import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  Button,
  Divider,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@vapor/react-material";
import IconButton from "@vapor/react-material/IconButton";
import { Typography } from "@vapor/react-extended";
import { useEffect, useState } from "react";
import { useDeleteFolder } from "../../hooks/useDeleteFolder";
import React from "react";

const DeleteAlert = ({ open, setOpen, id, onDelete }: any) => {
  const handleDialog = () => {
    setOpen(false);
  };

  const { data, loading, error, fetch, hasLoaded } = useDeleteFolder(id);

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Conferma eliminazione
        <IconButton color="primary" onClick={handleDialog}></IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Questo documento è incluso nel fascicolo Mutui. Se lo elimini, il
          fascicolo risulterà incompleto. Vuoi preseguire?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          style={{ marginRight: "15px" }}
          onClick={handleDialog}
        >
          <Typography>Anulla</Typography>
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            fetch();
            onDelete();
            setOpen(false);
          }}
        >
          <Typography>Elimina</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeleteAlert;
