import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button } from "@vapor/react-material";
import { Typography } from "@vapor/react-extended";
import { useNavigate } from "react-router-dom";

const NoFolders = () => {
  const navigate = useNavigate();
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <FontAwesomeIcon
        icon={faFolderOpen}
        style={{
          height: "101px",
          color: "#98AAB3",
          width: "113px",
        }}
      />
      <Typography
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          color: "rgb(0, 80, 117)",
          fontFamily: "Cairo",
          width: "320px",
          letterSpacing: "0.12px",
          lineHeight: "24px",
          textAlign: "center",
        }}
      >
        Crea un set di documenti da rendere disponibili ai tuoi clienti
      </Typography>
      <Button
        variant="outlined"
        onClick={() => navigate("/folder")}
        sx={{ color: "rgb(0, 144, 209)" }}
      >
        Crea il tuo primo fascicolo
      </Button>
    </Stack>
  );
};

export default NoFolders;
