import CircularProgress from "@vapor/react-material/CircularProgress";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import { useTranslation } from "@onefront/react-sdk";

export const Loading = () => {
  const {t} = useTranslation("DOCS")
  return (
    <Stack direction="column" alignItems="center">
      <CircularProgress></CircularProgress>
      <ExtendedTypography color="#0090D1">
        {t("document.loading")}
      </ExtendedTypography>
    </Stack>
  );
};
