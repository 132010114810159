import headerImg from "../assets/headerImg.png";
import { Stack, Button } from "@vapor/react-material";
import { Plus } from "@vapor/react-icons";
import { Typography } from "@vapor/react-extended";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <Stack direction="row" alignItems="center">
      <img src={headerImg} alt="header-img" />
      <Stack direction="column" paddingLeft="20px" width="860px">
        <Typography
          sx={{
            color: "rgb(4, 86, 124)",
            width: "429px",
            fontSize: "30px",
            fontFamily: "Cairo",
            letterSpacing: "1.88px",
            lineHeight: "33px",
          }}
        >
          Creare fascicoli in modo veloce e automatico è facilissimo!
        </Typography>
        <Typography
          sx={{
            marginTop: "20px",
            width: "731px",
            color: "rgb(21, 41, 53)",
            fontSize: "14px",
            fontFamily: "Roboto",
            letterSpacing: "0.32px",
            lineHeight: "24px",
          }}
        >
          Seleziona aggiungi nuovo fascicolo e crea per tutti i tuoi clienti un
          dossier con la procedura guidata.
        </Typography>
      </Stack>
      <Button
        variant="contained"
        onClick={() => navigate("/folder")}
        sx={{
          boxShadow: "0px 8px 24px 0px rgba(21, 41, 53, 0.25)",
          borderRadius: "24px",
          minHeight: "48px  !important",
          minWidth: "48px !important",
        }}
      >
        <Plus color="inverse" />
      </Button>
    </Stack>
  );
};
export default Header;
