import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack
} from "@vapor/react-material";

export const LinkedDocumentModalDelete = ({
  handleDialogDelete,
  openModalDelete,
  setDocumentIdToDelete,
  doc,
  setOpenModalDelete,
  setClickToConfirm,
  docInfo,
}) => {
  const { t } = useTranslation("DOCS");

  return (
    <>
      <Dialog
        key={doc?.documentId}
        open={openModalDelete}
        onClose={handleDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("classification.removeLink")}</DialogTitle>
        <Divider variant="alert" />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Typography variant="body500">
                {t("classification.removeLinkMessage")}
              </Typography>
            <Stack direction="row">
              <Typography variant="body500">
                {t("classification.creationDate")}
              </Typography>
              <Typography variant="body500">{docInfo?.updatedOn}</Typography>
            </Stack>
            <Stack direction="row">
              <Typography variant="body500">
                {t("classification.fileName")}:
              </Typography>
              <Typography variant="body500">{docInfo?.filename}</Typography>
            </Stack>
              <Typography variant="body500">
                {t("classification.confirm")}
              </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleDialogDelete}
          >
            <Typography color={"red"}>
              {t("classification.cancelDialog")}
            </Typography>
          </Button>
          <Button
            key={doc?.documentId}
            onClick={() => {
              setClickToConfirm(true);
              setOpenModalDelete(false);
            }}
            variant="contained"
            color="error"
          >
            {t("classification.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
