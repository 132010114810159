import Chip from "@vapor/react-material/Chip";
import styled from "@emotion/styled";

interface StyledChipProps {
  categorylabel: string;
  selectedchip: string | null;
}
interface StyledStickyBoxProps {
  isEmbeded: string | null;
}
export const StyledChip = styled(Chip)<StyledChipProps>`
  && {
    ${(props) =>
      props.categorylabel === props.selectedchip
        ? {
            backgroundColor: "#b9e6f6",
            borderColor: "#0090d1",
            borderWidth: "1px",
            color: "#1F698A",
          }
        : {
            backgroundColor: "rgb(216, 216, 217)",
            borderWidth: "1px",
            color: "#d8d8d9",
          }}
  }
`;

export const StyledStickyBox = styled.div<StyledStickyBoxProps>`
  position: sticky;
  top: ${(props) => (props.isEmbeded ? "-1px" : "45px")};
  z-index: 10;
  margin-left: 16px;
  margin-right: 16px;
  padding-top: 10px;
  background-color: white;
  text-align: left;
  max-width: 100%;
`;
