/* eslint-disable react-hooks/rules-of-hooks */
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import InputLabel from "@vapor/react-material/InputLabel";
import {
  eachMonthOfInterval,
  eachQuarterOfInterval,
  startOfYear,
  endOfYear,
  endOfMonth,
  addMonths,
  format
} from "date-fns";
import { useTranslation } from "@onefront/react-sdk";

import { it, es, enGB } from "date-fns/locale";
import { StyledChip } from "../document-styled-components/StyledMonthsFilter";


const useCreateDates = (date, language) => {
  const { t } = useTranslation("DOCS")
  date = String(date);
  let months = eachMonthOfInterval({
    start: startOfYear(new Date(date)),
    end: endOfYear(new Date(date))
  }).map((month) => ({ start: month, end: endOfMonth(month) }));

  let quarters = eachQuarterOfInterval({
    start: startOfYear(new Date(date)),
    end: endOfYear(new Date(date))
  }).map((month) => ({
    start: month,
    end: addMonths(month, 3)
  }));

  let semesters = [
    {
      start: startOfYear(new Date(date)),
      end: addMonths(startOfYear(new Date(date)), 6)
    },
    {
      start: addMonths(startOfYear(new Date(date)), 6),
      end: addMonths(startOfYear(new Date(date)), 12)
    }
  ];

  return {
    month: [
      { name: t("document.dateFilter.all"), value: t("document.dateFilter.all") }
    ].concat(
      months.map((month, index) => ({
        name: format(month.start, "MMMM", { locale: language }),
        value: month
      }))
    ),
    trimester: [
      { name: t("document.dateFilter.all"), value: t("document.dateFilter.all") },
      { name: t("document.dateFilter.first"), value: quarters[0] },
      { name: t("document.dateFilter.second"), value: quarters[1] },
      { name: t("document.dateFilter.third"), value: quarters[2] },
      { name: t("document.dateFilter.fourth"), value: quarters[3] }
    ],
    semester: [
      { name: t("document.dateFilter.all"), value: t("document.dateFilter.all") },
      { name: t("document.dateFilter.first"), value: semesters[0] },
      { name: t("document.dateFilter.second"), value: semesters[1] }
    ]
  };
};

const changeSelectedMonths = (month, selectedMonth, setSelectedMonth) => {
  if (month.name === selectedMonth.name) {
    setSelectedMonth([]);
  } else {
    setSelectedMonth([month]);
  }
};

export const MonthsTypeList = ({
  monthType,
  months,
  selectedYear,
  setMonths
}) => {
  const { t, i18n } = useTranslation("DOCS");
  const language = i18n.language
  let monthNames = ""
  switch (language) {
    case "es": monthNames = useCreateDates(selectedYear, es); break
    case "it": monthNames = useCreateDates(selectedYear, it); break
    case "en": monthNames = useCreateDates(selectedYear, enGB); break
    default: monthNames = "";
  }

  return (
    <Box>
      <InputLabel sx={{ paddingBottom: "10px", paddingTop: "20px" }}>
        {t("document.dateFilter.selectMonths")}
      </InputLabel>
      <Stack
        width="290px"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        flexWrap="wrap"
        gap="12px"
      >
        {monthNames[monthType].map((el, index) => (
          <StyledChip
            size="medium"
            label={el.name[0].toUpperCase() + el.name.slice(1)}
            variant="filled"
            onClick={(e) => changeSelectedMonths(el, months, setMonths)}
            selected={!!months.find((month) => month.name === el.name)}
          />
        ))}
      </Stack>
    </Box>
  );
};
