import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import React from "react";
import { buildHeaders } from "./buildHeaders";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function useGetDocumentById({ id }: any) {
  const authData = useAuth();
  const { workspaceId, selectedTenant: currentCompany } = useClientInformationStore();

  const clientId = currentCompany?.itemId;

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/documents/${id}?workspaceId=${workspaceId}&loggedItemId=${clientId}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    id && fetch();
  }, [id]);

  return { data, loading, hasLoaded, fetch, error };
}
