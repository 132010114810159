import {Grid,Box} from "@vapor/react-material"
import Typography from "@vapor/react-extended/ExtendedTypography"
import { DocPreviewer } from "@onefront/documents-smart-components";
import { useTranslation } from "@onefront/react-sdk";
import Card from "@vapor/react-material/Card";
import CardContent from "@vapor/react-material/CardContent"
import * as React from "react";
import { useParams } from "react-router-dom";
import ErrorAlert from "../common-components/ErrorAlert";
import { AggregateByCategories } from "../common-utils/AggregateByCategories";
import { useGetDocumentById } from "../document-detail/hooks/useGetDocumentById";
import { useDeleteLinkedDocument } from "./api-hooks/useDeleteLinkedDocument";
import { useGetDocumentTypeCode } from "./api-hooks/useGetDocumentTypeCode";
import { useGetDocumentTypes } from "./api-hooks/useGetDocumentTypes";
import { ClassificationLinkedDocuments } from "./ClassificationLinkedDocuments";
import { DocumentEticets } from "./DocumentEticets";
import DocumentLinkDrawer from "./DocumentLinkDrawer";
import DocumentTypeComponent from "./DocumentTypeComponent";


const ClassificationDocumentContainer = ({
  activeStep,
  setActiveStep,
  setKvags,
  setTagsToSend,
  setDocumentType,
  error,
  setCheckIfFieldsAreFilled,
}) => {
  const { id } = useParams();
  const { workspaceId } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [macro, setMacor] = React.useState("");
  const [micro, setMicros] = React.useState("");
  const [category, setCategorie] = React.useState("");
  const [allDocuments, setAllDocuents] = React.useState([]);
  const [filteredDocuments, setFilteredDocuments] = React.useState([]);
  const [selectedMacro, setSelectedMacro] = React.useState("");
  const [selectedMicro, setSelectedMico] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [deletedId, setDeletedId] = React.useState("");
  const [buttonclicked, setClicked] = React.useState(false);
  const [selectedLabel, setSelectedLabel] = React.useState("");
  const [previewType, setPreviewType] = React.useState("");
  const [errorAlert, setErrorAlert] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const { t } = useTranslation("DOCS");

  const { data, loading, docTypeError } = useGetDocumentTypes({ workspaceId });

  const {
    data: documentDetails,
    loading: loadingDetails,
    fetch: fetchDetails,
  } = useGetDocumentById({
    id,
    workspaceId,
    deletedId,
    reload,
    setReload,
    previewType,
  });
  const { data: deleted } = useDeleteLinkedDocument(id, deletedId, workspaceId);

  React.useEffect(() => {
    if (deletedId) {
      fetchDetails();
    }
  }, [selectedMacro, selectedMicro, selectedCategory]);
  const doctypes = AggregateByCategories(data);
  const macrocategories = Object.keys(doctypes);

  const { groupedBy, labels } = React.useMemo(() => {
    const groupedBy = {};
    data?.forEach((el) => {
      groupedBy[el.label] = el;
    });
    const labels = Object.keys(groupedBy);
    return { labels, groupedBy };
  }, [data]);

  const valuesToDisplay = selectedLabel ? groupedBy[selectedLabel] : undefined;
  setDocumentType(valuesToDisplay?.label.toString());

  const { data: dataTypeCodes } = useGetDocumentTypeCode(
    valuesToDisplay?.typeCode,
    workspaceId
  );

  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const previw = React.useMemo(
    () => (
      <DocPreviewer
        height={"100vh"}
        fileName={data?.fileName}
        isInvoice={documentDetails?.einvoice}
        id={id}
        workspaceId={workspaceId}
      />
    ),
    []
  );
  return (
    <>
      <Box sx={{ flexGrow: 1, height: "200%", marginTop: "100px" }}>
        {errorAlert && <ErrorAlert />}
        <Grid style={{ height: "100%" }} container spacing={5}>
          <Grid item xs={6}>
            <Card>
              <div>
                <div style={{ marginLeft: "39px", marginTop: 10 }}>
                  <Typography
                    color={"#005075"}
                    fontFamily={"Cairo"}
                    fontSize={"24px"}
                    fontWeight={600}
                  >
                    {t("classification.typeOfDocument")}
                  </Typography>
                  <DocumentTypeComponent
                    loading={loadingDetails}
                    valuesToDisplay={valuesToDisplay}
                    labels={labels}
                    error={error}
                    selectedLabel={selectedLabel}
                    setSelectedLabel={setSelectedLabel}
                    prefilledCategory={documentDetails?.standardTags[2]?.value}
                    setCategory={setCategorie}
                  />
                </div>
              </div>

              {activeStep > 0 && (
                <div>
                  <div style={{ marginLeft: "39px", marginTop: 10 }}>
                    <Typography
                      color={"#005075"}
                      fontFamily={"Cairo"}
                      fontSize={"24px"}
                      fontWeight={600}
                      marginBottom={3}
                    >
                      {t("classification.addTags")}
                    </Typography>
                    <DocumentEticets
                      setCheckIfFieldsAreFilled={setCheckIfFieldsAreFilled}
                      activeStep={activeStep}
                      setErrorAlert={setErrorAlert}
                      costumeTags={documentDetails?.customTags}
                      setTagsToSend={setTagsToSend}
                      setKvags={setKvags}
                      dataTypeCodes={dataTypeCodes}
                      setActiveStep={setActiveStep}
                      docDetailData={documentDetails}
                    />
                  </div>
                </div>
              )}
            </Card>
            {activeStep > 1 && (
              <Card style={{ marginTop: "10px" }}>
                <div>
                  <div style={{ marginLeft: "39px", marginTop: 10 }}>
                    <Typography
                      color={"#005075"}
                      fontFamily={"Cairo"}
                      fontSize={"24px"}
                      fontWeight={600}
                      marginBottom={10}
                    >
                      {t("classification.linkADocument")}
                      <ClassificationLinkedDocuments
                        loadingDetails={loadingDetails}
                        fetchDetails={fetchDetails}
                        setDeletedId={setDeletedId}
                        data={documentDetails}
                        workspaceId={workspaceId}
                      />
                    </Typography>
                  </div>
                  <DocumentLinkDrawer
                    setReload={setReload}
                    docLinkedDocuments={documentDetails.linkedDocuments}
                    setFilteredDocuments={setFilteredDocuments}
                  />
                </div>
              </Card>
            )}
          </Grid>
          <Grid item xs={6}>
            <Card>
              <CardContent>{previw}</CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ClassificationDocumentContainer;
