interface FileData {
  content: string | null;
  filename: string | null;
}

const ConvertFileToBase64 = async (file: File): Promise<FileData> =>
  new Promise<FileData>((resolve) => {
    const reader = new FileReader();

    reader.onload = ({ target }) => {
      if (target) {
        let content = target.result as string;
        content = content.split(",")[1];
        const filename = file.name;
        resolve({ content, filename });
      } else {
        resolve({ content: null, filename: null });
      }
    };

    reader.onerror = () => resolve({ content: null, filename: null });

    reader.readAsDataURL(file);
  });

export default ConvertFileToBase64;
