import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../../common-utils/buildHeaders";

export function useGetAllowedMacrocategories(workspaceId) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `documentsApi://api/v1/documents/getAllowedMacroCategories?workspaceId=${workspaceId}`,
    {
      lazy: true,
      headers: buildHeaders({ authData })
    }
  );
  useEffect(() => {
    if (workspaceId) fetch();
  }, [workspaceId]);

  return { data, loading, hasLoaded, fetch, error };
}
