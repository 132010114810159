import {TextField} from "@vapor/react-material/TextField"
import React, { useMemo } from "react";
import ClassificationDatePicker from "./ClassificationDatePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";

const getCommonProps = (dataTypeCode, value, onChange) => {
  return {
    label: dataTypeCode.label,
    value,
    onChange,
  };
};

const DynamicInput = ({ label, jsonSchema, value, onChange, required }) => {
  const { t } = useTranslation("DOCS");
  const obj = useMemo(() => JSON.parse(jsonSchema), [jsonSchema]);
  if (obj.type === "string" && obj.format === "date") {
    return (
      <ClassificationDatePicker
        required={required}
        value={value}
        key={label}
        label1={label}
        onChange={onChange}
      />
    );
  }
  if (obj.type === "integer" && obj.minimum) {
    return (
      <TextField
        required={required.required}
        helperText={
          value === "" && required.required? (
            <div>
              <FontAwesomeIcon icon={faCircleMinus} /> {t("classification.errorMessage")}
            </div>
          ) : (
            " "
          )
        }
        error={value === "" && required?.required}
        key={label}
        value={value}
        style={{ marginRight: "10px", marginTop: "10px" }}
        display="inline-block"
        label={label}
        variant="outlined"
        type={"number"}
        sx={{
          width: "30%",
        }}
        onChange={onChange}
      />
    );
  }

  if (obj.type === "integer") {
    return (
      <TextField
        key={label}
        error={value === "" && required?.required}
        display="inline-block"
        helperText={
          value === "" && required.required ? (
            <div>
              <FontAwesomeIcon icon={faCircleMinus} /> {t("classification.errorMessage")}
            </div>
          ) : (
            " "
          )
        }
        label={label}
        value={value}
        variant="outlined"
        type={"number"}
        InputProps={{ inputProps: { min: obj.minimum, max: obj.maximum } }}
        sx={{ marginTop: "10px", marginRight: "10px", width: "30%" }}
        onChange={onChange}
      />
    );
  }
  if (obj.type === "string") {
    return (
      <TextField
        key={label}
        required={required?.required}
        display="inline-block"
        helperText={
          value === "" && required.required? (
            <div>
              <FontAwesomeIcon icon={faCircleMinus} /> {t("classification.errorMessage")}
            </div>
          ) : (
            " "
          )
        }
        error={value === "" && required?.required}
        label={label}
        value={value}
        variant="outlined"
        type={"text"}
        sx={{
          marginTop: "10px",
          width: "30%",
          marginRight: "10px",
        }}
        onChange={onChange}
      />
    );
  }
};

export const DynamicFields = ({
  dataTypeCodes,
  dataMapInputState,
  onChangeByName,
}) => {
  return (
    <>
      {dataTypeCodes?.map((dataTypeCode) => (
        <DynamicInput
          required={dataTypeCode}
          label={dataTypeCode.label}
          jsonSchema={dataTypeCode.jsonSchema}
          onChange={onChangeByName(dataTypeCode.name)}
          value={dataMapInputState[dataTypeCode.name]}
        />
      ))}
    </>
  );
};
