import TextField from "@vapor/react-material/TextField";
import { useTranslation } from "@onefront/react-sdk";

export const InvoiceFilter = ({ setInvoiceNumber, invoiceNumber }) => {
  const {t} = useTranslation("DOCS")
  return (
    <TextField
      label={t("document.invoice.number")}
      value={invoiceNumber}
      sx={{ width: "300px", zIndex: 0 }}
      onChange={(e) => setInvoiceNumber(e.target.value)}
    ></TextField>
  );
};