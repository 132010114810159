import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import { Button } from "@vapor/react-material/Button";
import { Loading } from "./Loading";
import { NotFoundBanner } from "./NotFound";
import CardList from "./CardList";
import { InfoAlert } from "./InfoAlert";
import { useTranslation } from "@onefront/react-sdk";
import { cardFormat } from "../types";

interface DocumentsProps {
  loading: boolean;
  documents: any;
  hasLoaded: boolean;
  data: any;
  loadMore: any;
  workspaceId: string;
  displayDataToCard: boolean;
  selectedFileFormat: cardFormat;
  displayPage: any;
  pageSize: number;
}

const Documents = ({
  loading,
  documents,
  hasLoaded,
  data,
  loadMore,
  workspaceId,
  displayDataToCard,
  selectedFileFormat,
  displayPage,
  pageSize,
}: DocumentsProps) => {
  const { t } = useTranslation("DOCS");
  return (
    <Stack direction="column" alignItems="center">
      {loading ? (
        <Loading />
      ) : hasLoaded && documents.length === 0 ? (
        <NotFoundBanner />
      ) : documents && documents.length !== 0 ? (
        <>
          <Stack justifyContent="center" />
          <CardList
            workspaceId={workspaceId}
            data={displayDataToCard}
            selectedFileFormat={selectedFileFormat}
          />
        </>
      ) : (
        <></>
      )}
      {data?.searchDocumentsResponse.length >= pageSize && data && !loading && (
        <Box marginTop={"10px"}>
          <Button variant="outlined" onClick={loadMore}>
            {t("classification.load")}
          </Button>
        </Box>
      )}
      {!displayDataToCard && !displayPage && <InfoAlert />}
    </Stack>
  );
};

export default Documents;
