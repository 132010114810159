import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { faFileArrowDown, faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button } from "@vapor/react-material";
import { Typography } from "@vapor/react-extended";
import { useCallback, useEffect, useState } from "react";
import { DateFormatter } from "../utils/DateFormatter";
import FolderCardPopup from "./FolderCardPopup";
import { useDownloadFolderById } from "../hooks/useDownloadFolderById";
import React from "react";
import FolderCardHover from "./FolderCardHover";

const FolderCard = ({
  folder,
  setOpenDeleteModal,
  setOpenShareModal,
  setOpenPreviewModal,
  setSelectedFolderId,
  setSelectedDocumentId,
}: any) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);
  const [showDocumentList, setShowDocumentList] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState(null);

  const content = [
    {
      label: "Ultimo aggiornamento:",
      value: DateFormatter(folder?.modifiedAt),
    },
    { label: "Tipologia:", value: folder?.classification },
    { label: "Periodo di riferimento:", value: folder?.referencePeriod },
    { label: "Contiene:", value: folder?.referenceIds?.length },
  ];

  const { data, loading, error, fetch } = useDownloadFolderById(folder?.id);

  const filename = data
    ? data?.headers["Content-Disposition"][0].match(
        /filename="([^"]+\.zip)"/
      )[1]
    : "-";

  const downloadFolder = useCallback(() => {
    if (data) {
      const dataUri = `data:application/zip;base64,${data?.base64}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = dataUri;
      downloadLink.download = filename;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [data]);

  useEffect(() => {
    downloadFolder();
  }, [downloadFolder]);

  return (
    <Stack
      sx={{
        width: "314px",
        position: "relative",
        height: "258px",
        boxShadow: "0px 2px 8px 0px rgba(21, 41, 53, 0.25)",
        border: "1px solid #E0E7EB",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        padding="12px"
      >
        <Typography
          sx={{ fontFamily: "Cairo", color: "#004E75", fontSize: "16px" }}
        >
          {folder?.text}
        </Typography>
        <FontAwesomeIcon
          onClick={(event) => {
            setOpenPopup(true);
            setSelectedFolderId(folder?.id);
            setAnchorElement(event.currentTarget as any);
          }}
          style={{ color: "#008FD6", cursor: "pointer", padding: "3px" }}
          icon={faEllipsisVertical}
        />
        {openPopup && (
          <FolderCardPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            anchorElement={anchorElement}
            setAnchorElement={setAnchorElement}
            setOpenDeleteModal={setOpenDeleteModal}
            setOpenShareModal={setOpenShareModal}
            id={folder?.id}
          />
        )}
        {showDocumentList && (
          <FolderCardHover
            openMenu={showDocumentList}
            setOpenMenu={setShowDocumentList}
            anchorEl={menuAnchorElement}
            setOpenPreviewModal={setOpenPreviewModal}
            setAnchorEl={setMenuAnchorElement}
            id={folder?.id}
            setSelectedDocumentId={setSelectedDocumentId}
          />
        )}
      </Stack>
      <Stack>
        <Stack
          direction="column"
          paddingLeft="8px"
          paddingRight="8px"
          paddingTop="15px"
          paddingBottom="22px"
        >
          {content.map((item) => (
            <Stack
              direction="row"
              key={folder.id}
              justifyContent="space-between"
              alignContent="center"
              paddingTop="5px"
            >
              <Typography
                sx={{
                  color: "#1F292E",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "500px",
                }}
              >
                {item.label}
              </Typography>
              <Typography
                sx={{
                  color: "#566B76",
                  fontFamily: "Roboto",
                  fontSize: "14px",
                  fontWeight: "400px",
                }}
              >
                {item.value}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
      <div
        style={{
          display: "flex",
          position: "absolute",
          bottom: "0",
          width: "inherit",
          cursor: "pointer",
          alignItems: "center",
          background: "#F2F6F8",
          height: "56px",
        }}
      >
        <FontAwesomeIcon
          onClick={() => fetch()}
          icon={faFileArrowDown}
          style={{
            color: "#008FD6",
            fontWeight: "bold",
            textAlign: "left",
            padding: "8px 16px",
          }}
        />
        <FontAwesomeIcon
          icon={faEye}
          id="folder-card"
          aria-haspopup="true"
          onClick={(event) => {
            setShowDocumentList((prev) => !prev);
            setMenuAnchorElement(event.currentTarget as any);
          }}
          style={{ color: "#008FD6", fontWeight: "bold", textAlign: "left" }}
        />
      </div>
    </Stack>
  );
};
export default FolderCard;
