import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import React from "react";
import { buildHeaders } from "./buildHeaders";

export function useGetFolderDocuments(id: string) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders/content/${id}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    fetch();
  }, []);

  return { data, loading, hasLoaded, fetch, error };
}
