import { useAuth, usePost } from "@onefront/react-sdk";
import { useEffect } from "react";
import React from "react";
import { buildHeaders } from "./buildHeaders";

export function useGetAllFolders(
  workspaceId: string,
  page: number,
  size: number,
  folderSort: string
) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = usePost(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders/search/all`,
    { workspaceId, page, size, folderSort },
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    if (!!workspaceId) {
      fetch();
    }
  }, [page, workspaceId, folderSort]);

  return { data, loading, hasLoaded, fetch, error };
}
