import { useEffect } from "react";
import { useAuth, usePost } from "@onefront/react-sdk";
import { buildHeaders } from "../../../common-utils/buildHeaders";
import { useClientInformationStore } from "../../../store/useClientInformationStore";

export function useGetDocuments({
  workspaceId,
  searchTerm,
  pageNumber,
  pageSize,
  sort = "LATEST_UPLOADS",
  readByAccountant,
  readByCompany,
  urgentDocs,
  startDate,
  endDate,
  filterPerYear = "",
  dateType = "DOCUMENT",
  microcategory,
  macrocategory,
  selectedCategories = {},
  invoiceNumber,
  checkIfUploaded,
} = {}) {
  const authData = useAuth();
  const { selectedTenant: currentCompany } = useClientInformationStore();
  const clientId = currentCompany?.itemId;
  if (macrocategory !== "") {
    selectedCategories.macrocategory = macrocategory;
  }
  if (invoiceNumber && invoiceNumber.length > 0) {
    selectedCategories.numeroFattura = invoiceNumber;
  } else {
    delete selectedCategories.numeroFattura;
  }
  const { data, loading, hasLoaded, fetch, error } = usePost(
    `documentsApi://api/v1/documents/search?`,
    {
      workspaceId,
      fullTextSearch: searchTerm,
      pageNumber,
      pageSize,
      sort,
      readByAccountant,
      readByCompany,
      isUrgent: urgentDocs,
      startDate,
      endDate,
      filterPerYear,
      dateType,
      microcategory,
      aggregationsFilter: selectedCategories,
      loggedItemId: clientId,
    },
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    },
  );

  useEffect(() => {
    if (workspaceId || checkIfUploaded) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchTerm,
    workspaceId,
    sort,
    readByAccountant,
    readByCompany,
    urgentDocs,
    pageSize,
    filterPerYear,
    microcategory,
    startDate,
    endDate,
    dateType,
    pageNumber,
    selectedCategories,
    invoiceNumber,
    checkIfUploaded,
  ]);

  useEffect(() => {
    /*
        if (error) {
            localStorage.removeItem(`docs.item.${clientId}.selectedWorkspace`)
        }
        */
  }, [error]);
  return { data, loading, hasLoaded, fetch, error };
}
