import { useNavigate } from "react-router-dom";
import { Stack, Button } from "@vapor/react-material";
import { Typography } from "@vapor/react-extended";
import React from "react";
import SuccessFolderCreation from "../assets/SuccessFolderCreation.svg";

const FolderCreatedSuccessfully = () => {
  const navigate = useNavigate();
  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <img src={SuccessFolderCreation} alt="header-img" />
      <Typography
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          color: "#1F292E",
          fontFamily: "Roboto",
          width: "320px",
          letterSpacing: "0.12px",
          lineHeight: "24px",
          textAlign: "center",
        }}
      >
        Il fascicolo è stato creato
      </Typography>
      <Button
        variant="outlined"
        onClick={() => navigate("/all-folders")}
        sx={{ color: "rgb(0, 144, 209)" }}
      >
        Torna a tutti i fascicoli
      </Button>
    </Stack>
  );
};
export default FolderCreatedSuccessfully;
