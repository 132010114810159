import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../../common-utils/buildHeaders";

interface useGetToGetSpaceManagementProps {
  workspaceId: string;
}

export function useGetToGetSpaceManagement({
  workspaceId,
}: useGetToGetSpaceManagementProps) {
  const authData = useAuth();

  const { data, fetch, loading, hasLoaded, error } = useGet(
    `documentsApi://api/v1/documents/spaceManagement?workspaceId=${workspaceId}`,
    {
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    if (workspaceId) fetch();
  }, [workspaceId]);

  return { data, loading, hasLoaded, fetch, error };
}
