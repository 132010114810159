import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import React from "react";
import { buildHeaders } from "./buildHeaders";

export function useDownloadFolderById(id: string) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders/download/${id}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );
  // useEffect(() => {
  //   if (tenantId) fetch();
  // }, [tenantId]);

  return { data, loading, hasLoaded, fetch, error };
}
