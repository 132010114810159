import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useClassificationInformation: any = create(
  devtools((set, get) => {
    return {
      classificationDocumentId: null,
      isClassificationInfoEmpty: true,
      setDocumentIdWhenClassify: (classificationDocumentId: any) =>
        set({ classificationDocumentId }),
      setIsClassificationInfoEmpty: (isClassificationInfoEmpty: boolean) => {
        set({ isClassificationInfoEmpty });
      },
    };
  })
);
