interface ObjectWithProperties {
  [key: string]: string;
}

interface RequiredProperty {
  name: string;
  required: boolean;
}

export const checkRequiredProperties = (obj: ObjectWithProperties, array: RequiredProperty[]): boolean => {
  for (let i = 0; i < array?.length; i++) {
    const { name, required } = array[i];

    if (obj.hasOwnProperty(name) && required && obj[name] === "") {
      return true;
    }
  }

  return false;
};