import { useAuth, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function useDeleteDocument(id, workspaceId) {
  const authData = useAuth();
  const { selectedTenant: currentCompany } = useClientInformationStore();
  const clientId = currentCompany?.itemId;

  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "delete",

    lazy: true,
    headers: buildHeaders({ authData }),

    url: `documentsApi://api/v1/documents/${id}?workspaceId=${workspaceId}&loggedItemId=${clientId}`,
  });

  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [id]);

  return { data, loading, hasLoaded, fetch, error };
}
