import {
  faArrowToBottom,
  faInfoCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Button, Tooltip } from "@vapor/react-material";
import React, { useEffect } from "react";
import { usePostDownload } from "../hooks/api-hooks/usePostDownload";
import { useTranslation } from "@onefront/react-sdk";
import { useClientInformationStore } from "../../store/useClientInformationStore";

const MasiveDownloadActionButton: React.FC<any> = ({
  documentList,
  setOpen,
  setOpenModal,
  downloadMassiveDocuments,
  setDownloadMassiveDocuments,
}) => {
  const { workspaceId } = useClientInformationStore();

  const { loading, postDownloadDocs, data, hasLoaded, fileName } =
    usePostDownload({ workspaceId });
  const { t } = useTranslation("DOCS");
  function handleDownload() {
    const documentListIds = documentList.map(
      (document: any) => document.documentId
    );
    postDownloadDocs(documentListIds);
  }
  useEffect(() => {
    if (downloadMassiveDocuments) handleDownload();
  }, [downloadMassiveDocuments]);

  useEffect(() => {
    if (hasLoaded && !loading) {
      if (!data.size) return setOpen(true);
      const blob = new Blob([data], { type: "application/zip" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(url);
      if ((window as any).aptrinsic) {
        (window as any).aptrinsic("track", "docs:DOCUMENT_DOWNLOAD");
      }
    }
    setOpenModal(false);
    setDownloadMassiveDocuments(false);
  }, [data, fileName, hasLoaded, loading]);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Button
        variant="text"
        startIcon={<FontAwesomeIcon icon={faArrowToBottom} />}
        sx={{ bgcolor: "white" }}
        size="large"
        disabled={documentList?.length > 50 || !documentList?.length}
        onClick={() => {
          if (documentList?.length >= 20) {
            setOpenModal(true);
          } else {
            handleDownload();
          }
        }}
      >
        {loading ? t("document.scaricanto") : t("document.scarciaDocumenti")}
      </Button>
      <Tooltip
        arrow
        title={
          documentList.length > 50
            ? t("document.scaricaMassivaTooltip")
            : t("document.massiveDownloadTooltip")
        }
        placement="top"
      >
        <FontAwesomeIcon icon={faInfoCircle} color="#0090d1" />
      </Tooltip>
    </Stack>
  );
};

export default MasiveDownloadActionButton;
