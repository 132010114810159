import * as React from "react";
import Box from "@vapor/react-material/Box"
import Stepper from "@vapor/react-material/Stepper";
import Step from "@vapor/react-material/Step";
import StepLabel from "@vapor/react-material/StepLabel";
import ClassificationDocumentContainer from "./ClassificationDocumentContainer";
import { useTranslation } from "@onefront/react-sdk";

export default function ClassificationStepper({
  setStepStatus,
  stepStatus,
  setKvags,
  setTagsToSend,
  setDocumentType,
  error,
  setCheckIfFieldsAreFilled,
}) {
  const { t } = useTranslation("DOCS");
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = ["Tipo", t("classification.tags"), t("classification.links")];
  const handleStep = (step) => () => {
    setActiveStep(step);
    setStepStatus(step);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        sx={{ width: "50%", marginLeft: "25%", color: "#0090d1" }}
        activeStep={stepStatus}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel color="inherit" onClick={handleStep(index)}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <>
          <ClassificationDocumentContainer
            error={error}
            setCheckIfFieldsAreFilled={setCheckIfFieldsAreFilled}
            setDocumentType={setDocumentType}
            setTagsToSend={setTagsToSend}
            setKvags={setKvags}
            setActiveStep={setActiveStep}
            activeStep={stepStatus}
          />
        </>
      </div>
    </Box>
  );
}
