import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../../common-utils/buildHeaders";

export function useDownloadDocument(
  docId,
  workspaceId,
  fileName,
  type,
  isInvoice
) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `documentsApi://api/v1/documents/${docId}/download?workspaceId=${workspaceId}&type=${type}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    if (data) {
      if (isInvoice && type === " ") {
        // For XML type download
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (isInvoice && type === "ASSOSW") {
        const filenameWithoutExtension = fileName.replace(".xml", "");
        // For PDF type download
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = filenameWithoutExtension + ".pdf";
        downloadLink.click();
      }
      if (!isInvoice) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    }
  }, [data]);

  return { data, loading, hasLoaded, fetch, error };
}
