import styled from "styled-components";
import Chip from "@vapor/react-material/Chip";

export const StyledChip = styled(Chip)`
  &.MuiChip-filled {
    ${(props) =>
      props.selected &&
      `
        background-color: #b9e6f6;
        border-color: #58b9e3;
        border-width: 1px;
        color: #7d97a0;
      `}
  }
`;
