import { useState, useEffect, useMemo, ReactNode } from "react";
import { faCircleExclamation, faFile } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons/faCommentAlt";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faMinusOctagon } from "@fortawesome/pro-regular-svg-icons/faMinusOctagon";
import { faFileCheck } from "@fortawesome/pro-solid-svg-icons/faFileCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack/";
import moment from "moment";
import Popover from "@vapor/react-material/Popover";
import { Link, useNavigate } from "react-router-dom";
import {
  macroCategoryCostants,
  macroCategoryConstant,
} from "../../common-components/CostantStrings";
import {
  StyledCommentCount,
  StyledDivWrapper,
  StyledFileIconContainer,
  StyledFlexWrapper,
  StyledGreyBoxContainer,
  StyledInboxIconContainer,
  StyledSliceCard,
} from "../document-styled-components/CardListStyles";
import { useDownloadDocument } from "../hooks/api-hooks/useDownloadADocument";
import { useTranslation } from "@onefront/react-sdk";
import useFormatDocumentName from "../../common-utils/useFormatDocumentName";
import { SliceCardProps } from "../types";
import * as React from "react";
import { config } from "../../../config";
import Tooltip from "@vapor/react-material/Tooltip";
import { AiAsistant } from "@onefront/documents-smart-components";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";

function ExperimentalFeatureWrapper(props: { children: ReactNode }) {
  return config.documentExperimentalFeature ? props.children : undefined;
}

const SliceCard = ({ doc, workspaceId }: SliceCardProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState<string>();
  const open = Boolean(anchorEl);
  const { t } = useTranslation("DOCS");
  const navigate = useNavigate();

  const {
    data: download,
    fetch,
    hasLoaded,
  } = useDownloadDocument(
    doc.documentId,
    workspaceId,
    doc.fileName,
    documentType,
    doc?.invoice,
  );
  const date = moment(doc.createdOn).format("DD/MM/YYYY");
  const checkVisibility = doc.visibleByAccountantOnly;
  const checkIfEncrypted = doc.encrypted;
  const documentTitle = doc.title;
  const fileName = doc.fileName;
  const isInbox = (doc?.standardTags || []).some(
    (x: any) => x.value === "Inbox",
  );
  const macro = doc?.standardTags.filter(
    (val: any) => val.name === "macrocategory",
  )[0].value;

  const icon =
    macroCategoryConstant.get(macro) || macroCategoryCostants.tutti.icon;

  const documentSearchPercentageScore =
    doc?.documentSearchScorePercentage === "NaN"
      ? 0
      : doc?.documentSearchScorePercentage;

  const documentSearchPercentage =
    documentSearchPercentageScore !== 0
      ? "Corrispondenza  " + doc?.documentSearchScorePercentage + "%"
      : 0;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFatture = useMemo(() => doc?.einvoice === true, [doc]);

  const handleClick = (event: { currentTarget: any }) => {
    isFatture ? setAnchorEl(event.currentTarget) : setDocumentType("ASSOSW");
  };

  useEffect(() => {
    if (documentType === "ASSOSW" || documentType === " ") {
      fetch();
    }
  }, [documentType]);

  return (
    <>
      <StyledSliceCard key={doc.documentId}>
        <StyledDivWrapper>
          <Link
            to={`/document-details/${doc.documentId}/${workspaceId}`}
            style={{ textDecoration: "none", width: "100%" }}
          >
            <Stack direction={"row"}>
              {" "}
              <StyledInboxIconContainer>
                <FontAwesomeIcon
                  style={{
                    marginTop: "50%",
                    color: "#005075",
                  }}
                  fontSize={"24px"}
                  icon={icon}
                />
              </StyledInboxIconContainer>
              <div>
                {documentSearchPercentage ? (
                  <Typography
                    variant="caption"
                    marginBottom={1}
                    color={
                      documentSearchPercentageScore > 80 ? "green" : "grey"
                    }
                  >
                    {documentSearchPercentage}
                  </Typography>
                ) : (
                  <div style={{ marginTop: 25 }}></div>
                )}
                <Stack direction="row">
                  <Typography
                    fontSize={"20px"}
                    fontFamily={"Cairo"}
                    fontWeight={600}
                    color="#005075"
                    paddingRight="10px"
                  >
                    {useFormatDocumentName(documentTitle)}
                  </Typography>
                  <Stack direction="row" gap={1} alignItems="center">
                    {doc?.urgent && (
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        color="#D92B2C"
                      ></FontAwesomeIcon>
                    )}

                    {checkVisibility && (
                      <FontAwesomeIcon color="red" icon={faMinusOctagon} />
                    )}
                    {checkIfEncrypted && (
                      <FontAwesomeIcon color="red" icon={faKey} />
                    )}
                  </Stack>
                </Stack>
                <StyledFlexWrapper>
                  <div style={{ marginRight: "10px" }}>
                    <Typography variant="bodySmall">
                      {t("document.file.uploadDate")}:{" "}
                    </Typography>
                    <Typography variant="bodySmall" fontWeight={"bold"}>
                      {date}
                    </Typography>
                  </div>
                  {!isInbox && (
                    <div>
                      <Typography variant="bodySmall">
                        {t("classification.fileName")}:
                      </Typography>
                      <Typography fontWeight={"bold"} variant="bodySmall">
                        {" "}
                        {fileName || ""}
                      </Typography>
                    </div>
                  )}
                </StyledFlexWrapper>
              </div>
            </Stack>
          </Link>

          <StyledFlexWrapper>
            <StyledFileIconContainer>
              <FontAwesomeIcon
                icon={doc.read ? faFileCheck : faFile}
                color={doc.userType === "ACCOUNTANT" ? "#152935" : "#0177ac"}
              ></FontAwesomeIcon>
            </StyledFileIconContainer>
            <StyledGreyBoxContainer>
              <Button
                onClick={() =>
                  navigate(`/document-details/${doc.documentId}/${workspaceId}`)
                }
              >
                <StyledCommentCount>
                  {doc.notesCount > 100 ? "99+" : doc.notesCount}
                </StyledCommentCount>
                <FontAwesomeIcon
                  color="#0090d1"
                  size="lg"
                  icon={faCommentAlt}
                />
              </Button>
              <Button>
                <FontAwesomeIcon
                  color="#0090d1"
                  size="lg"
                  icon={faEye}
                  onClick={() => {
                    navigate(
                      `/document-details/${doc.documentId}/${workspaceId}`,
                    );
                  }}
                />
              </Button>
              <Tooltip arrow placement="top" title={t("document.download")}>
                <Button>
                  <FontAwesomeIcon
                    onClick={handleClick}
                    size="lg"
                    key={doc.documentId}
                    color="#0090d1"
                    icon={faDownload}
                  />
                </Button>
              </Tooltip>
              {isFatture && (
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Typography
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    margin={2}
                    onClick={() => {
                      setDocumentType("ASSOSW");
                    }}
                  >
                    {t("document.downloadPdf")}
                  </Typography>
                  <Typography
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                    margin={2}
                    onClick={() => {
                      setDocumentType(" ");
                    }}
                  >
                    {t("document.downloadXml")}
                  </Typography>
                </Popover>
              )}
            </StyledGreyBoxContainer>
          </StyledFlexWrapper>
        </StyledDivWrapper>
      </StyledSliceCard>
    </>
  );
};

export default SliceCard;
