import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider, Autocomplete, Button } from "@vapor/react-material";
import Header from "../components/Header";
import NoFolders from "../components/NoFolders";
import SearchInput from "../core/SearchInput";
import {
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { AutocompleteRenderInputParams, TextField } from "@mui/material";
import FolderCard from "../components/FolderCard";
import React from "react";
import FolderCardHover from "../components/FolderCardHover";
import DeleteAlert from "../components/alerts/DeleteAlert";
import { useGetAllFolders } from "../hooks/useGetAllFolders";
import FolderCreatedSuccessfully from "../components/FolderCreatedSuccessfully";
import { Folder } from "../types/FolderInteface";
import ShareFolderModal from "../components/dashboard/ShareFolderModal";
import DocumentPreviewModal from "../components/dashboard/DocumentPreviewModal";
import { useClientInformationStore } from "../../store/useClientInformationStore";
import { WorkspaceSelector } from "@drift/oneplatfront-components";
import NoAccessErrorPage from "../../common-components/NoAccessError";

const FoldersPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [allFolders, setAllFolders] = useState<Folder[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [filterValue, setFilterValue] = useState("NEWEST");
  const filteringOptions = [
    "NEWEST",
    "OLDEST",
    "MOST_RECENTLY_UPDATED",
    "LEAST_RECENTLY_UPDATED",
    "ASCENDING",
    "DESCENDING",
  ];

  const { workspaceId, selectedWorkspace: ws } = useClientInformationStore();

  const workspaceDescription = ws
    ? `${
        [...ws?.companies, ...ws?.accountants].find((x) => x.id === workspaceId)
          ?.description
      }`
    : "";

  const {
    data: folders,
    loading,
    error,
    fetch,
    hasLoaded,
  } = useGetAllFolders(workspaceId, page, size, filterValue);

  useEffect(() => {
    if (hasLoaded && folders?.data && Array.isArray(folders.data)) {
      setAllFolders((prev: Folder[]) => [...prev, ...folders.data]);
    }
  }, [folders]);

  const searchFolders = () => {
    return allFolders.filter((folder) => folder.text.startsWith(searchValue));
  };

  const filteredFolders = useMemo(
    () => (!!searchValue ? searchFolders() : allFolders),
    [searchValue, allFolders]
  );

  return (
    <>
      <Stack
        direction="column"
        paddingTop="20px"
        marginLeft="16px"
        spacing={2}
        marginRight="16px"
      >
        <Grid container alignItems="center">
          <Grid item xs={4} />
          <Grid item xs={4}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Cairo",
                  fontWeight: "600",
                  textDecoration: "none",
                  textTransform: "none",
                  color: "#0077ad",
                  padding: "0px",
                  lineHeight: "1.5",
                  fontSize: "20px",
                }}
              >
                Fascicoli
              </Typography>
              <Typography
                sx={{
                  margin: "0px",
                  fontFamily: "Cairo",
                  color: "rgb(98, 121, 132)",
                  fontWeight: "400",
                  fontSize: "12px",
                  padding: "0px",
                  lineHeight: "1",
                }}
              >
                {workspaceDescription}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="right">
            <WorkspaceSelector />
          </Grid>
        </Grid>
        <Divider />
        {ws?.companies?.length ? (
          <>
            <Header />
            <hr />
            {filteredFolders.length === 0 && !searchValue ? (
              <NoFolders />
            ) : (
              <div style={{ width: "inherit" }}>
                <Stack
                  direction="row"
                  width="inherit"
                  alignContent="space-between"
                  justifyContent="space-between"
                >
                  <SearchInput
                    setSearchValue={setSearchValue}
                    onSearch={searchFolders}
                  />
                  <Autocomplete
                    defaultValue={"NEWEST"}
                    disablePortal
                    onChange={(_, value: any) => {
                      setAllFolders([]);
                      setFilterValue(value);
                    }}
                    id="grouped-demo"
                    options={filteringOptions}
                    renderInput={(params) => (
                      <TextField placeholder={"enter option"} {...params} />
                    )}
                  />
                </Stack>
                <Stack
                  display="grid"
                  sx={{
                    gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
                    justifyItems: "center",
                    width: "inherit",
                    paddingTop: "20px",
                    gap: "20px",
                  }}
                >
                  {filteredFolders.map((folder: any) => (
                    <FolderCard
                      key={folder.id}
                      folder={folder}
                      setOpenPreviewModal={setOpenPreviewModal}
                      setOpenDeleteModal={(value: boolean) =>
                        setOpenDeleteModal(value)
                      }
                      setOpenShareModal={setOpenShareModal}
                      setSelectedFolderId={setSelectedFolderId}
                      setSelectedDocumentId={setSelectedDocumentId}
                    />
                  ))}
                </Stack>
              </div>
            )}
          </>
        ) : (
          <NoAccessErrorPage />
        )}
      </Stack>
      {filteredFolders.length > 0 && ws?.companies?.length && (
        <div style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            disabled={folders?.count === 0}
            onClick={() => setPage((prev) => prev + 1)}
            sx={{ color: "rgb(0, 144, 209)", margin: "20px", padding: "20px" }}
          >
            Visualizza altri risultati
          </Button>
        </div>
      )}
      {openDeleteModal && (
        <DeleteAlert
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          id={selectedFolderId}
          onDelete={() => {
            setSize(8);
            setPage(1);
            setAllFolders([]);
            fetch();
          }}
        />
      )}
      {openShareModal && (
        <ShareFolderModal open={openShareModal} setOpen={setOpenShareModal} />
      )}
      {openPreviewModal && (
        <DocumentPreviewModal
          id={selectedDocumentId}
          open={openPreviewModal}
          setOpen={setOpenPreviewModal}
        />
      )}
      {/* <FolderCreatedSuccessfully /> */}
    </>
  );
};
export default FoldersPage;
