import Card from "@vapor/react-material/Card";
import styled from "styled-components";

export const StyledSliceCard = styled(Card)`
  margin-top: 10px;
  width: 100%;
`;

export const StyledDivWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 88px;
  margin-left: 20px;
`;
export const StyledInboxIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;
export const StyledFileIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;
export const StyledFlexWrapper = styled.div`
  display: flex;
`;
export const StyledGreyBoxContainer = styled.div`
  background: #f2f5f8;
  display: flex;
  align-items: center;
  gap:10px;
`;
export const StyledCommentCount = styled.div`
  background-color: #f2f5f8;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: bold;
  position: absolute;
  margin-bottom: 14px;
  margin-left: 10px;
  padding-left: 3px;
  height: 20px;
`;
