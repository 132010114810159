import { CompanySelector } from "./CompanySelector";

export function AccountantSelector(props) {
  const { ws, workspaceId, setWorkspaceId } = props;
  return ws && ws.itemType === "COMPANY" ?
    <>
    {workspaceId && <CompanySelector
        value={workspaceId}
        data={ws?.accountants?.map((x) => ({
          id: x.id,
          value: x.description
        }))}
        onChange={(v) => setWorkspaceId(v.target.value)}
      ></CompanySelector>}
    </> : undefined;
}

export function CompaniesSelector(props) {
  const { ws, workspaceId, setWorkspaceId } = props;
  return ws && ws.itemType === "ACCOUNTANT" ?
    <>
        {workspaceId && <CompanySelector
        value={workspaceId}
        data={ws?.companies?.map((x) => ({
          id: x.id,
          value: x.description
        }))}
        onChange={(v) => setWorkspaceId(v.target.value)}
      ></CompanySelector>}
    </> : undefined
}