import { Stack } from "@vapor/react-material";
import { VaporIcon } from "@vapor/react-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
  faArrowCircleRight,
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { format } from "date-fns";

type sdiIconTypes = "Delivered" | "notDelivered" | "Rejected";

const formatWithIcon = (messageType: sdiIconTypes, text: string) => {
  return (
    <Stack direction="row" gap={2}>
      <VaporIcon
        icon={
          messageType === "Delivered"
            ? faCheckCircle
            : messageType === "Rejected"
            ? faXmarkCircle
            : faArrowCircleRight
        }
        color={
          messageType === "Delivered"
            ? "success"
            : messageType === "Rejected"
            ? "critical"
            : "warning"
        }
      ></VaporIcon>
      <Typography variant="body500">{text}</Typography>
    </Stack>
  );
};

type InvoiceDeliveryResult =
  | "Invoice_Send"
  | "Invoice_Received"
  | "Not_Delivered_But_Available"
  | "SDI_Discarded"
  | "PA_Issued_Not_Delivered"
  | "PA_Delivered_And_Rejected"
  | "PA_Delivered_And_Accepted"
  | "PA_Delivered_And_Accepted_Effective_Time";

function parseDate(date: string) {
  if (Number(date)) {
    return new Date(parseInt(date, 10));
  } else {
    return new Date(date);
  }
}

const sdiMessageText = (
  messageType: InvoiceDeliveryResult,
  date: string,
  t: any,
  locale: any
) => {
  const formatedDate = format(parseDate(date), "dd MMM yyyy", {
    locale: locale,
  });
  const formatedHour = format(parseDate(date), "HH:mm");

  switch (messageType) {
    case "Invoice_Send":
      return formatWithIcon(
        "Delivered",
        `${t("classification.Invoice_Send")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "Invoice_Received":
      return formatWithIcon(
        "Delivered",
        `${t("classification.Invoice_Received")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "Not_Delivered_But_Available":
      return formatWithIcon(
        "Delivered",
        `${t("classification.Not_Delivered_But_Available")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "SDI_Discarded":
      return formatWithIcon(
        "notDelivered",
        `${t("classification.SDI_Discarded")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "PA_Issued_Not_Delivered":
      return formatWithIcon(
        "notDelivered",
        `${t("classification.PA_Issued_Not_Delivered")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "PA_Delivered_And_Rejected":
      return formatWithIcon(
        "Rejected",
        `${t("classification.PA_Delivered_And_Rejected")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "PA_Delivered_And_Accepted":
      return formatWithIcon(
        "Delivered",
        `${t("classification.PA_Delivered_And_Accepted")} ${formatedDate} ${t(
          "classification.hour"
        )} ${formatedHour}`
      );
    case "PA_Delivered_And_Accepted_Effective_Time":
      return formatWithIcon(
        "notDelivered",
        `${t(
          "classification.PA_Delivered_And_Accepted_Effective_Time"
        )} ${formatedDate} ${t("classification.hour")} ${formatedHour}`
      );
  }
};

type data = {
  label: string;
  value: string;
};

export const SdiReceipt = ({
  data,
  t,
  locale,
}: {
  data: data[];
  t: any;
  locale: any;
}) => {
  const values = new Map(
    data
      .filter(
        (item) =>
          item.label === "statusSDI" ||
          item.label === "sdiDeliveryDate" ||
          item.label === "sdiReceiptDate" ||
          item.label === "dataSDI" ||
          item.label === "receiptDate"
      )
      .map((item) => [item.label, item.value])
  );

  const statusSDI = values.get("statusSDI");
  const sdiDeliveryDate = values.get("sdiDeliveryDate");
  const sdiReceiptDate = values.get("sdiReceiptDate");
  const dataSDI = values.get("dataSDI");

  switch (statusSDI) {
    case "consegnata al destinatario":
      return sdiDeliveryDate
        ? sdiMessageText("Invoice_Send", sdiDeliveryDate, t, locale)
        : sdiReceiptDate &&
            sdiMessageText("Invoice_Received", sdiReceiptDate, t, locale);
    case "MESSA A DISPOSIZIONE":
      return (
        dataSDI &&
        sdiMessageText("Not_Delivered_But_Available", dataSDI, t, locale)
      );
    case "A_DISPOZICIONE":
      return (
        dataSDI &&
        sdiMessageText("Not_Delivered_But_Available", dataSDI, t, locale)
      );
    case "acetata":
      return (
        sdiReceiptDate &&
        sdiMessageText("PA_Delivered_And_Accepted", sdiReceiptDate, t, locale)
      );
    case "rifutata":
      return (
        sdiReceiptDate &&
        sdiMessageText("PA_Delivered_And_Rejected", sdiReceiptDate, t, locale)
      );
    case "INVIATO":
      return (
        sdiDeliveryDate &&
        sdiMessageText("Invoice_Send", sdiDeliveryDate, t, locale)
      );
    case "RIFIUTATO":
      return (
        sdiDeliveryDate &&
        sdiMessageText("PA_Delivered_And_Rejected", sdiDeliveryDate, t, locale)
      );
    case "ACCETATO":
      return (
        sdiDeliveryDate &&
        sdiMessageText("PA_Delivered_And_Accepted", sdiReceiptDate, t, locale)
      );
  }
};
