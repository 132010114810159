import FormControl from "@vapor/react-material/FormControl";
import Select from "@vapor/react-material/Select";
import MenuItem from "@vapor/react-material/MenuItem";
import { useTranslation } from "@onefront/react-sdk";
import { MonthTypePropsProps } from "../types";

export const MonthType = ({ monthType, setMonthType }: MonthTypePropsProps) => {
  const { t } = useTranslation("DOCS");
  const monthNames = ["month", "trimester", "semester"];
  return (
    <FormControl sx={{ width: "160px" }}>
      <Select value={t(monthType)}>
        {monthNames.map((name) => (
          <MenuItem
            key={name}
            value={t(name)}
            onClick={(e) => setMonthType(name as any)}
          >
            {t(name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
