import TextField from "@vapor/react-material/TextField";
import {it, es} from "date-fns/locale";
import {isValid, parse} from "date-fns";
import {
    AdapterDateFns, LocalizationProvider, DatePicker,
} from "@vapor/react-x-date-pickers";
import {useTranslation} from "@onefront/react-sdk";
import {useEffect} from "react";
import debounce from 'lodash.debounce';

const DocumentDatePicker = ({
                                startDate, endDate, width, label1, label2, handleFilterChange,
                            }) => {
    const {i18n} = useTranslation("DOCS");
    const language = i18n.language;
    const locale = language === "it" ? it : es;


    const debouncedHandleFilterChange = debounce((type, parsedDate) => {
        handleFilterChange(type, parsedDate);
    }, 500); // Debounce for 500 ms

    const handleDateChange = (type, newValue) => {
        if (newValue === null) {
            debouncedHandleFilterChange(type, '');
            return;
        }

        let parsedDate;

        if (typeof newValue === 'string' && newValue.trim() !== '') {
            parsedDate = parse(newValue, 'yyyy-MM-dd', new Date());
            if (!isValid(parsedDate)) {
                console.log("Invalid date input", newValue);
                return;
            }
        } else if (newValue instanceof Date && isValid(newValue)) {
            parsedDate = newValue;
        } else {
            console.log("Invalid input type or value", newValue);
            return;
        }

        if (parsedDate && !isNaN(parsedDate.getTime())) {
            debouncedHandleFilterChange(type, parsedDate);
        }
    };

    useEffect(() => {
        return () => {
            debouncedHandleFilterChange.cancel();
        };
    }, []);


    return (<>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns}>
            <div style={{display: "flex", width: width}}>
                <DatePicker
                    sx={{marginRight: 2, zIndex: 0}}
                    key={1}
                    label={label1}
                    value={startDate}
                    onChange={(newValue) => handleDateChange("startDate", newValue)}
                    renderInput={(params) => <TextField {...params} error={false}/>}
                />
                <DatePicker
                    sx={{zIndex: 0}}
                    key={2}
                    label={label2}
                    value={endDate}
                    onChange={(newValue) => handleDateChange("endDate", newValue)}
                    renderInput={(params) => <TextField {...params} error={false}/>}
                />
            </div>
        </LocalizationProvider>
    </>);
};
export default DocumentDatePicker;
