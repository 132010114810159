import FormControl from "@vapor/react-material/FormControl";
import RadioGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Radio from "@vapor/react-material/Radio";
import Typography from "@vapor/react-extended/ExtendedTypography"
import { useTranslation } from "@onefront/react-sdk";
import { dateType, DateTypePropsProps } from "../types";

const changeDateType = (
  type: dateType,
  handleFilterChange: (type: string, upload: string) => void,
) => {
  if (type === "DOCUMENT") {
    handleFilterChange("dateType", "UPLOAD");
  } else {
    handleFilterChange("dateType", "DOCUMENT");
  }
};

export const DateType = ({
  dateType,
  doNotShow,
  handleFilterChange,
}: DateTypePropsProps) => {
  const { t } = useTranslation("DOCS");
  return (
    <FormControl>
      <div style={{ marginLeft: "2px", marginBottom: "4px" }}>
        {doNotShow && (
          <Typography
            fontSize={14}
            fontFamily={"Roboto"}
            fontWeight={600}
            height={20}
          >
            {t("document.date.title")}
          </Typography>
        )}
      </div>
      <RadioGroup row>
        <FormControlLabel
          value="right"
          control={
            <Radio
              checked={dateType === "DOCUMENT"}
              onClick={(e) => changeDateType(dateType, handleFilterChange)}
            />
          }
          label={t("document.document")}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Radio
              checked={dateType === "UPLOAD"}
              onClick={(e) => changeDateType(dateType, handleFilterChange)}
            />
          }
          label={t("document.upload")}
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};
