import Grid from "@vapor/react-material/Grid";
import CardBox from "./CardBox";
import SliceCard from "./SliceCard";
import { Key } from "react";

interface cardList {
  data: any,
  selectedFileFormat: string,
  workspaceId: string,
}

const CardList = ({
  data,
  selectedFileFormat,
  workspaceId,
}: cardList) => {
  return (
    <>
      {selectedFileFormat === "List" ? (
        <Grid container>
          {data &&
            data.map((doc: any, index: Key) => (
              <CardBox
                key={index}
                workspaceId={workspaceId}
                doc={doc}
              />
            ))}
        </Grid>
      ) : (
        data?.map((doc: any, index: Key) => (
          <SliceCard
            key={index}
            workspaceId={workspaceId}
            doc={doc}
          />
        ))
      )}
    </>
  );
};
export default CardList;
