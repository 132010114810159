import { Card } from "@vapor/react-material";
import { Snackbar } from "@vapor/react-material";
import { Alert } from "@vapor/react-material";
import { AlertTitle } from "@vapor/react-material";
import { Stack } from "@vapor/react-material";
import { Grid } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Divider } from "@vapor/react-material";
import { IconButton } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import {
  AccountantSelector,
  CompaniesSelector,
} from "./AccountantCompanySelector";
import { PageTitleProps } from "../types";
import { WorkspaceSelector } from "@drift/oneplatfront-components";
import React from "react";

const PageTitle = ({
  open,
  handleClose,
  Slide,
  displayPage,
  ws,
  workspaceId,
  setWorkspaceId,
}: PageTitleProps) => {
  const { t } = useTranslation("DOCS");
  return (
    <Card
      sx={{
        paddingTop: "30px",
        marginLeft: "16px",
        marginRight: "16px",
        boxShadow: "inset 0px 16px hsl(199, 30%, 93%)",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        TransitionComponent={Slide}
      >
        <Alert
          variant="outlined"
          severity="success"
          icon={<FontAwesomeIcon icon={faCheckCircle} />}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleClose}
              style={{ color: "black", fontWeight: "bold" }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{ fontWeight: "bold", verticalAlign: "middle" }}
              />
            </IconButton>
          }
        >
          <AlertTitle>{t("download.success")}</AlertTitle>
        </Alert>
      </Snackbar>
      <Stack direction="column" spacing={3}>
        <Grid container direction="row">
          <Grid item xs={4}>
            <Stack alignItems="flex-end" style={{ visibility: "hidden" }}>
              <CompaniesSelector
                ws={ws}
                workspaceId={workspaceId}
                setWorkspaceId={setWorkspaceId}
              />
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack direction="column" alignItems="center">
              <Typography
                sx={{
                  fontFamily: "Cairo",
                  fontWeight: "600",
                  textDecoration: "none",
                  textTransform: "none",
                  color: "rgb(0, 70, 102)",
                  padding: "0px",
                  lineHeight: "1.5",
                  fontSize: "20px",
                }}
              >
                {t("common.title")}
              </Typography>
              <Typography
                sx={{
                  margin: "0px",
                  fontFamily: "Cairo",
                  color: "rgb(98, 121, 132)",
                  fontWeight: "400",
                  fontSize: "12px",
                  padding: "0px",
                  lineHeight: "1",
                }}
              >
                {t("document.subTitle") +
                  `${
                    ws?.companies?.length
                      ? `: ${[...ws?.companies, ...ws?.accountants].find(
                          (x) => x.id === workspaceId,
                        )?.description}`
                      : ""
                  }`}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems={"flex-end"}
            xs={4}
          >
            <Stack alignItems="flex-end" direction="row">
              <WorkspaceSelector></WorkspaceSelector>
            </Stack>
          </Grid>
        </Grid>
        <Divider></Divider>
      </Stack>
    </Card>
  );
};

export default PageTitle;
