import { useLayout, useTranslation } from "@onefront/react-sdk";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Box from "@vapor/react-material/Box";
import Card from "@vapor/react-material/Card";
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import { format, subYears } from "date-fns";
import { useEffect, useState } from "react";
import { CheckboxFilter } from "./document-components/CheckBoxFilter";
import ChipsFilter from "./document-components/ChipsFilter";
import { DocumentHeader } from "./document-components/DocumentHeader";
import { FileFormatSwitcher } from "./document-components/FileFormatSwitcher";
import { useGetAllowedMacrocategories } from "./hooks/api-hooks/useAllowedMacrocategories";
import { useGetDocuments } from "./hooks/api-hooks/useGetDocuments";

import {
  AiAsistant,
  UploadSmartComponent,
} from "@onefront/documents-smart-components";
import { useAuth } from "@onefront/react-sdk";
import { Slide } from "@vapor/react-material";
import { useLocation } from "react-router-dom";
import { config } from "../../config";
import { DownloadConfirmationAlert } from "../documents/document-components/DownloadConfirmationAlert";
import { useClientInformationStore } from "../store/useClientInformationStore";
import AiAssistantFloatingBubble from "./document-components/AiAssistantFloatingBubble";
import Documents from "./document-components/Documents";
import Filters from "./document-components/Filters";
import MasiveDownloadActionButton from "./document-components/MasiveDownloadActionButton";
import PageTitle from "./document-components/PageTitle";
import { useGetToGetSpaceManagement } from "./hooks/api-hooks/useGetToGetSpaceManagement";
import { useDebounce } from "./hooks/useDebouncing";
import { useFilters } from "./hooks/useFilters";
import { cardFormat, MicroCategory } from "./types";
import NoAccessError from "../common-components/NoAccessError";

export const DocumentPage = () => {
  useEffect(() => {
    if ((window as any).aptrinsic) {
      (window as any).aptrinsic("track", "docs:ACCESS-LANDING-PAGE");
    }
  }, []);

  const { isEmbed } = useLayout();
  const { t } = useTranslation("DOCS");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFileFormat, setSelectedFileFormat] =
    useState<cardFormat>("Card");
  const [selectedYear, setSelectedYear] = useState(
    t("document.datefilter.all"),
  );
  const [monthType, setMonthType] = useState("month");
  const [months, setMonths] = useState([
    { name: t("document.dateFilter.all"), value: t("document.dateFilter.all") },
  ]);
  const [displayDataToCard, setDisplayDataToCard] = useState<any>([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const invoiceNumberDebounced = useDebounce(invoiceNumber);
  const [displayPage, setDisplayPage] = useState(true);
  const [checkIfUploaded, setCheckIfUploaded] = useState(false);
  const [downloadMassiveDocuments, setDownloadMassiveDocuments] =
    useState(false);
  // const { currentCompany, ws, workspaceId, setWorkspaceId } =
  //   useSelectedWorkspace();

  const {
    selectedTenant: currentCompany,
    selectedWorkspace: ws,
    workspaceId,
    setWorkspaceId,
  } = useClientInformationStore();
  const [userCanUpload, setUserCanUpload] = useState(false);
  const subtitle = ws
    ? `${
        [...ws.companies, ...ws.accountants].find((x) => x.id === workspaceId)
          ?.description ?? ""
      }`
    : "";
  const auth = useAuth().tokenData;
  const user = {
    id: auth?.client_id,
    email: auth?.email,
    firstName: auth?.given_name,
    lastName: auth?.family_name,
  };
  const isAccountant = ws?.itemType === "ACCOUNTANT";
  const company = ws?.companies?.at(0);
  const accountant = ws?.accountants?.at(0);
  const userAccount = isAccountant ? accountant : company;
  const account = {
    id: userAccount?.id,
    name: userAccount?.description,
  };
  if ((window as any).aptrinsic) {
    (window as any).aptrinsic("identify", user, account);
  }

  const {
    data: spaceManagementData,
    loading: spaceManagementLoading,
    error: spaceManagementError,
  } = useGetToGetSpaceManagement({ workspaceId });

  //filters logic
  const defaultFilters = {
    sortOption: "LATEST_UPLOADS",
    readByAccountant: false,
    readByCompany: false,
    urgentDocs: query.get("isUrgent") === "true",
    dateType: "UPLOAD",
    selectedChip: "Inbox",
    startDate: "",
    endDate: "",
    microCategory: [],
    filterPerYear: "",
    invoiceNumber: "",
  };

  const [filters, setFilters, handleFilterChange] = useFilters(defaultFilters);

  const { data, loading, hasLoaded, error } = useGetDocuments({
    workspaceId,
    searchTerm: searchValue,
    sort: filters.sortOption,
    readByAccountant: filters.readByAccountant,
    readByCompany: filters.readByCompany,
    urgentDocs: filters.urgentDocs,
    dateType: filters.dateType,
    startDate: filters.startDate
      ? format(new Date(filters.startDate), "yyyy-MM-dd")
      : "",
    endDate: filters.endDate
      ? format(new Date(filters.endDate), "yyyy-MM-dd")
      : "",
    macroCategory:
      filters.selectedChip === t("document.dateFilter.all")
        ? ""
        : filters.selectedChip,
    microCategory: filters.selectedMicro,
    selectedCategories,
    filterPerYear:
      months[0].name === t("document.dateFilter.all")
        ? selectedYear !== t("document.dateFilter.all")
          ? ""
          : ""
        : "",
    pageSize: pageSize,
    pageNumber,
    invoiceNumber: invoiceNumberDebounced,
    checkIfUploaded: checkIfUploaded,
  });

  useEffect(() => {
    const searchApiResponse = data?.aggregationValue;
    if (data && searchApiResponse?.microcategory) {
      // Create a map of existing microcategory bucketNames
      const existingMicroCategoriesMap = new Map<
        string,
        { name: string; value: number; checked: boolean }
      >(filters.microCategory?.map((cat: { name: string }) => [cat.name, cat]));

      // Process and update both new and existing microCategories
      searchApiResponse.microcategory
        .filter((cat: any) => cat?.bucketName !== "Inbox")
        .forEach((category: any) => {
          const categoryName = category.bucketName;
          existingMicroCategoriesMap.set(categoryName, {
            name: categoryName,
            value: category.docCount,
            checked: existingMicroCategoriesMap.has(categoryName)
              ? existingMicroCategoriesMap.get(categoryName)!.checked
              : true,
          });
        });

      handleFilterChange(
        "microCategory",
        Array.from(existingMicroCategoriesMap.values()),
      );
    }
  }, [data]);
  const handleCheckboxChange = (category: MicroCategory) => {
    let checkedCount = 0;
    let lastCheckedCategory: any = null;

    const updatedMicroCategory = filters.microCategory.map(
      (microCategory: MicroCategory) => {
        let updatedCategory = { ...microCategory };

        if (microCategory.name === category.name) {
          updatedCategory.checked = !updatedCategory.checked;
        }

        if (updatedCategory.checked) {
          checkedCount += 1;
          lastCheckedCategory = updatedCategory;
        }

        return updatedCategory;
      },
    );

    // If no checkboxes are checked, revert the last operation and return false
    if (
      checkedCount === 0 ||
      (checkedCount === 1 &&
        lastCheckedCategory &&
        !lastCheckedCategory.checked)
    ) {
      return false;
    }
    // Extracting the names of the selected micro categories
    const selectedMicro = updatedMicroCategory
      .filter((category: MicroCategory) => category.checked)
      .map((category: MicroCategory) => category.name);

    handleFilterChange("microCategory", updatedMicroCategory);
    handleFilterChange("selectedMicro", selectedMicro);
  };

  useEffect(() => {
    setPageNumber(0);
    setDisplayDataToCard([]);
  }, [
    workspaceId,
    searchValue,
    currentCompany,
    filters.sortOption,
    filters.readByAccountant,
    filters.urgentDocs,
    filters.readByCompany,
    filters.dateType,
    pageSize,
    filters.selectedChip,
    selectedYear,
    months,
    setPageNumber,
    selectedCategories,
    invoiceNumber,
    filters.startDate,
    filters.endDate,
    filters.selectedMicro,
    checkIfUploaded,
  ]);

  useEffect(() => {
    if (!loading && !error && data) {
      if (!pageNumber) return setDisplayDataToCard(documents);
      setDisplayDataToCard((prev: any) => [...prev, ...documents]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (ws?.companies.length === 0 && ws?.accountants.length === 0) {
      setDisplayPage(false);
    } else {
      setDisplayPage(true);
    }
  }, [ws]);

  const permisionData = useGetAllowedMacrocategories(workspaceId)?.data;

  const macroCategoriesData = permisionData?.aggregationValue.macrocategory;
  const canUpload = permisionData?.permissions?.uploadWeb;

  useEffect(() => {
    setUserCanUpload(canUpload);
  }, [canUpload]);

  const macroCategories = new Map(
    macroCategoriesData &&
      macroCategoriesData?.map((category: any) => [
        category?.bucketName,
        category?.docCount,
      ]),
  );

  const documents = data?.searchDocumentsResponse;

  const allYears = [...Array(10).keys()].map((n) =>
    format(subYears(new Date(), n), "yyyy"),
  );

  useEffect(() => {
    setSelectedYear(t("document.dateFilter.all"));
    handleFilterChange("selectedMicro", []);
    setSelectedCategories({});
    // setStartDate(subYears(new Date(), 15));
    // setEndDate(new Date());
  }, [filters.selectedChip]);

  //TODO: Chechk if this is need
  // useEffect(() => {
  //   handleFilterChange("selectedChip", "Inbox");
  // }, [workspaceId]);

  useEffect(() => {
    setMonths([
      {
        name: t("document.dateFilter.all"),
        value: t("document.dateFilter.all"),
      },
    ]);
  }, [monthType, selectedYear]);

  useEffect(() => {
    if (months.length === 0) {
      setMonths([
        {
          name: t("document.dateFilter.all"),
          value: t("document.dateFilter.all"),
        },
      ]);
    }
  }, [months]);

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return ws?.companies?.length || ws?.accountants?.length ? (
    <Box sx={{ backgroundColor: "hsl(199, 30%, 93%)" }}>
      {config.documentExperimentalFeature && (
        <AiAssistantFloatingBubble bottom={"500px"}>
          <AiAsistant withSideDrawer={true} workspaceId={workspaceId} userLanguage={ws.userLanguage} />
        </AiAssistantFloatingBubble>
      )}
      <PageTitle
        open={open}
        handleClose={handleClose}
        Slide={Slide}
        displayPage={displayPage}
        ws={ws}
        workspaceId={workspaceId}
        setWorkspaceId={setWorkspaceId}
      ></PageTitle>
      <DocumentHeader />
      {data && displayPage && (
        <Box
          style={{
            position: "sticky",
            top: isEmbed ? "-1px" : "45px",
            zIndex: 10,
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "hsl(199, 30%, 93%)",
            textAlign: "left",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              padding: "16px",
              backgroundColor: "white",
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <ChipsFilter
                handleFilterChange={handleFilterChange}
                filters={filters}
                macroCategories={macroCategories}
              ></ChipsFilter>
              <FileFormatSwitcher
                selectedFileFormat={selectedFileFormat}
                setSelectedFileFormat={setSelectedFileFormat}
              ></FileFormatSwitcher>
            </Stack>
            <Box>
              <CheckboxFilter
                microCategory={filters.microCategory}
                handleCheckboxChange={handleCheckboxChange}
              />
            </Box>
          </div>
        </Box>
      )}
      <Card
        sx={{ marginLeft: "16px", marginRight: "16px", minHeight: "100vh" }}
      >
        <Box sx={{ padding: "20px" }}>
          {displayPage ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              columnGap={4}
            >
              {!currentCompany?.itemId ? (
                ""
              ) : (
                <Grid item xs={4.2} style={{ maxWidth: "300px" }}>
                  <Filters
                    spaceManagementError={spaceManagementError}
                    freeSpace={spaceManagementData?.freeSpace}
                    totalSpace={spaceManagementData?.totalSpace}
                    usedSpace={spaceManagementData?.usedSpace}
                    setSearchValue={setSearchValue}
                    sortOption={filters.sortOption}
                    readByAccountant={filters.readByAccountant}
                    readByCompany={filters.readByCompany}
                    urgentDocs={filters.urgentDocs}
                    handleFilterChange={handleFilterChange}
                    dateType={filters.dateType}
                    startDate={filters.startDate}
                    endDate={filters.endDate}
                    selectedChip={filters.selectedChip}
                    setInvoiceNumber={setInvoiceNumber}
                    invoiceNumber={invoiceNumber}
                    allYears={allYears}
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    monthType={monthType}
                    months={months}
                    setMonths={setMonths}
                    setMonthType={setMonthType}
                    data={data}
                    setSelectedCategories={setSelectedCategories}
                  ></Filters>
                </Grid>
              )}
              <Grid item xs={7.8} style={{ maxWidth: "100%", flexGrow: "1" }}>
                <Documents
                  loading={loading}
                  documents={documents}
                  hasLoaded={hasLoaded}
                  data={data}
                  loadMore={loadMore}
                  workspaceId={workspaceId}
                  displayDataToCard={displayDataToCard}
                  selectedFileFormat={selectedFileFormat}
                  displayPage={displayPage}
                  pageSize={pageSize}
                ></Documents>
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="banner-notfound.png" alt="not found"></img>
            </Box>
          )}
        </Box>
      </Card>
      {!currentCompany?.itemId ? (
        ""
      ) : (
        <VaporToolbar
          contentLeft={[
            <MasiveDownloadActionButton
              downloadMassiveDocuments={downloadMassiveDocuments}
              setDownloadMassiveDocuments={setDownloadMassiveDocuments}
              setOpen={setOpen}
              setOpenModal={setOpenModal}
              documentList={displayDataToCard}
              workspaceId={workspaceId}
              openModal={openModal}
            />,
          ]}
          contentRight={
            canUpload && [
              <UploadSmartComponent
                loggedItemId={currentCompany?.itemId}
                setCheckIfUploaded={setCheckIfUploaded}
                isAccountant={ws?.itemType !== "COMPANY" ? true : false}
                subtitle={subtitle}
                workspaceId={workspaceId}
              />,
            ]
          }
          size="large"
          variant="regular"
        ></VaporToolbar>
      )}{" "}
      {openModal && (
        <DownloadConfirmationAlert
          displayDataToCard={displayDataToCard}
          setOpenModal={setOpenModal}
          setDownloadMassiveDocuments={setDownloadMassiveDocuments}
        />
      )}
    </Box>
  ) : (
    <>
      <PageTitle
        open={open}
        handleClose={handleClose}
        Slide={Slide}
        displayPage={displayPage}
        ws={ws}
        workspaceId={workspaceId}
        setWorkspaceId={setWorkspaceId}
      ></PageTitle>
      <NoAccessError />
    </>
  );
};
