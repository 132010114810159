import { useAuth, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function useDeleteLinkedDocument(
  id,
  idToDelete,
  workspaceId,
  clickToConfirm
) {
  const authData = useAuth();
  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "delete",

    lazy: true,
    headers: buildHeaders({ authData }),

    url: `documentsApi://api/v1/documents/${id}/unlink/${idToDelete}?workspaceId=${workspaceId}`,
  });

  useEffect(() => {
    if (idToDelete) {
      fetch();
    }
  }, [clickToConfirm]);

  return { data, loading, hasLoaded, fetch, error };
}
