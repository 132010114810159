import { useAuth, usePost } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "./buildHeaders";
import React from "react";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function usePostUploadDocument({ content, filename }: any) {
  const authData = useAuth();
  const { workspaceId, selectedTenant: currentCompany } =
    useClientInformationStore();

  const clientId = currentCompany?.itemId;

  const payload = {
    content: content,
    encryptedOnUpload: false,
    filename: filename,
    loggedItemId: clientId,
    metadata: [],
    note: "",
    source: "web",
    typeCode: "inbox.inbox.inbox",
    urgentDoc: false,
    visibleByAccountantOnly: false,
    workspaceId,
  };

  const { data, loading, hasLoaded, fetch, error } = usePost(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/documents`,
    payload,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    if (!!content && !!filename) {
      fetch();
    }
  }, [content, filename]);

  return { data, loading, hasLoaded, fetch, error };
}
