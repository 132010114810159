import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useAuth, useGet, useTranslation } from "@onefront/react-sdk";
import {
  useCurrentTenant,
  useCurrentWorkspace,
} from "@drift/oneplatfront-components";
import { useClientInformationStore } from "../../store/useClientInformationStore";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function useSelectedWorkspace() {
  const {
    tenantId,
    selectedTenant,
    setWorkspace,
    setTenant,
    setWorkspaceId,
    setTenantId,
    setCounterpartId,
    counterpartId,
    setOneBoxCompanyName,
  } = useClientInformationStore();
  const authData = useAuth();
  const { i18n } = useTranslation("DOCS");

  const { tenant: oneBoxTenant } = useCurrentTenant(true);
  const { workspace: oneBoxWorkspace } = useCurrentWorkspace(true);

  const urlParams = new URLSearchParams(window.location.search);
  const querystringTenant = urlParams.get("companyId");
  const querystringCounterpartId = urlParams.get("counterpart");
  const counterpart =
    querystringCounterpartId || oneBoxWorkspace?.itemId ;

  const { data: ws, fetch } = useGet(
    `documentsApi://api/v1/documents/getAllowedWorkspaces?itemId=${tenantId}&counterpart=${counterpart}`,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    },
  );

  useEffect(() => {
    if (oneBoxWorkspace) {
      setOneBoxCompanyName(oneBoxWorkspace.description);
    }
  }, [oneBoxWorkspace, setOneBoxCompanyName]);

  useEffect(() => {
    if (oneBoxTenant?.id && oneBoxTenant.id !== selectedTenant?.id) {
      setTenant(oneBoxTenant);
      setTenantId(oneBoxTenant.itemId);
      setWorkspace(null);
      setWorkspaceId(null);
    } else if (querystringTenant) {
      setTenant({ itemId: querystringTenant });
      setTenantId(querystringTenant);
      setWorkspace(null);
      setWorkspaceId(null);
    }
  }, [oneBoxTenant?.id]);

  useEffect(() => {
    if (tenantId && counterpart && counterpart !== counterpartId) {
      fetch();
      setCounterpartId(counterpart);
    }else (
        setWorkspace({userLanguage: 'it',
          itemType: 'ACCOUNTANT',
          companies: [],
          accountants: []})
    )
  }, [counterpart, tenantId]);

  useEffect(() => {
    if (ws) {
      setWorkspace(ws);
      setWorkspaceId(ws ? [...ws?.companies, ...ws?.accountants][0]?.id : null);
      i18n.changeLanguage(ws?.userLanguage || "it");
    }
  }, [ws]);

  return;
}
