import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stack from "@vapor/react-material/Stack";
import { useTranslation } from "@onefront/react-sdk";
import { Alert, AlertTitle, Button } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";
import { useDeleteDocument } from "../hooks/useDeleteDocument";
import { useEffect, useState } from "react";
import { faClose } from "@fortawesome/pro-solid-svg-icons";

export const DeleteDocumentAlert = ({
  id,
  documentTitle,
  workspaceId,
  setStatusOnDelete,
  onCancel,
  setShowError,
  onDelete,
}) => {
  const [documentIdToDelete, setDocumentIdToDelete] = useState("");
  const [clickToConfirm, setClickToConfirm] = useState(false);
  const { t } = useTranslation("DOCS");
  const { data, loading, error } = useDeleteDocument(
    documentIdToDelete,
    workspaceId
  );

  useEffect(() => {
    if (clickToConfirm) {
      setDocumentIdToDelete(id);
    }
    setClickToConfirm(false);
    onDelete(data);
  }, [clickToConfirm, data]);

  if (data) {
    setStatusOnDelete(true);
  }

  if (error) {
    setShowError(true)
  }

  return (
    <Stack style={{ display: "flex" }}>
      <Alert
        sx={{ width: "100%" }}
        variant="outlined"
        severity="warning"
        size="large"
        icon={<FontAwesomeIcon icon={faTriangleExclamation} />}
        action={
          <IconButton size="small" onClick={onCancel}>
            <FontAwesomeIcon size="xs" icon={faClose} />
          </IconButton>
        }
      >
        <AlertTitle sx={{ paddingTop: "3px" }}>
          {t("document.attention")}: {t("document.deletingAlert")}{" "}
          {documentTitle}
        </AlertTitle>
        <div
          style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}
        >
          <Button
            variant="outlined"
            style={{ backgroundColor: "white", marginRight: "15px" }}
            onClick={onCancel}
          >
            <Typography color={"blue"}>{t("classification.cancel")}</Typography>
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "linear-gradient(90deg,#0090d1,#00c3ea)",
            }}
            onClick={() => {
              if (window.aptrinsic) {
                window.aptrinsic("track", "docs:DELETE-DOCUMENT");
              }
              setClickToConfirm(true);
            }}
          >
            <Typography style={{ color: "white" }}>
              {t("classification.delete")}
            </Typography>
          </Button>
        </div>
      </Alert>
    </Stack>
  );
};
