import { useEffect, useState } from "react";
import {
  Stack,
  IconButton,
  Divider,
  Menu,
  MenuItem,
} from "@vapor/react-material";
import TextField from "@vapor/react-material/TextField";
import Switch from "@vapor/react-material/Switch";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@vapor/react-material/Tooltip";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import { Autocomplete, Button } from "@vapor/react-material";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import FileUploader from "../components/create-folder/FileUploader";
import { StyledStackWithPadding } from "../styledComponents/CreateFolderPageStyled";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCreateFolder } from "../hooks/useCreateFolder";
import { FolderPayload } from "../types/FolderInteface";
import { Typography } from "@vapor/react-extended";
import { useGetExistingDocuments } from "../hooks/useGetExistingDocuments";
import SearchDocumentComponent from "../components/create-folder/SearchDocumentsComponent";
import SuccessAlert from "../components/alerts/SuccessAlert";
import { useParams } from "react-router-dom";
import { useGetFolderById } from "../hooks/useGetFolderById";
import { usePutUpdateFolder } from "../hooks/usePutUpdateFolder";
import { useClientInformationStore } from "../../store/useClientInformationStore";
import FolderCreatedSuccessfully from "../components/FolderCreatedSuccessfully";

const CreateFolderPage = () => {
  const [folderName, setFolderName] = useState<string | null>();
  const [checked, setChecked] = useState(true);
  const [classification, setClassification] = useState<string | null>();
  const [referencePeriod, setReferencePeriod] = useState<string | null>();
  const [referenceIds, setReferenceIds] = useState<string[]>([]);
  const [clickToConfirm, setClickToConfirm] = useState(false);
  const [uploadFolder, setUploadFolder] = useState(false);
  const [folderToBeEdited, setFolderToBeEdited] = useState();
  const [mode, setMode] = useState<"create" | "edit">("create");

  const { workspaceId, selectedWorkspace: ws } = useClientInformationStore();

  const workspaceDescription = ws
    ? `${
        [...ws?.companies, ...ws?.accountants].find((x) => x.id === workspaceId)
          ?.description
      }`
    : "";

  // const [createdBy, setCreatedBy] = useState<string>("");
  // const [createdByFullName, setCreatedByFullName] = useState("");
  // const [itemClassifier, setItemClassifier] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const concatDocumentIds = (newIds: string[]) => {
    if (newIds) {
      const uniqueResults = Array.from(new Set([...referenceIds, ...newIds]));
      setReferenceIds(uniqueResults);
    }
  };

  const { data, loading, error, fetch, hasLoaded } = useCreateFolder({
    folderName,
    referenceIds,
    classification,
    referencePeriod,
  });

  const {
    data: folderById,
    loading: loadingFolder,
    error: folderError,
    fetch: fetchFolder,
    hasLoaded: hasFolderLoaded,
  } = useGetFolderById({
    id,
  });

  const {
    data: updatedFolder,
    loading: loadingUpdatedFolder,
    error: updatedFolderError,
    fetch: fetchUpdatedFolder,
    hasLoaded: hasUpdatedFolderLoaded,
  } = usePutUpdateFolder({
    id,
    referenceIds,
    classification,
    referencePeriod,
  });

  useEffect(() => {
    if (uploadFolder && mode === "create") {
      fetch();
    } else if (uploadFolder && mode === "edit") {
      fetchUpdatedFolder();
    }
  }, [uploadFolder, mode]);

  useEffect(() => {
    if (id && folderById) {
      setMode("edit");
      setFolderToBeEdited(folderById);
      setClassification(folderById.classification);
      setReferencePeriod(folderById.referencePeriod);
      setReferenceIds(folderById?.referenceIds);
      setFolderName(folderById.text);
    }
  }, [id, folderById]);

  const clearForm = () => {
    setReferenceIds([]);
    setFolderName(null);
    setClassification(null);
    setReferencePeriod(null);
    setChecked(false);
    setUploadFolder(false);
  };

  return (
    <>
      <Stack
        direction="column"
        spacing={3}
        display="flex"
        paddingTop="20px"
        marginLeft="16px"
        marginRight="16px"
      >
        <Stack direction="row" display="flex" alignItems="center">
          <IconButton
            onClick={() => navigate("/all-folders")}
            sx={{
              height: "14px",
              cursor: "pointer",
              textAlign: "left",
              paddingRight: "10px",
              color: "#008FD6",
              width: "16px",
            }}
          >
            <FontAwesomeIcon size="sm" icon={faArrowLeft} color={"#0090d1"} />
          </IconButton>
          <div
            style={{
              display: "flex",
              flex: "auto",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontWeight: "600",
                color: "#0077ad",
                lineHeight: "1.5",
                fontSize: "20px",
              }}
            >
              CREA NUOVO FASCICOLO
            </Typography>
            <Typography
              sx={{
                margin: "0px",
                fontFamily: "Cairo",
                color: "rgb(98, 121, 132)",
                fontWeight: "400",
                fontSize: "12px",
                padding: "0px",
                lineHeight: "1",
              }}
            >
              {workspaceDescription}
            </Typography>
          </div>
        </Stack>
        <Divider />
        {data && <SuccessAlert message="Folder was created successfully" />}
        {updatedFolder && (
          <SuccessAlert message="Folder was updated successfully" />
        )}
        {data || updatedFolder ? (
          <FolderCreatedSuccessfully />
        ) : (
          <StyledStackWithPadding width="50%" alignSelf="center">
            <Typography
              sx={{
                color: "#566B76",
                fontFamily: "Cairo",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              Crea un nuovo fascicolo per Impianti & ristrutturazioni snc. I
              campi con l’asterisco (*) sono obbligatori.
            </Typography>
            <label>Nome fascicolo*</label>
            <TextField
              placeholder="Inserisci il nome del fascicolo"
              value={folderName}
              disabled={id && folderById}
              variant="outlined"
              sx={{
                width: "full",
              }}
              onChange={(e: any) => {
                setFolderName(e?.target.value);
              }}
            />
            <FormControlLabel
              value="right"
              control={
                <Switch
                  checked={checked}
                  onClick={() => setChecked((prev) => !prev)}
                />
              }
              label={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    marginRight={1}
                    fontFamily={"Roboto"}
                    fontSize={"14px"}
                  >
                    Visibile solo al tuo studio
                  </Typography>
                  <Tooltip color="#0090d1" title={"title"} arrow>
                    <FontAwesomeIcon
                      size="1x"
                      color="#0090d1"
                      icon={faCircleInfo}
                    />
                  </Tooltip>
                </div>
              }
              labelPlacement="end"
              onChange={() => {}}
            />
            <Stack direction="row" display="flex" style={{ width: "100%" }}>
              <div style={{ flex: 2, marginRight: "10px" }}>
                <label>Tipologia*</label>
                <Autocomplete
                  disablePortal
                  style={{ width: "100%" }}
                  value={classification ?? ""}
                  onChange={(e, value: any) => {
                    setClassification(value);
                  }}
                  id="grouped-demo"
                  options={[
                    "CONTENZIOSO",
                    "DICHIARAZIONI",
                    "PERSONALIZZATO",
                    "BANCA",
                    "BILANCIO",
                  ]}
                  renderInput={(params) => (
                    <TextField
                      placeholder={"Choose option"}
                      value={classification ?? ""}
                      {...params}
                    />
                  )}
                />
              </div>
              <div style={{ flex: 1 }}>
                <label>Periodo di riferimento*</label>
                <Autocomplete
                  style={{ width: "100%" }}
                  disablePortal
                  value={referencePeriod ?? ""}
                  onChange={(e, value: any) => {
                    setReferencePeriod(value);
                  }}
                  options={[
                    "2023",
                    "2022",
                    "2021",
                    "2020",
                    "2019",
                    "2018",
                    "2017",
                    "2016",
                    "2015",
                    "2014",
                    "2013",
                  ]}
                  renderInput={(params) => (
                    <TextField
                      placeholder={"Choose option"}
                      value={referencePeriod ?? ""}
                      {...params}
                    />
                  )}
                />
              </div>
            </Stack>
            <SearchDocumentComponent
              onDocumentSelect={concatDocumentIds}
              prefilledIds={folderById?.referenceIds}
            />
            <Typography
              sx={{
                fontFamily: "Cairo",
                fontSize: "16px",
                fontWeight: "600",
                color: "#566B76",
                textAlign: "center",
              }}
            >
              Oppure
            </Typography>
            <FileUploader
              clickToConfirm={clickToConfirm}
              setClickToConfirm={setClickToConfirm}
              onFilesUpload={concatDocumentIds}
              setUploadFolder={setUploadFolder}
            />
          </StyledStackWithPadding>
        )}
      </Stack>
      <VaporToolbar
        size="medium"
        variant="regular"
        contentRight={[
          <>
            <Button
              onClick={() => {
                navigate("/all-folders");
              }}
              variant="outlined"
              sx={{ marginRight: "10px" }}
              size="small"
            >
              Chiudi
            </Button>
            <Button
              onClick={() => {
                setClickToConfirm(true);
                // clearForm();
              }}
              variant="contained"
              size="small"
              disabled={!folderName || !classification || !referencePeriod}
            >
              {mode === "edit" ? "Modifica fascicolo" : "Crea fascicolo"}
            </Button>
          </>,
        ]}
      />
    </>
  );
};
export default CreateFolderPage;
