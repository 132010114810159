import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar";
import { faMessageLines } from "@fortawesome/pro-regular-svg-icons/faMessageLines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "@vapor/react-material";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { enGB, es, it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { SdiReceipt } from "../document-detail-utils/sdiStatus";
import { usePatchToToggleVisibility } from "../hooks/usePatchToToggleVisibility";
import CustomizedAccordions from "./AccordionDocumentDetails";
import VisibilityStatus from "./VisibilityStatus";
import StatusBadge from "@vapor/react-custom/StatusBadge";

interface CommentCountProps {
  unreadNotes: number;
  notesCount: number;
  styledNotesCount: string;
}
interface DocumentDetailsProps {
  setReload: any;
  data: any;
  expanded: boolean;
  workspaceId: string;
  setexpandedComment: any;
  expandedComment: boolean;
  fetchDetails: any;
  loading: boolean;
  commentCount: CommentCountProps;
  setNewName: any;
  NewName: string;
  isAccountant: boolean;
  setCheckVisibility: Function;
}

const DocumentDetails = ({
  setReload,
  data,
  expanded,
  workspaceId,
  setexpandedComment,
  expandedComment,
  fetchDetails,
  loading,
  commentCount,
  setNewName,
  NewName,
  isAccountant,
  setCheckVisibility,
}: DocumentDetailsProps) => {
  const { t, i18n } = useTranslation("DOCS");

  const [toggleVisibility, setToggleVisibility] = useState(null);

  const locale =
    i18n.language === "it" ? it : i18n.language === "es" ? es : enGB;
  const infoList = data?.infoList || [];

  const filteredInfoList = infoList.filter(
    (x: { label: string }) =>
      x.label === "invoiceNumber" || x.label === "DATE" || x.label === "AMOUNT"
  );
  useEffect(() => {
    if ((window as any).aptrinsic) {
      (window as any).aptrinsic("track", "docs:OPEN-DOCUMENT-DETAILS");
    }
  }, []);

  const hidden = data?.visibleByAccountantOnly;
  const microName = data?.standardTags?.filter(
    (cat: { name: string }) => cat.name === "microcategory"
  )[0].value;

  const { data: visibiltyData } = usePatchToToggleVisibility({
    id: data?.documentId,
    workspaceId,
    toggleVisibility,
  });
  useEffect(() => {
    if (visibiltyData) {
      setCheckVisibility(toggleVisibility);
    }
  }, [visibiltyData]);


  return (
    <>
      <Collapse orientation="vertical" in={!expanded}>
        <Stack direction="column" gap={1} paddingLeft={3}>
          <Stack direction="row" gap={1} alignItems="center">
            <FontAwesomeIcon
              color="#005075"
              size="1x"
              icon={faFileInvoiceDollar}
            />
            <Typography variant="titleMedium" color="primary.textTitleColor">
              {microName === "Inbox"
                ? t("classification.unclassified")
                : t("micro." + microName)}
            </Typography>
          </Stack>
          <Stack direction="row" gap={4}>
            {filteredInfoList.map((info: { label: string; value: any }) => {
              return (
                <Stack direction="column" spacing={1}>
                  <Typography variant="bodyLarge500">
                    {t("classification.2." + info.label)}
                  </Typography>
                  <Typography variant="displaySmall">{info.value}</Typography>
                </Stack>
              );
            })}
          </Stack>
          <SdiReceipt data={infoList} t={t} locale={locale}></SdiReceipt>
          <VisibilityStatus
            isInvoice={data?.einvoice}
            toggleVisibility={toggleVisibility}
            setToggleVisibility={setToggleVisibility}
            hidden={hidden}
            isAccountant={isAccountant}
          />
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <FontAwesomeIcon color="#0090d1" size="1x" icon={faMessageLines} />
            <Button
              size="large"
              onClick={() => setexpandedComment(!expandedComment)}
              variant="text"
            >
              {expandedComment
                ? t("classification.open")
                : t("classification.close")}
            </Button>
            {commentCount.notesCount > 0 && (
              <StatusBadge
                color="info"
                bgColor={commentCount.unreadNotes > 0 ? "#D82829" : "#E4E6E7"}
                label={commentCount.styledNotesCount}
              ></StatusBadge>
            )}
          </Stack>
        </Stack>
        <div style={{ padding: 7 }}>
          <CustomizedAccordions
            setNewName={setNewName}
            NewName={NewName}
            setReload={setReload}
            data={data}
            standardTags={data?.standardTags}
            workspaceId={workspaceId}
            loading={loading}
            fetchDetails={fetchDetails}
          />
        </div>
      </Collapse>
    </>
  );
};

export default DocumentDetails;
