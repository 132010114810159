import {
  faArrowLeft,
  faCheckCircle,
  faCircleMinus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import IconButton from "@vapor/react-material/IconButton";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../documents/document-components/Loading";
import { DeleteDocumentAlert } from "./document-details-components/DeleteDocumentAlert";
import DetailContainer from "./document-details-components/DetailContainer";
import DocumentDetailUpperBar from "./document-details-components/DocumentDetailUpperBar";
import { DocumentDetailsFooter } from "./document-details-components/DocumentDetailsFooter";
import { useGetDocumentById } from "./hooks/useGetDocumentById";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";
import { Alert, AlertTitle } from "@vapor/react-material";
import { Stack } from "@vapor/react-material/Stack";
import { useClientInformationStore } from "../store/useClientInformationStore";

export const DocumentPageDetail = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [expandedComment, setexpandedComment] = useState(true);
  const [expandedAssistant, setExpandedAssistant] = useState(false);
  const [statusOnDelete, setStatusOnDelete] = useState(false);
  const [previewType, setPreviewType] = useState("");
  const { id, workspaceId } = useParams();
  const [reload, setReload] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [NewName, setNewName] = useState("");
  const [response, setResponse] = useState();
  const [showError, setShowError] = useState(false);
  const [checkVisibility, setCheckVisibility] = useState(false);

  const { data, loading, error, fetch } = useGetDocumentById({
    id,
    workspaceId,
    reload,
    setReload,
    previewType,
    checkVisibility,
    setCheckVisibility,
  });
  if (statusOnDelete) {
    setStatusOnDelete(false);
    setTimeout(() => navigate("/"), 2000);
  }
  useEffect(() => {
    if (expanded === true) {
      setexpandedComment(false);
    } else {
      setexpandedComment(true);
    }
  }, [expanded]);


  useEffect(() => {
    if (window.aptrinsic) {
      window.aptrinsic("track", "docs:ACCESS-COMMENTS");
    }
  }, [expandedComment]);
  const { selectedTenant: currentCompany, selectedWorkspace: ws } =
    useClientInformationStore();
  let workspaceData = { data: ws };
  let companyName = workspaceData?.data?.accountants[0]?.description;
  const itemClassification = workspaceData?.data?.itemType;
  const { t } = useTranslation("DOCS");

  const documentTitle =
    data && data?.title.indexOf("Invoice issued to") !== -1
      ? t("document.invoice.issued") + data?.title.slice(17)
      : data?.title.indexOf("Invoice received from") !== -1
      ? t("document.invoice.received") + data?.title.slice(21)
      : data?.title;

  const [commentCount, setCommentCount] = useState(0);
  const goBack = () => {
    window.history.go(-1);
  };
  return (
    <>
      <VaporPage
        headerLeft={[
          <IconButton onClick={goBack} size="large">
            <FontAwesomeIcon
              icon={faArrowLeft}
              color={"#0090d1"}
              size={"2xs"}
            ></FontAwesomeIcon>
          </IconButton>,
        ]}
        // title={NewName ? NewName : data?.fileName}
        title={data ? documentTitle : NewName}
        subtitle={data ? data.subTitle : companyName}
        sx={{
          "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
            color: "#005075",
            fontWeight: "normal",
          },
          "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
            color: "#5a6872",
          },
        }}
      >
        {loading ? (
          <div style={{ marginTop: "15%", marginLeft: "3%" }}>
            <Loading />
          </div>
        ) : (
          <div>
            <DocumentDetailUpperBar
              id={id}
              expandedComment={expandedComment}
              setexpandedComment={setexpandedComment}
              data={data}
              setExpanded={setExpanded}
              expanded={expanded}
              commentCount={commentCount}
              setExpandedAssistant={setExpandedAssistant}
              expandedAssistant={expandedAssistant}
            />
            <DetailContainer
              setCheckVisibility={setCheckVisibility}
              isAccountant={ws?.itemType !== "COMPANY" ? true : false}
              NewName={NewName}
              setNewName={setNewName}
              setPreviewType={setPreviewType}
              fetchDetails={fetch}
              loading={loading}
              setReload={setReload}
              workspaceId={workspaceId}
              setexpandedComment={setexpandedComment}
              data={data}
              expanded={expanded}
              expandedComment={expandedComment}
              workspaceData={workspaceData}
              commentCount={commentCount}
              setCommentCount={setCommentCount}
              setExpandedAssistant={setExpandedAssistant}
              expandedAssistant={expandedAssistant}
            />
          </div>
        )}
      </VaporPage>
      <VaporToolbar
        contentLeft={[
          <DocumentDetailsFooter
            readOnly={data?.readOnly}
            isInvoice={data?.einvoice}
            itemClassification={itemClassification}
            setStatusOnDelete={setStatusOnDelete}
            id={id}
            onOpenModal={() => setOpenModalDelete(!openModalDelete)}
            workspaceId={workspaceId}
          />,
        ]}
        contentRight={
          openModalDelete && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "100%",
              }}
            >
              <DeleteDocumentAlert
                documentTitle={documentTitle}
                setStatusOnDelete={setStatusOnDelete}
                id={id}
                workspaceId={workspaceId}
                onDelete={setResponse}
                setShowError={setShowError}
                onCancel={() => {
                  setOpenModalDelete(false);
                }}
              />
              {!!response && (
                <Stack style={{ display: "flex", marginTop: "5px" }}>
                  <Alert
                    sx={{ width: "100%" }}
                    variant="outlined"
                    size="large"
                    severity="success"
                    icon={<FontAwesomeIcon icon={faCheckCircle} />}
                  >
                    <AlertTitle>{t("document.deletedSuccessfully")}</AlertTitle>
                  </Alert>
                </Stack>
              )}
              {error && (
                <Stack style={{ display: "flex", marginTop: "5px" }}>
                  <Alert
                    sx={{ width: "100%" }}
                    variant="outlined"
                    size="large"
                    severity="error"
                    icon={<FontAwesomeIcon size="lg" icon={faCircleMinus} />}
                  >
                    <AlertTitle>
                      {t("document.error")} {t("document.failedDeletion")}
                    </AlertTitle>
                  </Alert>
                </Stack>
              )}
            </div>
          )
        }
      ></VaporToolbar>
    </>
  );
};
