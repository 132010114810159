import { VaporTag } from "@vapor/react-custom";
import { Stack } from "@vapor/react-material";
import { format } from "date-fns";
import React from "react";
import { useTranslation } from "@onefront/react-sdk";

interface DocumentLinkTagsProps {
  startDate: string | null;
  setStartDate: any | null;
  endDate: string | null;
  setEndDate: any | null;
  dateType: string;
  setDateType: (type: string) => void;
  selectedDocuments: string[];
  setSelectedDocuments: (documents: string[]) => void;
  selectedChip: string | null;
  setSelectedChip: any;
}

export const DocumentLinkTags: React.FC<DocumentLinkTagsProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  dateType,
  setDateType,
  selectedChip,
  setSelectedChip,
}) => {
  const { t } = useTranslation("DOCS");

  const handleDelete = (tagLabel: string) => {
    if (tagLabel === "Start Date") {
      // Clear the startDate state
      setStartDate(null);
    } else if (tagLabel === "End Date") {
      // Clear the endDate state
      setEndDate(null);
    } else if (tagLabel === "Date Type") {
      // Clear the dateType state
      setDateType("UPLOAD");
    } else if (tagLabel === "Selected Documents") {
      // Clear the selectedDocuments state
      setEndDate(null);
    } else if (tagLabel === "Selected Chip") {
      // Clear the selectedChip state
      setSelectedChip();
    }
  };
  const startDateConverter = Number(startDate);
  const endDateConverter = Number(endDate);
  const formattedStartDate = startDate
    ? format(startDateConverter, "yyyy-MM-dd'T'hh:mm")
    : "";
  const formattedEndDate = endDate
    ? format(endDateConverter, "yyyy-MM-dd'T'hh:mm")
    : "";

  return (
    <Stack direction="row" spacing={1} marginTop={"10px"}>
      {startDate && (
        <VaporTag
          label={`${t("document.MonthFilter.startDate")}: ${formattedStartDate}`}
          onDelete={() => handleDelete("Start Date")}
        />
      )}
      {endDate && (
        <VaporTag
          label={`${t("document.MonthFilter.endDate")}: ${formattedEndDate}`}
          onDelete={() => handleDelete("End Date")}
        />
      )}
      {dateType && (
        <VaporTag
          label={`${t("classification.dateType")}: ${t("link." + dateType)}`}
          onDelete={() => handleDelete("Date Type")}
        />
      )}
      {selectedChip && (
        <VaporTag
          label={`${t("classification.selectedMacro")}: ${selectedChip}`}
          onDelete={() => handleDelete("Selected Chip")}
        />
      )}
    </Stack>
  );
};
