import { useEffect, useMemo, useState } from "react";
import { checkRequiredProperties } from "../common-utils/checkRequiredProperties";
import { useGetDynamicInputStateFromCache } from "../newDocuments/hooks/useGetDynamicInputStateFromCache";
import { updateDynamicObject } from "./classification-utils/updateDynamicObject";
function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export const useDataMapInputState = (
  dataTypeCodes,
  activeStep,
  standardTags
) => {
  const [errorLabel, setErrorLabel] = useState(false);
  const [dynamicInput, handleDynamicInputChange] =
    useGetDynamicInputStateFromCache({});
  const [dataMapInputState, setDataMapInputState] = useState({});
  const [step, setStep] = useState();
  const checkIfNoDataInCache = isObjectEmpty(dynamicInput);

  const onChangeByName = (name) => (event) => {
    setDataMapInputState((prev) => ({ ...prev, [name]: event.target.value }));
  };
  useMemo(() => {
    if (checkIfNoDataInCache) {
      setDataMapInputState(
        updateDynamicObject(standardTags, dataMapInputState)
      );
    } else {
      setDataMapInputState(dynamicInput);
    }
  }, [step, checkIfNoDataInCache]);

  useEffect(() => {
    if (dataMapInputState || !isObjectEmpty(dynamicInput)) {
      handleDynamicInputChange(dataMapInputState);
    }
  }, [dataMapInputState, handleDynamicInputChange, dynamicInput]);

  useEffect(() => {
    setStep(activeStep);
  }, []);

  const filteredValues = useMemo(() => {
    dataTypeCodes?.filter((filteredDataType) => filteredDataType.required);
  }, [dataTypeCodes]);

  useEffect(() => {
    if (activeStep > 1 && dataMapInputState) {
      const requiredFieldCheck = checkRequiredProperties(
        dataMapInputState,
        filteredValues
      );
      setErrorLabel(requiredFieldCheck);
    }
  }, [dataMapInputState, activeStep, filteredValues]);

  useEffect(() => {
    if (dataTypeCodes && isObjectEmpty(dataMapInputState)) {
      const dataMap = {};
      for (const el of dataTypeCodes) {
        dataMap[el.name] = "";
      }
      setDataMapInputState(dataMap);
    }
  }, [dataTypeCodes, dataMapInputState]);

  return {
    dataMapInputState,
    onChangeByName,
    setDataMapInputState,
    errorLabel,
  };
};

export default useDataMapInputState;
