import { faCircleMinus } from "@fortawesome/pro-solid-svg-icons/faCircleMinus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Stack from "@vapor/react-material/Stack";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Alert from "@vapor/react-material/Alert";

export default function ErrorAlert() {
  const { t } = useTranslation("DOCS");
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert
        sx={{ height: "64px", margintop: "5px" }}
        variant="outlined"
        severity="error"
        icon={<FontAwesomeIcon size="lg" icon={faCircleMinus} />}
      >
        <Typography
          fontFamily={"Roboto"}
          fontSize={"16px"}
          fontWeight={500}
          marginTop={"2px"}
        >
          {t("classification.attention")}: {t("classification.errorAlert")}
        </Typography>
      </Alert>
    </Stack>
  );
}
