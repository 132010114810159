import Typography from "@vapor/react-extended/ExtendedTypography";
import Stack from "@vapor/react-material/Stack";
import Divider from "@vapor/react-material/Divider";
import Box from "@vapor/react-material/Box";
import { useTranslation } from "@onefront/react-sdk";

function DocumentHeader() {
  const {t} = useTranslation("DOCS")
  return (
    <Box sx={{ backgroundColor: "hsl(199, 30%, 93%)" }}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        sx={{
          backgroundColor: "white",
          marginLeft: "16px",
          marginRight: "16px",
          padding: "30px"
        }}
      >
        <img src="Head-Documenti.svg" alt=""></img>
        <Stack paddingTop={2} marginLeft="20px" spacing={1}>
          <Stack direction="row" spacing={1}>
            <Typography
              fontSize={35}
              color="#337694"
              fontFamily="cairo-thin,sans-serif"
              lineHeight="0.8"
              fontWeight="100"
            >
              {t("document.header.title1")}
            </Typography>
            <Typography
              fontSize={35}
              fontFamily="cairo-regular,sans-serif"
              color="#04567c"
              lineHeight="0.8"
              fontWeight="bold"
            >
              {t("document.header.title2")}
            </Typography>
          </Stack>
          <Typography
            fontSize={35}
            color="#337694"
            fontFamily="cairo-thin,sans-serif"
            lineHeight="0.8"
            fontWeight="100"
          >
            {t("document.header.title3")}
          </Typography>
          <Stack direction="column">
            <Typography
              paddingTop="15px"
              fontFamily="roboto"
              color="#45555E"
              whiteSpace="nowrap"
            >
              {t("document.header.subTitle1")}
            </Typography>
            <Typography fontFamily="roboto" color="#45555E">
              {t("document.header.subTitle2")}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Divider
        variant="fullwidth"
        sx={{ borderWidth: "initial", marginLeft: "16px", marginRight: "16px" }}
      ></Divider>
    </Box>
  );
}

export default DocumentHeader;
