import React from "react";

const AiAssistantFloatingBubble = ({ children, bottom = "300px" }) => {
  return <div style={{ ...styles.bubble, bottom: bottom }}>{children}</div>;
};

const styles = {
  bubble: {
    position: "fixed",
    right: "20px",
    backgroundColor: "#7745EC",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
    zIndex: 1000,
  },
};

export default AiAssistantFloatingBubble;
