import Select from "@vapor/react-material/Select";
import FormControl from "@vapor/react-material/FormControl";
import InputLabel from "@vapor/react-material/InputLabel";
import MenuItem from "@vapor/react-material/MenuItem";
import { useTranslation } from "@onefront/react-sdk";




export const SortingFilter = ({ sortOption, setSortOptions }) => {
  const { t } = useTranslation("DOCS");
  const sortingFilterOptions = [
    { key: "OLDEST_UPLOADS",   value: t("document.sort.leastRecent")},
    { key: "LATEST_UPLOADS",   value: t("document.sort.mostRecent")},
    { key: "OLDEST_DOCUMENTS", value: t("document.sort.leastRecentDocument") },
    { key: "LATEST_DOCUMENTS", value: t("document.sort.mostRecentDocument") },
    { key: "HIGHER_MATCH",     value: t("document.sort.match")}
  ]

  return (
    <FormControl sx={{ width: "300px" }}>
      <InputLabel>{t("document.sort.title")}</InputLabel>
      <Select
        value={sortOption}
        onChange={(e) => setSortOptions(e.target.value)}
      >
        {sortingFilterOptions.map(({ key, value }, index) => (
          <MenuItem key={index} value={key}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
