import onefront from "@onefront/react-sdk";
import OneBox from "@drift/oneplatfront";
import { workspaceSelectorService } from "@drift/oneplatfront-components";
import { useSelectedWorkspace } from "./views/documents/hooks/useSelectedWorkspace";
import { useClientInformationStore } from "./views/store/useClientInformationStore";
import { documentDetail } from "./views/document-detail";
import { documentClassifications } from "./views/classification";
import { documents } from "./views/documents";
import { documents as newDocsPage } from "./views/newDocuments";
import { gainsights } from "./features/gainsight";
import { config } from "./config";
import { docPreviewNavigation } from "./views/DocPreviewNavigation";
import { folders } from "./views/folders/folders-component";

const env = process.env.REACT_APP_REDIRECT;
const docsPageToRender = config.documentExperimentalFeature
  ? newDocsPage
  : documents;

const WrappersComponent = ({ children }: any) => {
  const { selectedTenant, selectedWorkspace } = useClientInformationStore();
  useSelectedWorkspace();

  return children;
};

export const wrapperService = () => ({
  target: "$ONE_LAYOUT_ROUTES_AFTER",
  handler: {
    component: ({ children }: any) => (
      <WrappersComponent>{children}</WrappersComponent>
    ),
  },
});

const urlParams = new URLSearchParams(window.location.search);
const companyIdExists = urlParams.has("companyId");
const counterpartExists = urlParams.has("counterpart");

const run = companyIdExists && counterpartExists ? onefront.run : OneBox.run;
const services =
  companyIdExists && counterpartExists
    ? [wrapperService]
    : [wrapperService, workspaceSelectorService];

run({
  settings: {
    notistack: {
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    },
    one: {
      box: {
        app: {
          id: "dms", // <-- Id of the Launchpad's Products that we want to preload when the user haven't already picked one
        },
        module: {
          name: "DMS", // <-- Name of the module to be developed
          version: "1.0.0", // <-- Version of the module to be developed
        },
      },
      loading: {
        delay: 2000,
      },
      layout: {
        title: "Documents",
      },
      i18n: {
        options: {
          debug: true,
          lng: "it",
          fallbackLng: "it",
          load: "all",
          defaultNS: [],
        },
      },
      gainsight:{
        enabled: true
      },
      auth: {
        token: { verify: false, refresh: false },
      },
      login: {
        target: {
          params: ["mode=redirect", `appId=${env ? "DMS" : "demo"}`],
        },
      },
    },
  },
  features: [
    docPreviewNavigation,
    docsPageToRender,
    documentDetail,
    documentClassifications,
    folders,
    env ? gainsights : {},
  ],
  services,
}).catch(console.error);
