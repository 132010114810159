import { useEffect, useState, Dispatch, SetStateAction } from "react";
import { useClassificationInformation } from "../../store/useClassificationInformation";

type DynamicInput = Record<string, any>;

export const useGetDynamicInputStateFromCache = (
  defaultDynamicInput: DynamicInput,
): [DynamicInput, Dispatch<SetStateAction<DynamicInput>>] => {
  const { classificationDocumentId, isClassificationInfoEmpty } = useClassificationInformation();
  const savedDynamicInputRaw = localStorage.getItem("dynamicInput");
  let savedDynamicInput: DynamicInput = JSON.parse(
    savedDynamicInputRaw ?? "{}"
  );

  if (Object.keys(savedDynamicInput).length === 0 && defaultDynamicInput) {
    savedDynamicInput = defaultDynamicInput;
  }

  const [dynamicInput, setDynamicInput] =
    useState<DynamicInput>(savedDynamicInput);

  useEffect(() => {
    localStorage.setItem("dynamicInput", JSON.stringify(dynamicInput));
    if (classificationDocumentId) {
      localStorage.removeItem("dynamicInput");
    }
  }, [dynamicInput, classificationDocumentId]);

  useEffect(() => {
    if (isClassificationInfoEmpty) {
      localStorage.removeItem("dynamicInput");
    }
  }, [isClassificationInfoEmpty]);

  const handleDynamicInputChange = (value: DynamicInput) => {
    setDynamicInput(value);
  };

  return [dynamicInput, handleDynamicInputChange];
};

export const useGetSelectedLabelStateFromCache = (
  defaultSelectedLabel: string | null,
): [string | null, Dispatch<SetStateAction<string | null>>] => {
  const { classificationDocumentId, isClassificationInfoEmpty } = useClassificationInformation();
  const savedSelectedLabel =
    localStorage.getItem("selectedLabel") || defaultSelectedLabel;

  const [selectedLabel, setSelectedLabel] = useState<string | null>(
    savedSelectedLabel
  );

  useEffect(() => {
    localStorage.setItem("selectedLabel", selectedLabel || "");
    if (classificationDocumentId) {
      localStorage.removeItem("selectedLabel");
    }
  }, [selectedLabel, classificationDocumentId]);

  useEffect(() => {
    if (isClassificationInfoEmpty) {
      localStorage.removeItem("selectedLabel");
    }
  }, [isClassificationInfoEmpty]);

  return [selectedLabel, setSelectedLabel];
};
