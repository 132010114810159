import { faArrowLeft, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VaporToolbar } from "@vapor/react-custom";
import VaporPage from "@vapor/react-custom/VaporPage";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Drawer from "@vapor/react-material/Drawer";
import HeaderBar from "@vapor/react-material/HeaderBar";
import IconButton from "@vapor/react-material/IconButton";
import Stack from "@vapor/react-material/Stack";
import { format } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DocumentDatePicker from "../common-components/DocumentDatePicker";
import { LoadingWithoutText } from "../common-components/LoadingWithoutText";
import ChipsFilter from "../documents/document-components/ChipsFilter";
import { DateType } from "../documents/document-components/DateType";
import { Search } from "../documents/document-components/SearchBar";
import { useGetAllowedMacrocategories } from "../documents/hooks/api-hooks/useAllowedMacrocategories";
import { useGetDocumentsForClassifications } from "./api-hooks/useGetDocumentsForClassificaitions";
import { usePutLinkedDocuments } from "./api-hooks/usePostLinkedDocuments";
import DocumentLinkedList from "./DocumentLinkedList";
import { DocumentLinkTags } from "./DocumentLinkTags";

interface MenuItems {
  documentId: number;
  fileName: string;
}

export default function DocumentLinkDrawer({
  setFilteredDocuments,
  docLinkedDocuments,
  setReload,
}: any) {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation("DOCS");
  const [searchValue, setSearchValue] = React.useState("");
  const { workspaceId, id } = useParams();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [collapse, setCollapse] = React.useState(false);
  const [dateType, setDateType] = React.useState("UPLOAD");
  const [selectedDocuments, setSelectedDocuments] = React.useState<string[]>(
    [],
  );
  const [selectedChip, setSelectedChip] = React.useState(
    t("document.chips.Tutti"),
  );

  const {
    data: documents,
    loading: documentsLoading,
    error: documentsError,
  } = useGetDocumentsForClassifications({
    workspaceId,
    dateType: dateType,
    startDate: startDate ? format(startDate, "yyyy-MM-dd'T'hh:mm") : "",
    endDate: endDate ? format(endDate, "yyyy-MM-dd'T'hh:mm") : "",
    macrocategory:
      selectedChip === t("document.dateFilter.all") ? "" : selectedChip,
    searchTerm: searchValue,
  });
  const {
    data: linkedDocuments,
    loading: loadingLinkedDocs,
    fetch,
  } = usePutLinkedDocuments({
    id,
    filteredDocuments: selectedDocuments,
    workspaceId,
  });

  React.useEffect(() => {
    setSelectedChip(t("document.chips.Tutti"));
  }, []);

  const handleDrawer = () => {
    setOpen(!open);
  };
  const macroCategoriesData =
    useGetAllowedMacrocategories(workspaceId)?.data?.aggregationValue
      .macrocategory;

  const macroCategories = new Map(
    macroCategoriesData &&
      macroCategoriesData?.map((category: any) => [
        category.bucketName,
        category.docCount,
      ]),
  );
  const title = t("classification.selectADocument");

  const linkedDocumentIds = docLinkedDocuments?.map(
    (doc: any) => doc.documentId,
  );

  const menuItems = documents?.searchDocumentsResponse.filter(
    (item: any) => !linkedDocumentIds?.includes(item.documentId),
  );

  return (
    <>
      <Box sx={{ display: "flex", position: "relative", m: 4 }}>
        <Drawer anchor="right" open={open} onClose={handleDrawer}>
          <HeaderBar
            divider
            description="Page description text"
            title="Drawer Title"
            sx={{ width: "100%" }}
          />
          <Stack style={{ marginTop: "4%" }}>
            <VaporPage
              headerLeft={[
                <IconButton onClick={() => setOpen(false)} size="large">
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    color={"#0090d1"}
                    size={"2xs"}
                  ></FontAwesomeIcon>
                </IconButton>,
              ]}
              headerRight={""}
              title={title}
              sx={{
                "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
                  color: "#005075",
                  fontWeight: "normal",
                },
                "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
                  color: "#5a6872",
                },
              }}
            >
              <VaporPage.Section>
                <Stack direction="column" justifyContent="space-between">
                  <ChipsFilter
                    selectedChip={selectedChip}
                    setSelectedChip={setSelectedChip}
                    macroCategories={macroCategories}
                  ></ChipsFilter>
                </Stack>
                <Stack direction="row" spacing={4}>
                  <div style={{ marginTop: "20px" }}>
                    <Search
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                      width={400}
                    ></Search>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      float: "left",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setCollapse(!collapse);
                        selectedChip !== "Tutti" &&
                          collapse === true &&
                          setSelectedChip(t("document.chips.Tutti"));
                        collapse === true && setStartDate(null);
                        collapse === true && setEndDate(null);
                      }}
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faFilter}
                          color={"#0090d1"}
                          size={"lg"}
                        ></FontAwesomeIcon>
                      </div>
                      <Typography
                        marginLeft={1}
                        color={"#0090d1"}
                        fontFamily={"Roboto"}
                        fontSize={"16px"}
                        fontWeight={500}
                      >
                        {collapse
                          ? t("classification.restore")
                          : t("classification.Filter")}
                      </Typography>
                    </Button>
                  </div>{" "}
                </Stack>

                <Collapse orientation="vertical" in={collapse}>
                  <DocumentLinkTags
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    dateType={dateType}
                    setDateType={setDateType}
                    selectedDocuments={selectedDocuments}
                    setSelectedDocuments={setSelectedDocuments}
                    selectedChip={selectedChip}
                    setSelectedChip={setSelectedChip}
                  />

                  <Stack marginTop={3}>
                    <div style={{ display: "flex" }}>
                      <DocumentDatePicker
                        width={"50%"}
                        label1={t("document.MonthFilter.startDate")}
                        label2={t("document.MonthFilter.endDate")}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                      <div style={{ marginLeft: "4%", marginTop: "2%" }}>
                        <DateType
                          doNotShow={false}
                          dateType={dateType}
                          setDateType={setDateType}
                        ></DateType>
                      </div>
                    </div>
                  </Stack>
                  {/* here needs to be done:
                   the filters that are shown on the mockup and change the naming of component */}

                  {/* <Stack>
                    <BasicSelect />
                  </Stack> */}
                </Collapse>
              </VaporPage.Section>

              <Typography
                fontFamily={"Roboto"}
                fontSize={"14px"}
                fontWeight={"bold"}
                margin={3}
              >
                {t("classification.resultsOfYourSearch")}
              </Typography>
              {documentsLoading ? (
                <LoadingWithoutText />
              ) : (
                <DocumentLinkedList
                  setSelectedDocuments={setSelectedDocuments}
                  menuItems={menuItems}
                  workspaceId={workspaceId}
                />
              )}
            </VaporPage>
          </Stack>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              alignItems: "end",
              justifyContent: "end",
            }}
          >
            <VaporToolbar
              contentRight={[
                <Stack direction="row" sx={{ p: 1 }}>
                  <Button variant="outlined" onClick={() => setOpen(!open)}>
                    {t("classification.cancel")}
                  </Button>
                  <Button
                    disabled={selectedDocuments.length === 0}
                    onClick={async () => {
                      await fetch();
                      await setOpen(!open);
                      await setReload(true);
                      if ((window as any).aptrinsic) {
                        (window as any).aptrinsic(
                          "track",
                          "docs:LINK-DOCUMENT",
                        );
                      }
                    }}
                    variant="contained"
                    sx={{ ml: 1 }}
                  >
                    {t("classification.confirm")}
                    {loadingLinkedDocs ? (
                      <CircularProgress
                        size={"20px"}
                        style={{ marginLeft: 5 }}
                        color="inherit"
                      />
                    ) : (
                      ""
                    )}
                  </Button>
                </Stack>,
              ]}
              size="small"
              variant="regular"
            ></VaporToolbar>
          </Box>
        </Drawer>
      </Box>
      <Button
        onClick={handleDrawer}
        variant="text"
        style={{
          marginLeft: "20px",
          marginBottom: "10px",
          backgroundColor: "white",
          border: "1px solid #2097d3",
        }}
      >
        <FontAwesomeIcon
          size="sm"
          icon={faCirclePlus}
          style={{ marginRight: 10 }}
        />
        {t("classification.selectADocument")}
      </Button>
    </>
  );
}
