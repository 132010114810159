import { DocumentClassifications } from "./DocumentClassifications";

export const documentClassifications = ({ getConfig }) => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/classifications/:id/:workspaceId",
        component: DocumentClassifications
      }
    }
  ];
};
