import Alert from "@vapor/react-material/Alert";
import AlertTitle from "@vapor/react-material/AlertTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "@onefront/react-sdk";


export const InfoAlert = () => {
  const { t } = useTranslation("DOCS")
  return (

    <Alert
      style={{ width: "30%" }}
      variant="outlined"
      severity="info"
      icon={<FontAwesomeIcon icon={faInfoCircle} />}
    >
      <AlertTitle>{t("classification.alert")}</AlertTitle>
      {t("classification.description")}
    </Alert>
  )
}
