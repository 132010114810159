import FormControl from "@vapor/react-material/FormControl";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Checkbox from "@vapor/react-material/Checkbox";
import { useTranslation } from "@onefront/react-sdk";
import { Key } from "react";

const es = {
  "micro.": "Seguro",
  "micro.730": "Formulario 730",
  "micro.Accertamento": "Verificación",
  "micro.Altre dichiarazioni": "Otras declaraciones",
  "micro.Altro": "Otro",
  "micro.Antiriciclaggio": "Antilavado de dinero",
  "micro.Assicurazione": "Seguro",
  "micro.Atti privati": "Actos privados",
  "micro.Atti societari": "Actos corporativos",
  "micro.Autofattura": "AutoFactura",
  "micro.AutoFattura": "AutoFactura",
  "micro.Bilanci": "Balances",
  "micro.Cedolini": "Comprobantes de pago",
  "micro.Certificazioni": "Certificaciones",
  "micro.Contenzioso": "Contencioso",
  "micro.Contezioso": "Controversia",
  "micro.Contratti": "Contratos",
  "micro.Dichiarazioni fiscali": "Declaraciones fiscales",
  "micro.Documenti contabili": "Documentos contables",
  "micro.Documenti di trasporto": "Documentos de transporte",
  "micro.Documento di identità": "Documento de identidad",
  "micro.Dogane": "Aduanas",
  "micro.Fattura per estrazione beni": "Factura por extracción de bienes",
  "micro.Fatture di acconto": "Facturas de anticipo",
  "micro.Fatture emesse": "Facturas emitidas",
  "micro.Fatture per nome e per conto": "Facturas a nombre propio y por cuenta ajena",
  "micro.Fatture ricevute": "Facturas recibidas",
  "micro.Imposta di bollo": "Impuesto de timbre",
  "micro.Imposta sulle proprietà": "Impuesto sobre propiedades",
  "micro.Inbox": "Bandeja de entrada",
  "micro.IRAP": "IRAP",
  "micro.Istanza di interpello": "Solicitud de consulta",
  "micro.IVA": "Impuesto al Valor Agregado (IVA)",
  "micro.Libri contabili": "Libros contables",
  "micro.Libri e registri": "Libros y registros",
  "micro.Modelli di versamento": "Modelos de pago",
  "micro.Nota spese": "Nota de gastos",
  "micro.Note di credito": "Notas de crédito",
  "micro.Note di debito": "Notas de débito",
  "micro.Notifica": "Notificación",
  "micro.Parcelle acconto": "Parcela de anticipo",
  "micro.Parcelle": "Honorarios",
  "micro.Pratiche camerali": "Trámites cameral",
  "micro.Pratiche del lavoro": "Trámites laborales",
  "micro.Ratei e costi": "Gastos e intereses",
  "micro.Redditi": "Ingresos",
  "micro.Registri fiscali": "Registros fiscales",
  "micro.Ricevute": "Recibos",
  "micro.Riscossione": "Recaudación",
  "micro.Ritenute": "Retenciones",
  "micro.Spesometro": "Informe de gastos",
  "micro.Studi di settore": "Estudios sectoriales",
  "micro.Telematico": "Telemático",
  "micro.Variazione dati": "Cambio de datos",
  "micro.Verbali": "Actas",
  "micro.Visure": "Visuras",
}

const it = {
  "micro.730": "730",
  "micro.Accertamento": "Accertamento",
  "micro.Altre dichiarazioni": "Altre dichiarazioni",
  "micro.Altro": "Altro",
  "micro.Antiriciclaggio": "Antiriciclaggio",
  "micro.Assicurazione": "Assicurazione",
  "micro.Atti privati": "Ati privati",
  "micro.Atti societari": "Atti societari",
  "micro.Autofattura": "Autofattura",
  "micro.AutoFattura": "AutoFattura",
  "micro.Bilanci": "Bilanci",
  "micro.Cedolini": "Cedolini",
  "micro.Certificazioni": "Certificazioni",
  "micro.Contenzioso": "Contenzioso",
  "micro.Contezioso": "Contezioso",
  "micro.Contratti": "Contratti",
  "micro.Dichiarazioni fiscali": "Dichiarazioni fiscali",
  "micro.Documenti contabili": "Documenti contabili",
  "micro.Documenti di trasporto": "Documenti di trasporto",
  "micro.Documento di identità": "Documento di identità",
  "micro.Dogane": "Dogane",
  "micro.Fattura per estrazione beni": "Fattura per estrazione beni",
  "micro.Fatture di acconto": "Fatture di acconto",
  "micro.Fatture emesse": "Fatture emesse",
  "micro.Fatture per nome e per conto": "Fatture per nome e per conto",
  "micro.Fatture ricevute": "Fatture ricevute",
  "micro.Imposta di bollo": "Imposta di bollo",
  "micro.Imposta sulle proprietà": "Imposta sulle proprietà",
  "micro.Inbox": "Inbox",
  "micro.IRAP": "IRAP",
  "micro.Istanza di interpello": "Istanza di interpello",
  "micro.IVA": "IVA",
  "micro.Libri contabili": "Libri contabili",
  "micro.Libri e registri": "Libri e registri",
  "micro.Modelli di versamento": "Modelli di versamento",
  "micro.Nota spese": "Nota spese",
  "micro.Note di credito": "Note di credito",
  "micro.Note di debito": "Note di debito",
  "micro.Notifica": "Notifica",
  "micro.Parcelle acconto": "Parcelle acconto",
  "micro.Parcelle": "Parcelle",
  "micro.Pratiche camerali": "Pratiche camerali",
  "micro.Pratiche del lavoro": "Pratiche del lavoro",
  "micro.Ratei e costi": "Ratei e costi",
  "micro.Redditi": "Redditi",
  "micro.Registri fiscali": "Registri fiscali",
  "micro.Ricevute": "Ricevute",
  "micro.Riscossione": "Riscossione",
  "micro.Ritenute": "Ritenute",
  "micro.Spesometro": "Spesometro",
  "micro.Studi di settore": "Studi di settore",
  "micro.Telematico": "Telematico",
  "micro.Variazione dati": "Variazione dati",
  "micro.Verbali": "Verbali",
  "micro.Visure": "Visure",
}
  


export const CheckboxFilter = ({ microCategory, handleCheckboxChange }) => {
    const { i18n } = useTranslation("DOCS");
    return (
        <FormControl sx={{ marginTop: "10px" }}>
            <FormGroup row>
                {microCategory &&
                    microCategory.map((category: { name: string; checked: boolean; }, index: Key) => {
                        const key = "micro." + category.name;
                        const label = i18n.language === "es" ? es[key] || "" : it[key] || "";
                        return (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={category.checked}
                                        onChange={() => handleCheckboxChange(category)}
                                    />
                                }
                                label={label}
                                labelPlacement="end"
                            />
                        );
                    })}
            </FormGroup>
        </FormControl>
    );
};