import { faTag, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { useNavigate } from "react-router-dom";

export const DocumentDetailsFooter = ({
  id,
  isInvoice,
  workspaceId,
  setStatusOnDelete,
  itemClassification,
  onOpenModal,
  readOnly,
}) => {
  const { t } = useTranslation("DOCS");
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      // padding={4}
      alignItems="flex-start"
      // paddingLeft="50px"
    >
      {(isInvoice === false && readOnly) ||
      (isInvoice === true && readOnly === false) ||
      (isInvoice === true && readOnly === true) ? (
        ""
      ) : (
        <Button
          onClick={() => {
            onOpenModal();
          }}
        >
          <div style={{ marginRight: "5px", padding: 5 }}>
            <FontAwesomeIcon size="lg" color="red" icon={faTrashCan} />
          </div>
          <div>
            <Typography
              fontSize={"16px"}
              fontWeight={500}
              fontFamily={"Roboto"}
              color="red"
            >
              {t("classification.delete")}
            </Typography>
          </div>
        </Button>
      )}

      {(isInvoice === false && readOnly) ||
      (isInvoice === true && readOnly === false) ||
      (isInvoice === true && readOnly === true) ? (
        ""
      ) : (
        <Button
          onClick={() => {
            navigate(`/classifications/${id}/${workspaceId}`);
          }}
        >
          <div style={{ marginLeft: 20, marginRight: "5px", padding: 5 }}>
            <FontAwesomeIcon size="lg" color="#0090d1" icon={faTag} />
          </div>
          <div>
            <Typography
              fontSize={"16px"}
              fontWeight={500}
              fontFamily={"Roboto"}
              color="#0090d1"
            >
              {t("classification.changeClassification")}
            </Typography>
          </div>
        </Button>
      )}
    </Stack>
  );
};
