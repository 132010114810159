import {
  useState,
  useMemo,
  useCallback,
  useEffect,
  SetStateAction,
} from "react";
import { faCircleExclamation, faFile } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons/faCommentAlt";
import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faInbox } from "@fortawesome/pro-regular-svg-icons/faInbox";
import { faKey } from "@fortawesome/pro-regular-svg-icons/faKey";
import { faMinusOctagon } from "@fortawesome/pro-regular-svg-icons/faMinusOctagon";
import { faFileCheck } from "@fortawesome/pro-solid-svg-icons/faFileCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import CardContent from "@vapor/react-material/CardContent";
import Stack from "@vapor/react-material/Stack/";
import Popover from "@vapor/react-material/Popover";
import { Link, useNavigate } from "react-router-dom";
import {
  macroCategoryCostants,
  macroCategoryConstant,
} from "../../common-components/CostantStrings";
import {
  StyledCard,
  StyledCardAction,
  StyledDivParagrapht,
} from "../document-styled-components/CardBoxStyles";
import {
  StyledCommentCount,
  StyledFlexWrapper,
} from "../document-styled-components/CardListStyles";
import { useDownloadDocument } from "../hooks/api-hooks/useDownloadADocument";
import { useTranslation } from "@onefront/react-sdk";
import useFormatDocumentName from "../../common-utils/useFormatDocumentName";
import { DateFormater } from "../document-utils/DateFormater";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { it, enGB, es } from "date-fns/locale";
import Tooltip from "@vapor/react-material/Tooltip";
import { CardBoxProps } from "../types";
import { SubtitleAndTitleFormatter } from "../../documents/document-utils/subTitleFormater";

const CardBox = ({ doc, workspaceId }: CardBoxProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState<string>();
  const open = Boolean(anchorEl);

  const { t, i18n } = useTranslation("DOCS");
  const locale =
    i18n.language === "it" ? it : i18n.language === "en" ? enGB : es;

  const checkIfEncrypted = doc.encrypted;
  const checkVisibility = doc.visibleByAccountantOnl;

  const { data: download, fetch } = useDownloadDocument(
    doc.documentId,
    workspaceId,
    doc.fileName,
    documentType,
    doc?.invoice
  );

  const documentSearchPercentageScore =
    doc?.documentSearchScorePercentage === "NaN"
      ? 0
      : doc?.documentSearchScorePercentage;

  const documentSearchPercentage =
    documentSearchPercentageScore !== 0
      ? "Corrispondenza  " + doc?.documentSearchScorePercentage + "%"
      : "";

  const navigate = useNavigate();
  const infoList = doc?.infoList || [];
  const filteredInfoList = infoList
    .filter((x: any) => x.name !== "Filename" && x.name !== "DATE")
    .filter((x: any) => x.value !== "");
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFatture = useMemo(() => doc?.einvoice === true, [doc]);

  const handleClick = (event: { currentTarget: any }) => {
    isFatture ? setAnchorEl(event.currentTarget) : setDocumentType("ASSOSW");
  };

  useEffect(() => {
    if (documentType === "ASSOSW" || documentType === " ") {
      fetch();
    }
  }, [documentType]);

  const macro = doc?.standardTags.filter(
    (val: { name: string }) => val.name === "macrocategory"
  )[0].value;

  const macroCategoryConstants = new Map([
    [macroCategoryCostants.inbox.label, macroCategoryCostants.inbox.icon],
    [macroCategoryCostants.fatture.label, macroCategoryCostants.fatture.icon],
    [macroCategoryCostants.altri.label, macroCategoryCostants.altri.icon],
    [macroCategoryCostants.azienda.label, macroCategoryCostants.azienda.icon],
    [macroCategoryCostants.fiscale.label, macroCategoryCostants.fiscale.icon],
    [
      macroCategoryCostants.personale.label,
      macroCategoryCostants.personale.icon,
    ],
    [macroCategoryCostants.tutti.label, macroCategoryCostants.tutti.icon],
  ]);

  const icon =
    macroCategoryConstants.get(macro) || macroCategoryCostants.inbox.icon;

  return (
    <>
      <StyledCard key={doc.documentId}>
        <Link
          to={`/document-details/${doc.documentId}/${workspaceId}`}
          style={{ textDecoration: "none", color: "black " }}
        >
          <CardContent style={{ height: "82%" }}>
            <div>
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="caption"
                  marginBottom={1}
                  color={documentSearchPercentageScore > 80 ? "green" : "grey"}
                >
                  {documentSearchPercentage}
                </Typography>
                <Stack direction="row" gap={1}>
                  {doc.urgent && (
                    <FontAwesomeIcon
                      icon={faCircleExclamation}
                      style={{ marginLeft: "10px" }}
                      color="#D92B2C"
                      size="sm"
                    ></FontAwesomeIcon>
                  )}
                  <FontAwesomeIcon
                    style={{ float: "right" }}
                    icon={doc.read ? faFileCheck : faFile}
                    color={
                      doc.userType === "ACCOUNTANT" ? "#152935" : "#0177ac"
                    }
                    size="sm"
                  ></FontAwesomeIcon>
                </Stack>
              </Stack>
              <Stack
                style={{ display: "inline" }}
                direction={"row"}
                justifyContent="space-between"
              >
                <FontAwesomeIcon
                  style={{
                    float: "left",
                    marginRight: "15px",
                    color: "#005075",
                    marginTop: "10px",
                  }}
                  size="lg"
                  icon={icon}
                />
                <Stack direction="row" justifyContent="space-between">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontFamily={"Cairo"}
                      fontWeight={600}
                      color="#005075"
                      fontSize={"20px"}
                      style={{
                        maxWidth: "300px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                      }}
                    >
                      {SubtitleAndTitleFormatter(doc?.category)}
                    </Typography>
                  </div>
                  <Stack
                    direction="row"
                    alignItems="top"
                    paddingTop={1}
                    gap={1}
                  >
                    {checkVisibility && (
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={faKey}
                      ></FontAwesomeIcon>
                    )}
                    {checkIfEncrypted && (
                      <FontAwesomeIcon
                        size="sm"
                        color="red"
                        icon={faMinusOctagon}
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </div>
            <Typography
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "13%",
                maxWidth: "300px",
                padding: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordWrap: "break-word",
              }}
              fontFamily={"Cairo"}
              color="#005075"
              fontSize={"14px"}
            >
              {SubtitleAndTitleFormatter(doc?.subTitle)}
            </Typography>

            <StyledDivParagrapht>
              {filteredInfoList.map((info: any) => (
                <Stack direction="row">
                  <Typography
                    style={{ marginRight: "5px" }}
                    fontFamily={"Roboto"}
                    fontSize={"13px"}
                  >
                    {t("document." + info.name)}:
                  </Typography>
                  <Typography fontSize={"14px"} fontWeight={"bold"}>
                    {info.name === "Creation date" ||
                    info.name === "Document date"
                      ? DateFormater(info.value, locale)
                      : info.value}
                  </Typography>
                </Stack>
              ))}
            </StyledDivParagrapht>
          </CardContent>
        </Link>
        <StyledCardAction>
          <Button
            onClick={() =>
              navigate(`document-details/${doc.documentId}/${workspaceId}`)
            }
            style={{
              padding: 0,
              minHeight: 0,
              minWidth: 0,
              marginRight: "15px",
            }}
          >
            <StyledCommentCount>
              {doc.notesCount > 100 ? "99+" : doc.notesCount}
            </StyledCommentCount>
            <FontAwesomeIcon color="#0090d1" size="lg" icon={faCommentAlt} />
          </Button>
          <Button
            size="medium"
            style={{
              padding: 0,
              minHeight: 0,
              minWidth: 0,
              marginRight: "20px",
            }}
          >
            <FontAwesomeIcon
              color="#0090d1"
              size="lg"
              icon={faEye}
              onClick={() =>
                navigate(`document-details/${doc.documentId}/${workspaceId}`)
              }
            />
          </Button>
          <Tooltip arrow placement="top" title={t("document.download")}>
            <Button
              size="medium"
              style={{
                padding: 0,
                minHeight: 0,
                minWidth: 0,
                marginRight: "20px",
              }}
            >
              <FontAwesomeIcon
                size="lg"
                key={doc.documentId}
                onClick={handleClick}
                icon={faDownload}
              />
            </Button>
          </Tooltip>
          {isFatture && (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                margin={2}
                onClick={() => {
                  setDocumentType("ASSOSW");
                }}
              >
                {t("document.downloadPdf")}
              </Typography>
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                margin={2}
                onClick={() => {
                  setDocumentType(" ");
                }}
              >
                {t("document.downloadXml")}
              </Typography>
            </Popover>
          )}
        </StyledCardAction>
      </StyledCard>
    </>
  );
};

export default CardBox;
