import styled from "styled-components";
import Card from "@vapor/react-material/Card";
import CardActions from "@vapor/react-material/CardActions";

export const StyledDivParagrapht = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const StyledCard = styled(Card)`
  margin-top: 10px;
  margin-left: 10px;
  width: 292px;
`;
export const StyledCardAction = styled(CardActions)`
  background: #f2f5f8;
  display: flex;
  justify-content: flex-end;
  height: 20%;
`;
export const StyledFloatingDivFile = styled.div`
  float: right;
  margin-right: 5%;
`;
