import { parseISO, format } from "date-fns"

export const DateFormater = (date: string, locale: Locale): string => {
    try {
        const parsedDate = parseISO(date);
        return format(parsedDate, 'd MMMM y', { locale: locale });
    } catch (error) {
        return date;
    }
};

export function formatNumberOfGB(value: number): string {
    if (Math.abs(value) < 0.0001) {
      return "0";
    }
  
    if (Number.isInteger(value)) {
      const formattedValue = value.toFixed(2);
      return formattedValue.replace(/\./g, ",");
    }
  
    return value.toFixed(2).replace(/\./g, ",");
  }