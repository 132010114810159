import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useClientInformationStore: any = create(
  devtools((set, get) => {
    return {
      tenantId: null,
      counterpartId: null,
      workspaceId: null,
      selectedWorkspace: null,
      selectedTenant: null,
      oneBoxCompanyName: null,
      setWorkspace: (workspace: any) => set({ selectedWorkspace: workspace }),
      setTenant: (tenant: any) => set({ selectedTenant: tenant }),
      setWorkspaceId: (workspaceId: string) =>
        set({ workspaceId: workspaceId }),
      setCounterpartId: (counterpartId: string) =>
        set({ counterpartId: counterpartId }),
      setTenantId: (tenantId: string) => set({ tenantId: tenantId }),
      setOneBoxCompanyName: (oneBoxCompanyName: string) =>
        set({ oneBoxCompanyName: oneBoxCompanyName }),
    };
  }, {}),
);
