import React, { useState } from "react";
import {
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  Divider,
} from "@vapor/react-material";
import { DocPreviewer } from "@onefront/documents-smart-components";
import { useClientInformationStore } from "../../../store/useClientInformationStore";
import Close from "@mui/icons-material/Close";

const DocumentPreviewModal = ({ open, setOpen, id }: any) => {
  const handleClose = () => {
    setOpen(false);
  };

  const { workspaceId } = useClientInformationStore();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Document Previewer
        <IconButton color="primary" onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent>
        <DocPreviewer
          id={id}
          isInvoice={false}
          height="100vh"
          workspaceId={workspaceId}
        />
      </DialogContent>
    </Dialog>
  );
};
export default DocumentPreviewModal;
