import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@vapor/react-material";

export const LinkedDocumentModalInformation = ({
  handleDialog,
  openModalInfo,
  docInfo,
}) => {
  const { t } = useTranslation("DOCS");
  return (
    <>
      {docInfo && (
        <Dialog
          open={openModalInfo}
          onClose={handleDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{t("classification.dialogTitle")}</DialogTitle>
          <Divider variant="fullWidth" />
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {docInfo.updatedOn && (
                <Stack direction="row" gap={1}>
                  <Typography variant="body">
                    {t("classification.creationDate")}
                  </Typography>
                  <Typography variant="body">{docInfo.updatedOn}</Typography>
                </Stack>
              )}
              {docInfo.filename && (
                <Stack direction="row" gap={1}>
                  <Typography variant="body" noWrap>
                    {t("classification.fileName")}:
                  </Typography>
                  <Typography variant="body" style={{ whiteSpace: "nowrap" }}>
                    {docInfo.filename}
                  </Typography>
                </Stack>
              )}
              {docInfo.macrocategory && docInfo.microcategory && (
                <Stack direction="row" gap={1}>
                  <Typography variant="body">
                    {t("classification.category")}:
                  </Typography>
                  <Typography variant="body">
                    {docInfo.macrocategory}/{docInfo.microcategory}
                  </Typography>
                </Stack>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialog} variant="outlined">
              {t("classification.closeDialog")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
