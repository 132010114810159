import * as React from "react";
import Alert from "@vapor/react-material/Alert";
import AlertTitle from "@vapor/react-material/AlertTitle";
import Stack from "@vapor/react-material/Stack";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslation} from "@onefront/react-sdk";

export default function SuccessAlertDocumentDelete() {
  const { t } = useTranslation("DOCS");
    return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert
        variant="outlined"
        severity="success"
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
      >
        <AlertTitle>{t("upload.success")}</AlertTitle>
          {t("document.successfulLinkDeletion")}
      </Alert>
    </Stack>
  );
}
