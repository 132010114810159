import { useAuth, usePut } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function usePutClassifications({id, workspaceId, kvags, documentType}) {
  const authData = useAuth();
  const { data, loading, hasLoaded, fetch, error } = usePut(
    `documentsApi://api/v1/documents/classification?workspaceId=${workspaceId}`,
    {
      documentId: id,
      documentTypeName: documentType,
      kvags: kvags
    },
    {
      headers: buildHeaders({ authData })
    }
  );
  useEffect(() => {
    if (data && kvags) {
      fetch();
    }
  }, [id, kvags]);

  return { data, loading, hasLoaded, fetch, error };
}
