import FormControl from "@vapor/react-material/FormControl";
import RadioGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Radio from "@vapor/react-material/Radio";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";

const changeDateType = (type, setType) => {
  if (type === "DOCUMENT") {
    setType("UPLOAD");
  } else {
    setType("DOCUMENT");
  }
};

export const DateType = ({ dateType, setDateType, doNotShow }) => {
  const { t } = useTranslation("DOCS");
  return (
    <FormControl>
      <div style={{ marginLeft: "2px", marginBottom: "4px" }}>
        {doNotShow && (
          <Typography
            fontSize={14}
            fontFamily={"Roboto"}
            fontWeight={600}
            height={20}
          >
            {t("document.time")}
          </Typography>
        )}
      </div>
      <RadioGroup row>
        <FormControlLabel
          value="right"
          control={
            <Radio
              checked={dateType === "DOCUMENT"}
              onClick={(e) => changeDateType(dateType, setDateType)}
            />
          }
          label={t("document.document")}
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Radio
              checked={dateType === "UPLOAD"}
              onClick={(e) => changeDateType(dateType, setDateType)}
            />
          }
          label={t("document.upload")}
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};
