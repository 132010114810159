import { faPen } from "@fortawesome/pro-solid-svg-icons/faPen";
import { faXmarkLarge } from "@fortawesome/pro-solid-svg-icons/faXmarkLarge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Tooltip,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";

import * as React from "react";
import { usePatchToRenameFile } from "../hooks/usePatchToRenameFile";

export default function RenameDocumentFileModal({
  workspaceId,
  id,
  setReload,
  data: docData,
  setNewName,
  newName,
}) {
  const { t } = useTranslation("DOCS");
  const [open, setOpen] = React.useState(false);
  const [newFileName, setNewFileName] = React.useState(
    newName ? newName : docData?.fileName
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { data, loading, error, fetch } = usePatchToRenameFile({
    id,
    newFileName,
    workspaceId,
  });
  if (data) {
    setNewName(newFileName);
  }

  return (
    <div>
      <Tooltip arrow title={t("classification.rename")} placement="top">
        <IconButton onClick={handleClickOpen} color="primary" size="small">
          <FontAwesomeIcon icon={faPen} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ display: "flex" }} id="alert-dialog-title">
          <Typography>{t("classification.rename")}</Typography>
          <IconButton size="medium" color="primary">
            <FontAwesomeIcon
              icon={faXmarkLarge}
              onClick={handleClose}
            />
          </IconButton>
        </DialogTitle>
        <Divider variant="fullWidth" />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                width: 500,
                maxWidth: "100%",
              }}
            >
              <TextField
                defaultValue={docData?.fileName}
                onChange={(e) => setNewFileName(e.target.value)}
                fullWidth
                id="fullWidth"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t("classification.cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={async () => {
              await setOpen(false);
              await fetch();
            }}
            autoFocus
          >
            {t("chat.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
