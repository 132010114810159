import {Grid,Box} from "@vapor/react-material";
import {
  DocumentNotes,
  DocPreviewer,
  AiAsistant,
} from "@onefront/documents-smart-components";
import * as React from "react";
import { useParams } from "react-router-dom";
import DocumentDetails from "./DocumentDetailsProps";
import { useClientInformationStore } from "../../store/useClientInformationStore";

const DetailContainer = ({
  loading,
  setReload,
  data,
  expanded,
  expandedComment,
  workspaceId,
  setexpandedComment,
  fetchDetails,
  workspaceData,
  setPreviewType,
  loadingPreview,
  setNewName,
  NewName,
  isAccountant,
  setCheckVisibility,
  commentCount,
  setCommentCount,
  setExpandedAssistant,
  expandedAssistant,
}) => {
  const { id } = useParams();
  const itemClassification = workspaceData?.data?.itemType;
  const { selectedTenant: tenant , selectedWorkspace: ws} = useClientInformationStore();

  return (
    <>
      <Box sx={{ flexGrow: 1, height: "200%" }}>
        <Grid style={{ height: "100%" }} container spacing={1}>
          <Grid item xs={4} display={expanded ? "none" : ""}>
            <DocumentDetails
              setCheckVisibility={setCheckVisibility}
              isAccountant={isAccountant}
              NewName={NewName}
              setNewName={setNewName}
              commentCount={commentCount}
              setReload={setReload}
              fetchDetails={fetchDetails}
              loading={loading}
              setexpandedComment={setexpandedComment}
              workspaceId={workspaceId}
              expandedComment={expandedComment}
              data={data}
              expanded={expanded}
            />
          </Grid>

          <Grid item xs={expandedAssistant || !expandedComment ? 4 : 8}>
            <DocPreviewer
              height={"100vh"}
              fileName={data?.fileName}
              isInvoice={data?.einvoice}
              id={data?.einvoice ? id.split("_")[0] : id}
              workspaceId={workspaceId}
            />
          </Grid>
          <Grid item xs={4} display={expandedComment ? "none" : ""}>
            <DocumentNotes
              tenantItemId={tenant?.itemId}
              setCommentCount={setCommentCount}
              documentId={id}
              workspaceId={workspaceId}
              itemClassification={itemClassification}
            />
          </Grid>
          {expandedAssistant && (
            <Grid item xs={4}>
              <AiAsistant documentId={id} withSideDrawer={false} workspaceId={workspaceId} userLanguage={ws.userLanguage} />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default DetailContainer;
