import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faCircleX } from "@fortawesome/pro-solid-svg-icons/faCircleX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@vapor/react-material";
import moment from "moment";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@onefront/react-sdk";
import { config } from "../../../config";
import {
  faArrowCircleRight,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@vapor/react-extended/ExtendedTypography";
interface Deadline {
  status: string;
  amount: string;
  date: string;
  id: string;
}
interface DocumentScannerDetailsInterface {
  data: Deadline[];
}

const deadlineStatuses = {
  authorized: {
    icon: faArrowCircleRight,
    label: "document.authorized",
    color: "#fdb927",
  },
  rejected: {
    icon: faCircleX,
    label: "document.rejected",
    color: "red",
  },
  paid: {
    icon: faCircleCheck,
    label: "document.paid",
    color: "green",
  },
  collected: {
    icon: faCircleCheck,
    label: "document.collected",
    color: "green",
  },
  to_be_paid: {
    icon: faArrowCircleRight,
    label: "document.toBePaid",
    color: "#fdb927",
  },
  to_be_collected: {
    icon: faArrowCircleRight,
    label: "document.toBeCollected",
    color: "#fdb927",
  },
  unpaid: {
    icon: faCircleX,
    label: "document.unpaid",
    color: "red",
  },
  suspended: {
    icon: faArrowCircleRight,
    label: "document.suspended",
    color: "#fdb927",
  },
  pending: {
    icon: faArrowCircleRight,
    label: "document.pending",
    color: "#fdb927",
  },
  rebated: {
    icon: faCircleCheck,
    label: "document.rebated",
    color: "green",
  },
};

export const DocumentScannerDetails: React.FC<
  DocumentScannerDetailsInterface
> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("DOCS");
  var referrer = document.referrer;

  const renderIcon = (rowStatus: keyof typeof deadlineStatuses) => {
    const correctStatus = deadlineStatuses[rowStatus];

    return (
      <>
        {correctStatus && (
          <div>
            <FontAwesomeIcon
              color={correctStatus.color}
              size="lg"
              icon={correctStatus.icon}
              style={{ marginRight: "10px" }}
            />
            {t(correctStatus.label)}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: "100%" }} aria-label="caption table">
          <TableBody>
            {data.map((row: Deadline) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography variant="body">
                    {moment(row.date).format("DD/MM/YYYY")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body">{row.amount}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body">
                    {renderIcon(row.status as keyof typeof deadlineStatuses)}
                  </Typography>
                </TableCell>
                <TableCell key={row.id}>
                  <IconButton
                    onClick={() =>
                      (window.parent.location.href = `${config.digitalBoxBaseUrl}scadenza/${row.id}`)
                    }
                  >
                    <FontAwesomeIcon
                      color="#0090d1"
                      size="lg"
                      icon={faArrowRight}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
