import { Stack, Divider, Menu, MenuItem } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faEye } from "@fortawesome/pro-regular-svg-icons";
import React, { useState } from "react";
import { Typography } from "@vapor/react-extended";
import { useGetFolderDocuments } from "../hooks/useGetFolderDocuments";
import { faLoveseat } from "@fortawesome/pro-light-svg-icons";

const Loading = () => (
  <Typography
    sx={{
      marginTop: "20px",
      marginBottom: "20px",
      color: "#1F292E",
      fontFamily: "Roboto",
      letterSpacing: "0.12px",
      lineHeight: "24px",
      textAlign: "center",
    }}
  >
    Loading
  </Typography>
);

const FolderCardHover = ({
  anchorEl,
  setAnchorEl,
  openMenu,
  setOpenMenu,
  setOpenPreviewModal,
  id,
  setSelectedDocumentId
}: any) => {
  const { data, error, fetch, hasLoaded, loading } = useGetFolderDocuments(id);

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
    setOpenPreviewModal(false);
  };

  return (
    <Menu
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleClose}
      PaperProps={{
        style: {
          minHeight: "100px",
          minWidth: "200px",
          overflowY: "auto",
        },
      }}
      MenuListProps={{
        "aria-labelledby": "folder-card",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        hasLoaded &&
        data?.documents.map((item: any) => (
          <MenuItem
            key={item.id}
            sx={{
              padding: "10px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Roboto",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              <FontAwesomeIcon
                icon={faFile}
                style={{
                  height: "14px",
                  paddingRight: "4px",
                  color: "black",
                  width: "16px",
                }}
              />
              {item.filename}
            </Typography>
            <FontAwesomeIcon
              onClick={() => {
                setSelectedDocumentId(item?.id);
                setOpenPreviewModal(true);
              }}
              icon={faEye}
              style={{
                height: "14px",
                cursor: "pointer",
                color: "#008FD6",
                width: "16px",
              }}
            />
          </MenuItem>
        ))
      )}
      {hasLoaded && data?.documents?.length === 0 && (
        <Typography
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            color: "#1F292E",
            padding: "4px",
            fontFamily: "Roboto",
            letterSpacing: "0.12px",
            fontSize: "14px",
            textAlign: "center",
          }}
        >
          There are no documents for this folder
        </Typography>
      )}
    </Menu>
  );
};

export default FolderCardHover;
