import CircularProgress from "@vapor/react-material/CircularProgress";
import Stack from "@vapor/react-material/Stack";

export const LoadingWithoutText = ({}) => {
  return (
    <Stack direction="column" alignItems="center">
      <CircularProgress></CircularProgress>
    </Stack>
  );
};
