import CreateFolderPage from "../pages/CreateFolderPage";
import FoldersPage from "../pages/FoldersPage";

export const folders = () => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/all-folders",
        component: FoldersPage,
      },
    },
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: `/folder/:id?`,
        component: CreateFolderPage,
      },
    },
  ];
};
