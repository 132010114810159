import { useAuth, usePut } from "@onefront/react-sdk";
import { buildHeaders } from "./buildHeaders";
import React from "react";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function usePutUpdateFolder({
  id,
  referenceIds,
  classification,
  referencePeriod,
}: any) {
  const authData = useAuth();
  const { workspaceId } = useClientInformationStore();

  const payload = {
    workspaceId,
    id,
    classification,
    referencePeriod,
    referenceIds,
  };

  const { data, loading, hasLoaded, fetch, error } = usePut(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders`,
    payload,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  return { data, loading, hasLoaded, fetch, error };
}
