import { faAddressCard } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@vapor/react-extended";
import { useClientInformationStore } from "../store/useClientInformationStore";
import { useTranslation } from "@onefront/react-sdk";

const NoAccessErrorPage = () => {
  const { oneBoxCompanyName } = useClientInformationStore();
  const { t } = useTranslation("DOCS");

  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <FontAwesomeIcon
        style={{
          width: "150px",
          height: "120px",
          color: "#9EAAB3",
        }}
        icon={faAddressCard}
      />
      <Typography
        color="#004666"
        sx={{
          fontFamily: "Cairo",
          fontSize: "20px",
          fontStyle: "normal",
          fontHeight: "600",
          lineHeight: "28px",
        }}
      >
        {oneBoxCompanyName}
      </Typography>
      <Typography
        sx={{
          color: "#566B76",
          fontFamily: "Cairo",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
        }}
      >
        {t("document.noActiveService")}
      </Typography>
    </div>
  );
};
export default NoAccessErrorPage;
