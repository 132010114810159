import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Button from "@vapor/react-material/Button";
import IconButton from "@vapor/react-material/IconButton";
import Stack from "@vapor/react-material/Stack";
import { useEffect, useState } from "react";
import { useTranslation } from "@onefront/react-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { usePutClassifications } from "./api-hooks/usePutClassifications";
import { usePutTags } from "./api-hooks/usePutTags";
import ClassificationStepper from "./ClassificationStepper";
import ErrorAlert from "../common-components/ErrorAlert";
import { useClassificationInformation } from "../store/useClassificationInformation";

export const DocumentClassifications = () => {
  const [stepStatus, setStepStatus] = useState(0);
  const [kvags, setKvags] = useState(null);
  const [tagsToSend, setTagsToSend] = useState(null);
  const [documentType, setDocumentType] = useState();
  const [checkIfFieldsAreFilled, setCheckIfFieldsAreFilled] = useState(false);
  const { id, workspaceId } = useParams();
  const { t } = useTranslation("DOCS");
  const navigate = useNavigate();
  const { setDocumentIdWhenClassify, setIsClassificationInfoEmpty } =
    useClassificationInformation();
  const { data, loading, error, fetch } = usePutClassifications({
    id,
    workspaceId,
    kvags,
    documentType,
  });
  const {
    data: dataTags,
    loading: loadingTags,
    error: errorTags,
    fetch: fetchTags,
  } = usePutTags(id, tagsToSend);

  useEffect(() => {
    setDocumentIdWhenClassify(data?.documentId);
  }, [data]);

  if (data) {
    navigate(`/document-details/${id}/${workspaceId}`);
  }

  const goBack = () => {
    window.history.go(-1);
  };
  return (
    <>
      <VaporPage
        headerLeft={[
          <IconButton onClick={goBack} size="large">
            <FontAwesomeIcon
              icon={faArrowLeft}
              color={"#0090d1"}
              size={"2xs"}
            ></FontAwesomeIcon>
          </IconButton>,
        ]}
        title={t("classification.title")}
        sx={{
          "&.MuiCard-root .MuiBox-root .MuiTypography-titleSmall": {
            color: "#005075",
            fontWeight: "normal",
          },
          "&.MuiCard-root .MuiBox-root .MuiTypography-pageDescription": {
            color: "#5a6872",
          },
        }}
      >
        <VaporPage.Section>
          <ClassificationStepper
            setCheckIfFieldsAreFilled={setCheckIfFieldsAreFilled}
            setDocumentType={setDocumentType}
            setTagsToSend={setTagsToSend}
            error={error}
            setKvags={setKvags}
            stepStatus={stepStatus}
            setStepStatus={setStepStatus}
          />
        </VaporPage.Section>
        {/* {data.documentId && <MuiSucessAlert />} */}
      </VaporPage>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        style={{
          backgroundColor: "#f2f5f8",
        }}
      >
        <VaporToolbar
          contentRight={[
            <Button
              variant="outlined"
              onClick={() => {
                setIsClassificationInfoEmpty(true);
                navigate("/");
              }}
            >
              {t("classification.cancel")}
            </Button>,
            <Button
              disabled={checkIfFieldsAreFilled}
              onClick={() => {
                if (stepStatus < 2) {
                  setStepStatus(stepStatus + 1);
                } else {
                  fetch();
                  fetchTags();
                }
              }}
              variant="contained"
            >
              {stepStatus < 2
                ? t("classification.next")
                : t("classification.submit")}
            </Button>,
          ]}
          size="large"
          variants="regular"
        ></VaporToolbar>
      </Stack>
    </>
  );
};
