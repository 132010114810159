import Switch from "@vapor/react-material/Switch";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import Stack from "@vapor/react-material/Stack";
import { useTranslation } from "@onefront/react-sdk";
export const DocumentsReadByFilter = ({
  readByAccountant,
  readByCompany,
  setReadByCompany,
  setReadByAccountant, urgentDocs,
  setUrgentDocs
}) => {
  const { t } = useTranslation("DOCS")
  return (
    <FormGroup>
      <Stack direction="row" alignItems="center">
        <FontAwesomeIcon transform="grow-5" size="xs" icon={faCircleExclamation} color="#D92B2C"></FontAwesomeIcon>
        <FormControlLabel
          control={<Switch checked={urgentDocs} />}
          label={t("document.urgenti")}
          labelPlacement="start"
          onChange={(e) => setUrgentDocs(!urgentDocs)}
          />
      </Stack>
      <Stack direction="row" alignItems="center" >
        <FontAwesomeIcon icon={faFile} color="#0077AD"></FontAwesomeIcon>
        <FormControlLabel
          control={<Switch checked={readByAccountant} />}
          label={t("document.readByAccountant")}
          labelPlacement="start"
          onChange={(e) => setReadByAccountant(!readByAccountant)}
        />
      </Stack>
      <Stack direction="row" alignItems="center">
        <FontAwesomeIcon icon={faFile} color="#152935"></FontAwesomeIcon>
        <FormControlLabel
          control={<Switch checked={readByCompany} />}
          label={t("document.readByCompany")}
          labelPlacement="start"
          onChange={(e) => setReadByCompany(!readByCompany)}
        />
      </Stack>
    </FormGroup>
  );
};
