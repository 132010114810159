import { useAuth, useDelete } from "@onefront/react-sdk";
import { buildHeaders } from "./buildHeaders";
import React from "react";

export function useDeleteFolder(id: string) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useDelete(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders/${id}`,
    {},
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  return { data, loading, hasLoaded, fetch, error };
}
