import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import TextField from "@vapor/react-material/TextField";
import { useState } from "react";

export const Search = ({ setSearchValue, searchValue, width }) => {
  const [value, setValue] = useState("");
  const { t } = useTranslation("DOCS");
  return (
    <Stack direction="row" alignItems="end">
      <TextField
        placeholder={t("document.search.default")}
        variant="outlined"
        sx={{
          width: width,
        }}
        onChange={(e) => {
          setValue(e.target.value);
          if (e.target.value === "") {
            setValue(e.target.value);
            setSearchValue(e.target.value);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setValue(e.target.value);
            setSearchValue(e.target.value);
          }
        }}
        label={t("document.search.title")}
      />
      <Button
        onClick={(e) => {
          setSearchValue(value);
        }}
        size="medium"
        sx={{
          "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
            borderRadius: 0,
            minWidth: 40,
            p: 1,
          },
        }}
        variant="contained"
      >
        <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
      </Button>
    </Stack>
  );
};
