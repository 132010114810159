import { ExtendedTypography } from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails
} from "../document-styled-components/StyledAccordion";
import { useState } from "react";

import { useTranslation } from "@onefront/react-sdk";

export const AccordionFilter = ({ categories, setSelectedCategories }) => {
  const [expanded, setExpanded] = useState("");
  const { t } = useTranslation("DOCS")
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const categoryTitles = categories
    ? Object.entries(categories)
      .filter((category) => category[0] !== "macrocategory")
      .filter((category) => category[0] !== "microcategory")
      .filter((category) => category[0] !== "hubId")
      .filter((category) => category[1].length > 0)
    : [];

  const handleSelectCategory = (title, detail) => {
    setSelectedCategories((prevCategories) => {
      const previous = prevCategories[title];
      if (previous === detail.bucketName) {
        const { [title]: _, ...rest } = prevCategories;
        return rest;
      } else {
        return { ...prevCategories, [title]: detail.bucketName };
      }
    });
  };

  return (
    <Box sx={{ width: "300px" }}>
      {categoryTitles.map(([title, details], index) => (
        < StyledAccordion
          key = { index }
          expanded = { expanded === title}
      onChange={handleChange(title)}
        >
      <StyledAccordionSummary>
        <ExtendedTypography sx={{ color: "#45809A" }}>
          {t("document.filter." + title)}
        </ExtendedTypography>
      </StyledAccordionSummary>
      {details.map((detail, i) => (
        <StyledAccordionDetails key={i}>
          <ExtendedTypography
            onClick={(e) => handleSelectCategory(title, detail)}
          >
            {detail.bucketName} ({detail.docCount})
          </ExtendedTypography>
        </StyledAccordionDetails>
      ))}
    </StyledAccordion>
  ))
}
    </Box >
  );
};
