import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons/faTriangleExclamation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogTitle,
  Button,
  Divider,
  DialogContentText,
  DialogContent,
  DialogActions,
  Stack,
} from "@vapor/react-material";
import IconButton from "@vapor/react-material/IconButton";
import { useEffect, useState } from "react";
import { Typography } from "@vapor/react-extended";
import { useDeleteFolder } from "../../hooks/useDeleteFolder";
import React from "react";
import { faEnvelope, faLink } from "@fortawesome/pro-light-svg-icons";

const ShareFolderModal = ({ open, setOpen, id }: any) => {
  const [clickToConfirm, setClickToConfirm] = useState(false);

  const handleDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (clickToConfirm) {
      fetch();
    }
  }, [clickToConfirm]);

  const { data, loading, error, fetch, hasLoaded } = useDeleteFolder(id);

  return (
    <Dialog
      open={open}
      onClose={handleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        Condividi questo fascicolo
        <IconButton color="primary" onClick={handleDialog}></IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Condividi il link per il download del fascicolo + nome fascicolo.
        </DialogContentText>
        <Stack direction="row" marginTop="10px">
          <Stack>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "56px",
                marginRight: "10px",
                height: "56px",
                padding: "8px",
                background: "#F2F6F8",
                borderRadius: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon icon={faLink} color="#566B76" fontSize="24px" />
            </div>
            <Typography
              color="#1F292E"
              fontWeight="400"
              paddingTop="5px"
              fontFamily="Roboto"
              fontSize="12px"
            >
              Copia Link
            </Typography>
          </Stack>
          <Stack>
            <div
              style={{
                display: "flex",
                width: "56px",
                height: "56px",
                padding: "8px",
                background: "#F2F6F8",
                borderRadius: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                color="#566B76"
                fontSize="24px"
              />
            </div>
            <Typography
              color="#1F292E"
              fontWeight="400"
              paddingTop="5px"
              marginLeft="12px"
              fontFamily="Roboto"
              fontSize="12px"
            >
              Email
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ShareFolderModal;
