import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function useGetDocumentTypeCode( idForAttributesCall,workspaceId ) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `documentsApi://api/v1/documents/attributes?typeCode=${idForAttributesCall}&workspaceId=${workspaceId}`,
    {
      lazy: true,
      headers: buildHeaders({ authData })
    }
  );
  useEffect(() => {
    if (idForAttributesCall || data) {
      fetch();
    }
  }, [idForAttributesCall]);

  return { data, loading, hasLoaded, fetch, error };
}
