import { useEffect } from "react";
import { useAuth, usePost } from "@onefront/react-sdk";
import { buildHeaders } from "./buildHeaders";
import { faLacrosseStickBall } from "@fortawesome/pro-light-svg-icons";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function useGetExistingDocuments(searchTerm: any) {
  const authData = useAuth();

  const { workspaceId, selectedTenant: currentCompany } =
    useClientInformationStore();

  const clientId = currentCompany?.itemId;

  const payload = {
    workspaceId,
    pageNumber: "1",
    pageSize: "10",
    sort: "LATEST_UPLOADS",
    readByAccountant: false,
    readByCompany: false,
    urgentDocs: "",
    loggedItemId: clientId,
    startDate: "",
    endDate: "",
    filterPerYear: "",
    dateType: "DOCUMENT",
    microCategory: "",
    macroCategory: "",
    selectedCategories: {},
    invoiceNumber: "",
    checkIfUploaded: "",
    searchTerm,
  };

  const { data, loading, hasLoaded, fetch, error } = usePost(
    `documentsApi://api/v1/documents/search?`,
    payload,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  useEffect(() => {
    if (searchTerm) {
      fetch();
    }
  }, [searchTerm]);

  return { data, loading, hasLoaded, fetch, error };
}
