import { faDownload } from "@fortawesome/pro-regular-svg-icons/faDownload";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare";
import { faMessageLines as faMessageLinesBold } from "@fortawesome/pro-solid-svg-icons/faMessageLines";
import { faSidebarFlip } from "@fortawesome/pro-solid-svg-icons/faSidebarFlip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Box from "@vapor/react-material/Box";
import { useTranslation } from "@onefront/react-sdk";
import { IconButton, Tooltip } from "@vapor/react-material";
import Badge from "@vapor/react-material/Badge";
import Popover from "@vapor/react-material/Popover";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDownloadDocument } from "../../documents/hooks/api-hooks/useDownloadADocument";
import { Stack } from "@vapor/react-material/Stack";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import AiAssistantFloatingBubble from "../../newDocuments/document-components/AiAssistantFloatingBubble";

export default function DocumentDetailUpperBar({
  data,
  setExpanded,
  expanded,
  setexpandedComment,
  expandedComment,
  contentData,
  id,
  commentCount,
  setExpandedAssistant,
  expandedAssistant,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentType, setDocumentType] = useState();
  const open = Boolean(anchorEl);
  const { workspaceId } = useParams();
  const isFatture = useMemo(() => data?.einvoice === true, [data]);
  const { t } = useTranslation("DOCS");
  const {
    data: download,
    loading,
    fetch,
  } = useDownloadDocument(
    id,
    workspaceId,
    data?.fileName,
    documentType,
    isFatture
  );
  const navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    isFatture ? setAnchorEl(event.currentTarget) : setDocumentType("ASSOSW");
  };

  useEffect(() => {
    if (documentType === "ASSOSW" || documentType === " ") {
      fetch();
    }
  }, [documentType]);

  return (
    <Box sx={{ flexGrow: 1, paddingRight: "20px" }}>
      <Stack direction="row" justifyContent="space-between">
        <Tooltip title={t("document.docDetail")} placement="top" arrow>
          <IconButton
            size="large"
            color="primary"
            onClick={() => setExpanded(!expanded)}
          >
            <FontAwesomeIcon size="lg" icon={faSidebarFlip} />
          </IconButton>
        </Tooltip>

        <div>
          <Tooltip title={t("document.aiAssistant")} arrow placement="top">
            <AiAssistantFloatingBubble bottom={"500px"}>
              <FontAwesomeIcon
                color="white"
                size="xl"
                icon={faSparkles}
                onClick={() => {
                  setexpandedComment(true);
                  setExpandedAssistant(!expandedAssistant);
                }}
              />
            </AiAssistantFloatingBubble>
          </Tooltip>
          <Tooltip arrow title={t("document.openComments")} placement="top">
            <IconButton
              onClick={() => {
                if (expanded === true && expandedComment === false) {
                  setexpandedComment(expandedComment);
                  setExpandedAssistant(false);
                } else {
                  setexpandedComment(!expandedComment);
                  setExpandedAssistant(false);
                }
              }}
              size="large"
              color="primary"
            >
              {commentCount.notesCount === 0 ? (
                <FontAwesomeIcon size="lg" icon={faMessageLinesBold} />
              ) : commentCount.unreadNotes > 0 ? (
                <Badge
                  variant="dot"
                  color="primary"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      fontSize: 15,
                      height: 7,
                      minWidth: 7,
                      transform: "translate(4px, -4px)",
                    },
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faMessageLinesBold} />
                </Badge>
              ) : (
                <Badge
                  variant="dot"
                  color="secondary"
                  overlap="circular"
                  sx={{
                    "& .MuiBadge-badge": {
                      fontSize: 15,
                      height: 7,
                      minWidth: 7,
                      transform: "translate(4px, -4px)",
                    },
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faMessageLinesBold} />
                </Badge>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("document.preview")} arrow placement="top">
            <IconButton
              size="large"
              color="primary"
              onClick={() => navigate(`/document-preview/${id}/${workspaceId}`)}
            >
              <FontAwesomeIcon size="lg" icon={faArrowUpRightFromSquare} />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title={t("document.downloadDoc")} placement="top">
            <IconButton onClick={handleClick} size="large" color="primary">
              <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </IconButton>
          </Tooltip>
          {isFatture && (
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                margin={2}
                onClick={() => {
                  setDocumentType("ASSOSW");
                }}
              >
                {t("document.downloadPdf")}
              </Typography>
              <Typography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                margin={2}
                onClick={() => {
                  setDocumentType(" ");
                }}
              >
                {t("document.downloadXml")}
              </Typography>
            </Popover>
          )}
        </div>
      </Stack>
    </Box>
  );
}
