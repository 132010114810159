import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";
import { useEffect, useMemo, useState } from "react";
import { splitByCapitalLetter } from "../common-utils/splitByCapitalLetter";
import {
  StyledFontAwesomeFaCircleXmark,
  StyledRadiusButton,
  StyledTagWithRadiusButton,
  StyledTagWrapperDiv,
} from "./classification-styled-component/EticetsStyledComponent";
import { formatDateValue } from "./classification-utils/formatDateValue";
import { useDataMapInputState } from "./DataMapInputState";
import { DynamicFields } from "./DynamicFields";

export const DocumentEticets = ({
  dataTypeCodes,
  setActiveStep,
  setKvags,
  setTagsToSend,
  costumeTags,
  docDetailData,
  setErrorAlert,
  activeStep,
  setCheckIfFieldsAreFilled,
}) => {
  const [tagName, setTagName] = useState("");
  const [tags, setTags] = useState([]);
  const { t } = useTranslation("DOCS");

  const removeMe = (index) => setTags(tags.filter((_, i) => i !== index));
  
  const {
    dataMapInputState,
    onChangeByName,
    setDataMapInputState,
    errorLabel,
  } = useDataMapInputState(dataTypeCodes, activeStep,docDetailData?.standardTags);

  // Set the value of the specified key to an empty string
  const modifyDataMapInputState = (key) => {
    const updatedDataMap = { ...dataMapInputState, [key]: "" };
    setDataMapInputState(updatedDataMap);
  };

  setErrorAlert(errorLabel);
  setCheckIfFieldsAreFilled(errorLabel);

  useEffect(() => {
    const arrayToPutInKvagrgs = Object.entries(dataMapInputState).map(
      ([key, value]) => ({ key, value })
    );
    setKvags(arrayToPutInKvagrgs);
  }, [dataMapInputState]);

  useEffect(() => {
    setTagsToSend(tags);
  }, [tags]);

  useMemo(() => {
    if (costumeTags) {
      costumeTags.map((tag) => {
        setTags((prevState) => [...prevState, tag]);
      });
    }
  }, []);
  return (
    <>
      <DynamicFields
        dataTypeCodes={dataTypeCodes}
        dataMapInputState={dataMapInputState}
        onChangeByName={onChangeByName}
      />
      <div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          {Object.entries(dataMapInputState).map(
            ([key, value], index) =>
              value && (
                <div key={index}>
                  <StyledRadiusButton>
                    <div style={{ display: "flex" }}>
                      <FontAwesomeIcon
                        color="white"
                        size="16px"
                        icon={faCircleXmark}
                        style={{
                          marginTop: 3,
                          marginRight: 5,
                          cursor: "pointer",
                        }}
                        onClick={() => modifyDataMapInputState(key)}
                      />
                      <Typography
                        fontWeight={"solid"}
                        fontSize={"12px"}
                        color={"white"}
                      >
                        {`${splitByCapitalLetter(key)}: ${formatDateValue(
                          value
                        )}`}
                      </Typography>
                    </div>
                  </StyledRadiusButton>
                </div>
              )
          )}
        </div>
        <div style={{ marginBottom: 40, marginTop: 20 }}>
          <div style={{ display: "flex", marginBottom: 20 }}>
            <FormControl style={{ width: "85%" }} wid>
              <InputLabel htmlFor="outlined-adornment-amount">
                <Typography
                  fontFamily={"Roboto"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {t("classification.costumizedTag")}
                </Typography>
              </InputLabel>
              <TextField
                placeholder={t("classification.typeTheNameOfNewTag")}
                onChange={(e) => setTagName(e.target.value)}
                size="large"
                id="outlined-adornment-amount"
              />
            </FormControl>
            <div>
              <Button
                size="large"
                sx={{ marginTop: "28%", marginLeft: 1, marginRight: 1 }}
                variant="contained"
                onClick={() => {
                  tagName !== "" && setTags([...tags, tagName]);
                }}
              >
                {t("classification.add")}
              </Button>
            </div>
          </div>
          {tags.map((name, index) => (
            <StyledTagWrapperDiv>
              <StyledTagWithRadiusButton onClick={() => removeMe(index)}>
                <div style={{ display: "flex" }}>
                  <StyledFontAwesomeFaCircleXmark icon={faCircleXmark} />
                  <Typography
                    fontWeight={"solid"}
                    fontSize={"12px"}
                    color={"white"}
                  >
                    {name}
                  </Typography>
                </div>
              </StyledTagWithRadiusButton>
            </StyledTagWrapperDiv>
          ))}
        </div>
      </div>
    </>
  );
};
