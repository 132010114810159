import TextField from "@vapor/react-material/TextField";
import {  it,es,enGB } from 'date-fns/locale'
import { endOfDay, addHours } from 'date-fns'
import { AdapterDateFns, LocalizationProvider, DatePicker } from "@vapor/react-x-date-pickers";
import { useTranslation } from "@onefront/react-sdk";

const DocumentDatePicker = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  width,
  label1,
  label2
}) => {
  const { t, i18n } = useTranslation("DOCS")
  const language = i18n.language
  const locale = language === 'it' ? it : language === "es" ? es : enGB
  return (
    <>
      <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDateFns} >
        <div style={{ display: "flex", width: width }}>
          <DatePicker
            sx={{ marginRight: 2, zIndex: 0 }}
            key={1}
            label={label1}
            value={startDate}
            ampm={false}
            onChange={(newValue) => {
              setStartDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            sx={{ zIndex: 0 }}
            key={2}
            ampm={false}
            label={label2}
            value={endDate}
            onChange={(newValue) => {
              setEndDate(endOfDay(newValue));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>
    </>
  );
};
export default DocumentDatePicker;
