import React from "react";

export function AggregateByCategories(data) {
  const result = {};
  data?.forEach((obj) => {
    const { macroCategory, microCategory, label, typeCode } = obj;
    if (!result[macroCategory]) result[macroCategory] = {};
    if (!result[macroCategory][microCategory])
      result[macroCategory][microCategory] = {};
    if (!result[macroCategory][microCategory][label])
      result[macroCategory][microCategory][label] = typeCode;
  });
  return result;
}
