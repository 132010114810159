import { faEye } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import {
  Card,
  ListItemIcon,
  MenuItem,
  MenuList,
} from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import React, { useState } from "react";
import {
  macroCategoryCostants,
  macroCategoryConstant,
} from "../common-components/CostantStrings";
import { useNavigate } from "react-router";
interface MenuItems {
  standardTags: any;
  title: string;
  documentId: number;
  fileName: string;
}

interface IconMenuProps {
  menuItems: MenuItems[];
  setSelectedDocuments: any;
  workspaceId: string | undefined;
}

const DocumentLinkedList: React.FC<IconMenuProps> = ({
  menuItems,
  setSelectedDocuments,
  workspaceId,
}) => {
  const { t } = useTranslation("DOCS");
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  setSelectedDocuments(selectedItems);

  const handleItemClick = (documentId: number) => {
    if (selectedItems.includes(documentId)) {
      setSelectedItems(selectedItems.filter((id) => id !== documentId));
    } else {
      setSelectedItems([...selectedItems, documentId]);
    }
  };

  const getInvoiceStatus = (item: any) => (
    <div>
      {item.title.indexOf("Invoice issued to") !== -1
        ? t("document.invoice.issued") + item.title.slice(17)
        : item.title.indexOf("Invoice received from") !== -1
        ? t("document.invoice.received") + item.title.slice(21)
        : item.title}
    </div>
  );

  return (
    <div>
      <Card>
        <MenuList id="icon-menu">
          {menuItems?.map((item) => {
            let iconName =
              item.standardTags.filter(
                (category: any) => category.name === "macrocategory",
              )[0].value || macroCategoryCostants.tutti.icon;
            return (
              <MenuItem
                key={item.documentId}
                onClick={() => handleItemClick(item.documentId)}
                selected={selectedItems.includes(item.documentId)}
                sx={
                  selectedItems.includes(item.documentId)
                    ? { backgroundColor: "rgb(214, 241, 255)", color: "white" }
                    : {}
                }
                style={{ justifyContent: "space-between" }}
              >
                <div style={{ display: "flex" }}>
                  <ListItemIcon>
                    <FontAwesomeIcon
                      icon={iconName && macroCategoryConstant.get(iconName)}
                      color={"#0177ac"}
                      size="lg"
                    />
                  </ListItemIcon>
                  <Typography
                    fontFamily={"Roboto"}
                    fontSize={"14px"}
                  >
                    {getInvoiceStatus(item)}
                  </Typography>{" "}
                </div>
                <div
                  style={{ padding: "1" }}
                  onClick={() => {
                    navigate(
                      `/document-details/${item.documentId}/${workspaceId}`,
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faEye} color={"#0177ac"} size="lg" />
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Card>
    </div>
  );
};

export default DocumentLinkedList;
