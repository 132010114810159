import { useTranslation } from "@onefront/react-sdk";

export function SubtitleAndTitleFormatter(title: string) {
  const { t } = useTranslation("DOCS");

  const words: string[] = title.split(" ");

  if (words.length > 0 && words[0] === "to") {
    words.shift();
    words.unshift(t("document.to"));
  }
  if (words.length > 0 && words[0] === "from") {
    words.shift();
    words.unshift(t("document.from"));
  }

  return words.join(" ").toString();
}
