import { DocumentPageDetail } from "./DocumentPageDetail";

export const documentDetail = ({ getConfig }) => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/document-details/:id/:workspaceId",
        component: DocumentPageDetail
      }
    }
  ];
};
