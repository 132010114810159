import { MenuItem, Select } from "@vapor/react-material";

export const CompanySelector = ({ value, data, onChange }) => {
  return (
    <Select
      sx={{ marginLeft: "20px", width: 200 }}
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={onChange}
    >
      {data &&
        data.map((x, i) => (
          <MenuItem value={x.id} key={i}>
            {x.value}
          </MenuItem>
        ))}
    </Select>
  );
};
