import {useTranslation} from "@onefront/react-sdk";


const useFormatDocumentName = (name: string) => {
    const { t } = useTranslation("DOCS")

    return name.indexOf("Invoice issued to") !== -1 ?
        t("document.invoice.issued") + name.slice(17) :
        name.indexOf("Invoice received from") !== -1 ?
            t("document.invoice.received") + name.slice(21) :
            name
}

export default useFormatDocumentName