import { useAuth, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

interface usePatchToToggleVisibilityProps {
  id: string;
  workspaceId: string;
  toggleVisibility: boolean | null;
}

export function usePatchToToggleVisibility({
  id,
  workspaceId,
  toggleVisibility,
}: usePatchToToggleVisibilityProps) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "patch",
    lazy: true,
    headers: buildHeaders({ authData }),
    url: `documentsApi://api/v1/documents/${id}/toggleVisibility?workspaceId=${workspaceId}&visibleByAccountantOnly=${toggleVisibility}`,
  });

  useEffect(() => {
    if (toggleVisibility || (!toggleVisibility && toggleVisibility !== null)) {
      fetch();
    }
  }, [toggleVisibility]);

  return { data, loading, hasLoaded, fetch, error };
}
