import { WorkspaceSelector } from "@drift/oneplatfront-components";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Slide } from "@vapor/react-material";
import Card from "@vapor/react-material/Card";
import { UploadSmartComponent } from "@onefront/documents-smart-components";
import { useAuth, useTranslation } from "@onefront/react-sdk";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Snackbar } from "@vapor/react-material";
import Alert from "@vapor/react-material/Alert";
import AlertTitle from "@vapor/react-material/AlertTitle";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Divider from "@vapor/react-material/Divider";
import Grid from "@vapor/react-material/Grid";
import IconButton from "@vapor/react-material/IconButton";
import Stack from "@vapor/react-material/Stack";
import { endOfDay, format, subYears } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DocumentDatePicker from "../common-components/DocumentDatePicker";
import { useClientInformationStore } from "../store/useClientInformationStore";
import CardList from "./document-components/CardList";
import { AccordionFilter } from "./document-components/CategoryFilter";
import { CheckboxFilter } from "./document-components/CheckBoxFilter";
import ChipsFilter from "./document-components/ChipsFilter";
import { DateFilter } from "./document-components/DateFilter";
import { DateType } from "./document-components/DateType";
import DocumentHeader from "./document-components/DocumentHeader";
import { DownloadConfirmationAlert } from "./document-components/DownloadConfirmationAlert";
import { FileFormatSwitcher } from "./document-components/FileFormatSwitcher";
import { InfoAlert } from "./document-components/InfoAlert";
import { InvoiceFilter } from "./document-components/InvoiceFilter";
import { Loading } from "./document-components/Loading";
import MasiveDownloadActionButton from "./document-components/MasiveDownloadActionButton";
import { NotFoundBanner } from "./document-components/NotFound";
import { DocumentsReadByFilter } from "./document-components/ReadByFilter";
import { Search } from "./document-components/SearchBar";
import { SortingFilter } from "./document-components/SortingFilter";
import { useGetAllowedMacrocategories } from "./hooks/api-hooks/useAllowedMacrocategories";
import { useGetDocuments } from "./hooks/api-hooks/useGetDocuments";
import { useDebounce } from "./hooks/useDebouncing";

export const DocumentPage = () => {
  useEffect(() => {
    if (window.aptrinsic) {
      window.aptrinsic("track", "docs:ACCESS-LANDING-PAGE");
    }
  }, []);

  const { t } = useTranslation("DOCS");
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isEmbeded = query.get("embed");
  const [searchValue, setSearchValue] = useState("");

  const [selectedChip, setSelectedChip] = useState("Inbox");
  const [sortOption, setSortOptions] = useState("LATEST_UPLOADS");
  const [selectedFileFormat, setSelectedFileFormat] = useState("Card");
  const [readByAccountant, setReadByAccountant] = useState(false);
  const [readByCompany, setReadByCompany] = useState(false);
  const [urgentDocs, setUrgentDocs] = useState(
    query.get("isUrgent") === "true"
  );
  const [dateType, setDateType] = useState("UPLOAD");
  const [selectedYear, setSelectedYear] = useState(
    t("document.datefilter.all")
  );
  const [monthType, setMonthType] = useState("month");
  const [months, setMonths] = useState([
    { name: t("document.dateFilter.all"), value: t("document.dateFilter.all") },
  ]);
  const [displayDataToCard, setDisplayDataToCard] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const invoiceNumberDebounced = useDebounce(invoiceNumber);
  const [displayPage, setDisplayPage] = useState(true);
  const {
    selectedTenant: currentCompany,
    selectedWorkspace: ws,
    workspaceId,
  } = useClientInformationStore();
  const [microCategories, setMicroCategories] = useState([]);
  const [selectedMicro, setSelectedMicro] = useState([]);
  const [prevMacro, setPrevMacro] = useState("");
  const [userCanUpload, setUserCanUpload] = useState(false);
  const [checkIfUploaded, setCheckIfUploaded] = useState(false);
  const [downloadMassiveDocuments, setDownloadMassiveDocuments] =
    useState(false);
  const subtitle = ws
    ? `${
        [...ws.companies, ...ws.accountants].find((x) => x.id === workspaceId)
          ?.description ?? ""
      }`
    : "";

  const auth = useAuth()?.tokenData;
  const user = {
    id: auth?.client_id,
    email: auth?.email,
    firstName: auth?.given_name,
    lastName: auth?.family_name,
  };

  const isAccountant = ws?.itemType === "ACCOUNTANT";

  const company = ws?.companies?.at(0);
  const accountant = ws?.accountants?.at(0);
  const userAccount = isAccountant ? accountant : company;

  const account = {
    id: userAccount?.id,
    name: userAccount?.description,
  };

  if (window.aptrinsic) {
    window.aptrinsic("identify", user, account);
  }

  const { data, loading, hasLoaded, error } = useGetDocuments({
    workspaceId,
    searchTerm: searchValue,
    sort: sortOption,
    readByAccountant: readByAccountant,
    readByCompany: readByCompany,
    urgentDocs: urgentDocs,
    dateType: dateType,
    startDate: startDate ? format(startDate, "yyyy-MM-dd'T'00:00") : "",
    endDate: endDate ? format(endOfDay(endDate), "yyyy-MM-dd'T'kk:mm") : "",
    macrocategory:
      selectedChip === t("document.dateFilter.all") ? "" : selectedChip,
    microcategory: selectedMicro,
    selectedCategories,
    filterPerYear:
      months[0].name === t("document.dateFilter.all")
        ? selectedYear !== t("document.dateFilter.all")
          ? ""
          : ""
        : "",
    pageSize: pageSize,
    pageNumber,
    invoiceNumber: invoiceNumberDebounced,
    checkIfUploaded: checkIfUploaded,
  });

  useEffect(() => {
    if (
      data?.aggregationValue?.macrocategory?.[0] &&
      prevMacro !== data?.aggregationValue?.macrocategory[0]?.bucketName
    ) {
      const updatedMicroCategories = data?.aggregationValue.microcategory
        ?.filter((cat) => cat?.bucketName !== "Inbox")
        .map((category) => ({
          name: category?.bucketName,
          value: category?.docCount,
          checked: true,
        }));
      setPrevMacro(data?.aggregationValue?.macrocategory?.[0].bucketName);
      setMicroCategories(updatedMicroCategories);
    }
  }, [data?.aggregationValue.macrocategory]);

  const handleCheckboxChange = (changedCategory) => {
    if (selectedMicro.length === 1 && changedCategory.checked) return false;
    setMicroCategories((prevMicroCategories) => {
      const updatedCategories = prevMicroCategories.map((category) => {
        if (category.name === changedCategory.name) {
          return {
            ...category,
            checked: !category.checked,
          };
        } else {
          return category;
        }
      });

      const micros = updatedCategories
        .filter((category) => category.checked)
        .map((category) => category.name);
      setSelectedMicro(micros);
      return updatedCategories;
    });
  };
  useEffect(() => {
    setPageNumber(0);
    setDisplayDataToCard([]);
  }, [
    workspaceId,
    searchValue,
    currentCompany,
    sortOption,
    readByAccountant,
    urgentDocs,
    readByCompany,
    dateType,
    pageSize,
    selectedChip,
    selectedYear,
    months,
    setPageNumber,
    selectedCategories,
    invoiceNumber,
    startDate,
    endDate,
    selectedMicro,
    checkIfUploaded,
    setCheckIfUploaded,
  ]);

  useEffect(() => {
    if (!loading && !error && data) {
      if (!pageNumber) return setDisplayDataToCard(documents);
      setDisplayDataToCard((prev) => [...prev, ...documents]);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (ws?.companies.length === 0 && ws?.accountants.length === 0) {
      setDisplayPage(false);
    } else {
      setDisplayPage(true);
    }
  }, [ws]);

  const permisionData = useGetAllowedMacrocategories(workspaceId)?.data;

  const macroCategoriesData = permisionData?.aggregationValue.macrocategory;
  const canUpload = permisionData?.permissions?.uploadWeb;

  useEffect(() => {
    setUserCanUpload(canUpload);
  }, [canUpload]);

  useEffect(() => {
    const inbox =
      macroCategoriesData &&
      macroCategoriesData.find((obj) => obj?.bucketName === "Inbox");
    const first = macroCategoriesData && macroCategoriesData[0]?.bucketName;
    macroCategoriesData && inbox
      ? setSelectedChip(inbox?.bucketName)
      : setSelectedChip(first?.bucketName);
  }, [macroCategoriesData]);

  const macroCategories = new Map(
    macroCategoriesData &&
      macroCategoriesData?.map((category) => [
        category?.bucketName,
        category?.docCount,
      ])
  );

  const documents = data?.searchDocumentsResponse;

  const allYears = [...Array(10).keys()].map((n) =>
    format(subYears(new Date(), n), "yyyy")
  );

  useEffect(() => {
    setSelectedYear(t("document.dateFilter.all"));
    setSelectedMicro([]);
    setSelectedCategories({});
    // setStartDate(subYears(new Date(), 15));
    // setEndDate(new Date());
  }, [selectedChip]);

  useEffect(() => {
    setSelectedChip("Inbox");
  }, [workspaceId]);

  useEffect(() => {
    setMonths([
      {
        name: t("document.dateFilter.all"),
        value: t("document.dateFilter.all"),
      },
    ]);
  }, [monthType, selectedYear]);

  useEffect(() => {
    if (months.length === 0) {
      setMonths([
        {
          name: t("document.dateFilter.all"),
          value: t("document.dateFilter.all"),
        },
      ]);
    }
  }, [months]);

  const loadMore = () => {
    setPageNumber(pageNumber + 1);
  };
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Box sx={{ backgroundColor: "hsl(199, 30%, 93%)" }}>
      <Card
        sx={{
          paddingTop: "30px",
          marginLeft: "16px",
          marginRight: "16px",
          boxShadow: "inset 0px 16px hsl(199, 30%, 93%)",
        }}
      >
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          TransitionComponent={Slide}
        >
          <Alert
            variant="outlined"
            severity="success"
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleClose}
                style={{ color: "black", fontWeight: "bold" }}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ fontWeight: "bold", verticalAlign: "middle" }}
                />
              </IconButton>
            }
          >
            <AlertTitle>{t("document.succes")}</AlertTitle>
          </Alert>
        </Snackbar>
        <Stack direction="column" spacing={3}>
          <Grid container direction="row">
            <Grid container item direction="column" xs={4}>
              <Stack alignItems="flex-start" directon="row">
                <WorkspaceSelector></WorkspaceSelector>
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Stack direction="column" alignItems="center">
                <Typography
                  sx={{
                    fontFamily: "Cairo",
                    fontWeight: "600",
                    textDecoration: "none",
                    textTransform: "none",
                    color: "rgb(0, 70, 102)",
                    padding: "0px",
                    lineHeight: "1.5",
                    fontSize: "20px",
                  }}
                >
                  {t("common.title")}
                </Typography>
                <Typography
                  sx={{
                    margin: "0px",
                    fontFamily: "Cairo",
                    color: "rgb(98, 121, 132)",
                    fontWeight: "400",
                    fontSize: "12px",
                    padding: "0px",
                    lineHeight: "1",
                  }}
                >
                  {t("document.subTitle") +
                    `${
                      ws
                        ? `: ${
                            [...ws?.companies, ...ws?.accountants].find(
                              (x) => x.id === workspaceId
                            )?.description
                          }`
                        : ""
                    }`}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          <Divider></Divider>
        </Stack>
      </Card>
      <DocumentHeader />
      {data && displayPage && (
        <Box
          style={{
            position: "sticky",
            top: isEmbeded ? "-1px" : "45px",
            zIndex: 10,
            paddingLeft: "16px",
            paddingRight: "16px",
            backgroundColor: "hsl(199, 30%, 93%)",
            textAlign: "left",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              padding: "16px",
              backgroundColor:"white"
            }}
          >
            <Stack direction="row" justifyContent="space-between">
              <ChipsFilter
                selectedChip={selectedChip}
                setSelectedChip={setSelectedChip}
                macroCategories={macroCategories}
              ></ChipsFilter>
              <FileFormatSwitcher
                selectedFileFormat={selectedFileFormat}
                setSelectedFileFormat={setSelectedFileFormat}
              ></FileFormatSwitcher>
            </Stack>
            <Box>
              <CheckboxFilter
                checkboxes={microCategories}
                handleCheckboxChange={handleCheckboxChange}
              />
            </Box>
          </div>
        </Box>
      )}
      <Card
        sx={{ marginLeft: "16px", marginRight: "16px", minHeight: "100vh" }}
      >
        <Box sx={{ padding: "20px" }}>
          {displayPage ? (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              columnGap={4}
            >
              {!currentCompany?.itemId ? (
                ""
              ) : (
                <Grid item xs={4.2} style={{ maxWidth: "300px" }}>
                  {/* position={"fixed"} try using this for sticky filters */}
                  <Stack direction="column" spacing={4}>
                    <Search
                      setSearchValue={setSearchValue}
                      width={260}
                    ></Search>

                    <SortingFilter
                      sortOption={sortOption}
                      setSortOptions={setSortOptions}
                    ></SortingFilter>
                    <DocumentsReadByFilter
                      readByAccountant={readByAccountant}
                      readByCompany={readByCompany}
                      setReadByAccountant={setReadByAccountant}
                      setReadByCompany={setReadByCompany}
                      urgentDocs={urgentDocs}
                      setUrgentDocs={setUrgentDocs}
                    ></DocumentsReadByFilter>

                    <>
                      <DateType
                        doNotShow={true}
                        dateType={dateType}
                        setDateType={setDateType}
                      ></DateType>
                    </>

                    <DocumentDatePicker
                      label1={t("document.MonthFilter.startDate")}
                      label2={t("document.MonthFilter.endDate")}
                      startDate={startDate}
                      endDate={endDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                    {selectedChip === "Fatture" &&
                      selectedYear !== "document.dateFilter.all" && (
                        <>
                          <InvoiceFilter
                            setInvoiceNumber={setInvoiceNumber}
                            invoiceNumber={invoiceNumber}
                          ></InvoiceFilter>
                          <DateFilter
                            allYears={allYears}
                            selectedYear={selectedYear}
                            setSelectedYear={setSelectedYear}
                            monthType={monthType}
                            months={months}
                            setMonth={setMonths}
                            setMonthType={setMonthType}
                            setMonths={setMonths}
                          ></DateFilter>
                        </>
                      )}
                    {data && (
                      <AccordionFilter
                        setSelectedCategories={setSelectedCategories}
                        categories={data.aggregationValue}
                      ></AccordionFilter>
                    )}
                  </Stack>
                </Grid>
              )}
              <Grid item xs={7.8} style={{ maxWidth: "100%", flexGrow: "1" }}>
                <Stack direction="column" alignItems="center">
                  {loading ? (
                    <Loading />
                  ) : hasLoaded && documents.length === 0 ? (
                    <NotFoundBanner />
                  ) : documents && documents.length !== 0 ? (
                    <>
                      <Stack justifyContent="center" />
                      <CardList
                        readByCompany={readByCompany}
                        readByAccountant={readByAccountant}
                        urgentDocs={urgentDocs}
                        workspaceId={workspaceId}
                        data={displayDataToCard}
                        selectedFileFormat={selectedFileFormat}
                        selectedChip={selectedChip}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  {data?.searchDocumentsResponse.length >= 49 &&
                    data &&
                    !loading && (
                      <Box marginTop={"10px"}>
                        <Button variant="outlined" onClick={loadMore}>
                          {t("classification.load")}
                        </Button>
                      </Box>
                    )}
                  {!displayDataToCard && !displayPage && <InfoAlert />}
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img src="banner-notfound.png" alt="not found"></img>
            </Box>
          )}
        </Box>
      </Card>
      {!currentCompany?.itemId ? (
        ""
      ) : (
        <VaporToolbar
          contentLeft={[
            <MasiveDownloadActionButton
              downloadMassiveDocuments={downloadMassiveDocuments}
              setDownloadMassiveDocuments={setDownloadMassiveDocuments}
              setOpen={setOpen}
              setOpenModal={setOpenModal}
              documentList={displayDataToCard}
              workspaceId={workspaceId}
            />,
          ]}
          contentRight={
            canUpload && [
              <UploadSmartComponent
                loggedItemId={currentCompany?.itemId}
                setCheckIfUploaded={setCheckIfUploaded}
                isAccountant={ws?.itemType !== "COMPANY" ? true : false}
                subtitle={subtitle}
                workspaceId={workspaceId}
              />,
            ]
          }
          size="large"
          variants="regular"
        ></VaporToolbar>
      )}{" "}
      {openModal && (
        <DownloadConfirmationAlert
          setDownloadMassiveDocuments={setDownloadMassiveDocuments}
        />
      )}
    </Box>
  );
};
