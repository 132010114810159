import { useCallback, useEffect, useState } from "react";
import { useGetExistingDocuments } from "../../hooks/useGetExistingDocuments";
import SearchInput from "../../core/SearchInput";
import SearchIcon from "@mui/icons-material/Search";
import {
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  Stack,
} from "@vapor/react-material";
import { useGetFolderById } from "../../hooks/useGetFolderById";
import { useGetDocumentById } from "../../hooks/useGetDocumentById";
import sleep from "../../utils/SleepFunction";

const SearchDocumentComponent = ({ onDocumentSelect, prefilledIds }: any) => {
  const [searchValue, setSearchValue] = useState("");
  const [searchedDocuments, setSearchedDocuments] = useState<[]>([]);
  const [prefilledId, setPrefilledId] = useState();
  const [selectedSearchedDocuments, setSelectedSearchedDocuments] = useState<
    []
  >([]);

  const {
    data: documents,
    loading,
    error,
    fetch,
    hasLoaded,
  } = useGetExistingDocuments(searchValue);

  const {
    data: documentById,
    loading: loadingDocument,
    error: documentError,
    fetch: fetchDocument,
    hasLoaded: hasDocumentLoaded,
  } = useGetDocumentById({
    id: prefilledId,
  });

  const mapDocumentIds = () =>
    selectedSearchedDocuments &&
    selectedSearchedDocuments?.map((document: any) => document?.documentId);

  const handleDocSearch = useCallback(async () => {
    for await (const id of prefilledIds) {
      await sleep(100);
      setPrefilledId(id);
    }
  }, [prefilledIds]);

  const prefillSearchedDocuments = useCallback(async () => {
    if (hasDocumentLoaded && documentById && !documentError) {
      const uniqueResults = Array.from(
        new Set([...selectedSearchedDocuments, documentById])
      );
      await setSelectedSearchedDocuments(uniqueResults as any);
    }
    onDocumentSelect(mapDocumentIds());
  }, [documentById, prefilledId]);

  useEffect(() => {
    if (prefilledIds) {
      handleDocSearch();
    }
  }, [handleDocSearch, prefilledIds]);

  useEffect(() => {
    prefillSearchedDocuments();
  }, [prefillSearchedDocuments, documentById]);

  useEffect(() => {
    onDocumentSelect(mapDocumentIds());
  }, [selectedSearchedDocuments]);

  return (
    <Stack style={{ width: "100%" }}>
      <label>Seleziona uno o più documenti esistenti</label>
      <Stack direction="row" style={{ width: "100%" }}>
        <Autocomplete
          getOptionLabel={(option: any) =>
            option?.fileName || "Non specificato"
          }
          onChange={(_, value: any) => {
            setSelectedSearchedDocuments(value);
          }}
          id="multiple-limit-tags"
          style={{ width: "100%" }}
          multiple
          value={selectedSearchedDocuments}
          options={searchedDocuments ? searchedDocuments : []}
          renderInput={(params) => (
            <TextField
              value={selectedSearchedDocuments}
              onChange={(e: any) => {
                setSearchValue(e?.target.value);
                setSearchedDocuments(documents?.searchDocumentsResponse);
              }}
              {...params}
              error={error}
            />
          )}
        />
        <Button
          //   onClick={fetch}
          size="medium"
          sx={{
            "&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeMedium": {
              borderRadius: 0,
              minWidth: 40,
              p: 1,
            },
          }}
          variant="contained"
        >
          <SearchIcon />
        </Button>
      </Stack>
    </Stack>
  );
};
export default SearchDocumentComponent;
