import React from "react";
import { Stack, Button } from "@vapor/react-material"; // Adjust import
import Typography from "@vapor/react-extended/ExtendedTypography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusOctagon,
  faSquareCheck,
} from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "@vapor/react-material"; // Add Tooltip import
import { useTranslation } from "@onefront/react-sdk";

interface VisibilityStatusProps {
  hidden: boolean;
  isAccountant: boolean;
  toggleVisibility: boolean | null;
  setToggleVisibility: Function;
  isInvoice: boolean;
}

const VisibilityStatus: React.FC<VisibilityStatusProps> = ({
  hidden,
  isAccountant,
  toggleVisibility,
  setToggleVisibility,
  isInvoice,
}) => {
  const { t } = useTranslation("DOCS");

  return (
    <Stack direction="row" gap={1} alignItems="center">
      {hidden && (
        <>
          <FontAwesomeIcon color="red" icon={faMinusOctagon} />
          <Tooltip title={t("upload.docDetailChangeStatus")} arrow>
            <Button
              disabled={isInvoice}
              onClick={() =>
                setToggleVisibility(
                  toggleVisibility === null ? false : null
                )
              }
            >
              <Typography variant="body500">
                {t("classification.hidden")}
              </Typography>
            </Button>
          </Tooltip>
        </>
      )}
      {!hidden && isAccountant && (
        <>
          <FontAwesomeIcon color="#0090d1" icon={faSquareCheck} />
          <Tooltip title={t("upload.docDetailChangeStatus")} arrow>
            <Button
              disabled={isInvoice}
              onClick={() =>
                setToggleVisibility(
                  toggleVisibility === null ? true : null
                )
              }
            >
              <Typography variant="body500">
                {t("upload.docDetailVisibilToEveryone")}
              </Typography>
            </Button>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default VisibilityStatus;
