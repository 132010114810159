import { parseISO, format } from "date-fns";

export const DateFormatter = (date: string, locale?: Locale): string => {
  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, "d MMMM y", { locale: locale });
  } catch (error) {
    return date;
  }
};
