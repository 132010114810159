import moment from "moment";

export const formatDateValue = (value: string): string => {
  const dateFormats: string[] = ["YYYY-MM-DD", "DD/MM/YYYY"]; // Add any other date formats you expect
  const isValidDate: boolean = dateFormats.some((format: string) =>
    moment(value, format, true).isValid()
  );

  return isValidDate ? moment(value).format("DD/MM/YYYY") : value;
};

export const getFormattedDate = (info:any) =>
  info?.name?.startsWith("Data")
    ? moment(info?.value).format("YYYY-MM-DD")
    : ""
