import Grid from "@vapor/react-material/Grid";
import CardBox from "./CardBox";
import SliceCard from "./SliceCard";
import { Key } from "react";
import { CardListProps } from "../types";

const CardList = ({ data, selectedFileFormat, workspaceId }: CardListProps) => {
  return (
    <>
      {selectedFileFormat === "List" ? (
        <Grid container>
          {data &&
            data.map((doc: any, index: Key) => (
              <CardBox key={index} workspaceId={workspaceId} doc={doc} />
            ))}
        </Grid>
      ) : (
        data?.map((doc: any, index: Key) => (
          <SliceCard key={index} workspaceId={workspaceId} doc={doc} />
        ))
      )}
    </>
  );
};
export default CardList;
