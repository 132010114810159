import MuiAccordionDetails from "@vapor/react-material/AccordionDetails";
import Divider from "@vapor/react-material/Divider";
import { styled } from "@vapor/react-material/styles";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { useTranslation } from "@onefront/react-sdk";
import * as React from "react";
import { useParams } from "react-router-dom";
import DocumentLinkDrawer from "../../classification/DocumentLinkDrawer";
import { DocumentLinks } from "./DocumentLinks";
import { DocumentScannerDetails } from "./DocumentScannerDetails";
import RenameDocumentFileModal from "./RenameDocumentFileModal";
import moment from "moment";
import { LinkedDocumentModalInformation } from "../../classification/LinkedDocumentModalInformation";
import {
  StyledAccordion,
  StyledAccordionSummary,
} from "../../documents/document-styled-components/StyledAccordion";
import { Stack } from "@vapor/react-material";

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function AccordionDocumentDetails({
  setReload,
  data,
  workspaceId,
  loading,
  fetchDetails,
  standardTags,
  setNewName,
  NewName,
}) {
  const [expanded, setExpanded] = React.useState("panel1");
  const [deletedId, setDeletedId] = React.useState("");
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t } = useTranslation("DOCS");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const infoList = data?.infoList || [];
  const filteredData = infoList.filter(
    (x) =>
      x.label !== "AMOUNT" &&
      x.label !== "Creation date" &&
      x.label !== "invoiceNumber" &&
      x.label !== "microcategory" &&
      x.label !== "macrocategory" &&
      x.label !== "sdiReceiptDate" &&
      x.label !== "DATE"
  );

  const filteredTags = standardTags?.filter(
    (tag) =>
      tag.name !== "macrocategory" &&
      tag.name !== "microcategory" &&
      tag.name !== "category" &&
      tag.name !== "DataDocumento"
  );

  const open = Boolean(anchorEl);

  const getFormattedDate = (info) =>
    info?.name?.startsWith("Data")
      ? moment(info.value).format("DD/MM/YYYY")
      : info.value;

  return (
    <div>
      <StyledAccordion
        key={1}
        style={{ backgroundColor: expanded === "panel1" ? "white" : "#F7F7F7" }}
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <StyledAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography variant="titleSmall">
            {t("classification.info")}
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Stack direction="row" gap={1}>
              <Typography variant="body700">
                {t("classification.fileName")}:
              </Typography>
              <Typography variant="body500">
                {NewName ? NewName : data?.fileName}
              </Typography>
            </Stack>
            {!data?.readOnly && (
              <RenameDocumentFileModal
                NewName={NewName}
                setNewName={setNewName}
                data={data}
                setReload={setReload}
                workspaceId={workspaceId}
                id={id}
              />
            )}
          </Stack>
          <Divider
            sx={{ marginTop: 1, marginBottom: 1 }}
            style={{ background: "#C9CDCF" }}
          />
          {filteredData.map((info, index) =>
            info.label !== "category" ? (
              <Stack direction="row" key={index} gap={1}>
                <Typography variant="body700">
                  {t("classification." + info.label)}:
                </Typography>
                <Typography variant="body500">{info.value}</Typography>
              </Stack>
            ) : (
              <Stack direction="row" key={index} gap={1}>
                <Typography variant="body700">
                  {t("classification." + info.label)}:
                </Typography>
                <Typography variant="body500">
                  {t("document.chips." + info.value.split(" /")[0]) +
                    " / " +
                    t("micro." + info.value.split(" / ")[1])}
                </Typography>
              </Stack>
            )
          )}
          {filteredTags?.map((info, index) => (
            <div>
              {!!info?.value && (
                <Stack direction="row" key={index}>
                  <Typography variant="body700">
                    {t("classification." + info.name)}:
                  </Typography>
                  <Typography variant="body500">
                    {getFormattedDate(info)}
                  </Typography>
                </Stack>
              )}
            </div>
          ))}
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion
        key={2}
        style={{ backgroundColor: expanded === "panel2" ? "white" : "#F7F7F7" }}
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <StyledAccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
        >
          <Typography variant="titleSmall">
            {t("classification.documents")}
          </Typography>
        </StyledAccordionSummary>
        <AccordionDetails>
          <DocumentLinks
            data={data}
            setDeletedId={setDeletedId}
            workspaceId={workspaceId}
            fetchDetails={fetchDetails}
          />
          <DocumentLinkDrawer
            setReload={setReload}
            docLinkedDocuments={data?.linkedDocuments}
          />
        </AccordionDetails>
      </StyledAccordion>
      {data?.einvoice && data?.deadlines.length !== 0 && (
        <StyledAccordion
          key={3}
          style={{
            backgroundColor: expanded === "panel3" ? "white" : "#F7F7F7",
          }}
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <StyledAccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography variant="titleSmall">
              {t("deadlines.document")}
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <DocumentScannerDetails data={data?.deadlines} />
          </AccordionDetails>
        </StyledAccordion>
      )}
    </div>
  );
}
