import { Autocomplete } from "@vapor/react-material";
import TextField from "@vapor/react-material/TextField";
import Typography from "@vapor/react-extended/ExtendedTypography"
import { useTranslation } from "@onefront/react-sdk";
import { Skeleton } from "@vapor/react-material";
import InputLabel from "@vapor/react-material/InputLabel";
import { useEffect } from "react";
import {
  useGetDynamicInputStateFromCache,
  useGetSelectedLabelStateFromCache,
} from "../newDocuments/hooks/useGetDynamicInputStateFromCache";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function DocumentTypeComponent({
  setCategory,
  setSelectedLabel,
  labels,
  error,
  prefilledCategory,
  valuesToDisplay,
  selectedLabel,
  loading,
}) {
  const { t } = useTranslation("DOCS");
  const [cachedSelectedLabel, setCachedSelectedLabel] =
    useGetSelectedLabelStateFromCache(prefilledCategory);

  useEffect(() => {
    setSelectedLabel(cachedSelectedLabel);
  }, [cachedSelectedLabel]);
  useEffect(() => {
    if (cachedSelectedLabel) {
    } else {
      setSelectedLabel(prefilledCategory);
    }
  }, [prefilledCategory, cachedSelectedLabel]);

  return (
    <div style={{ marginBottom: 10, marginTop: 10 }}>
      {loading && !selectedLabel ? (
        <>
          <Skeleton width={"50%"} height={20} animation="wave" />
          <Skeleton width={"50%"} height={20} animation="wave" />
        </>
      ) : (
        <>
          <InputLabel>Tipo</InputLabel>
          <div>
            <Autocomplete
              disabled={loading}
              onChange={(event, value) => {
                setSelectedLabel(value);
                setCachedSelectedLabel(value);
              }}
              autoFocus
              defaultValue={prefilledCategory}
              value={cachedSelectedLabel}
              disablePortals
              id="combo-box-demo"
              options={labels}
              size="small"
              sx={{ width: "90%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={error}
                  defaultValue={prefilledCategory}
                />
              )}
            />
          </div>
          {cachedSelectedLabel && (
            <div style={{ display: "flex", marginTop: 10 }}>
              <Typography
                fontFamily={"Roboto"}
                fontSize={"14px"}
                marginRight="5px"
              >
                {t("classification.findDocInThisCategory")}:
              </Typography>
              <Typography
                fontFamily={"Roboto"}
                fontSize={"14px"}
                fontWeight={600}
              >
                {t("document.chips." + valuesToDisplay?.macroCategory)} /{" "}
                {t("micro." + valuesToDisplay?.microCategory)}
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}
