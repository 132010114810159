import Popover from "@vapor/react-material/Popover";
import { Typography } from "@vapor/react-extended";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  faPenToSquare,
  faShare,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";

type FolderCardPopupProps = {
  openPopup: boolean;
  anchorElement: any;
  setAnchorElement: any;
  setOpenDeleteModal: (value: boolean) => void;
  setOpenShareModal: (value: boolean) => void;
  setOpenPopup: (value: boolean) => void;
  id: string;
};
const FolderCardPopup = ({
  openPopup,
  anchorElement,
  setAnchorElement,
  setOpenDeleteModal,
  setOpenShareModal,
  setOpenPopup,
  id,
}: FolderCardPopupProps) => {
  const [selectedOption, setSelectedOption] = useState("");

  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorElement(null);
    setOpenPopup(false);
  };

  const handleEdit = () => {
    setAnchorElement(null);
    setOpenPopup(false);
    navigate(`/folder/${id}`);
  };

  const handleDeletion = () => {
    setAnchorElement(null);
    setOpenPopup(false);
    setOpenDeleteModal(true);
  };

  const handleShare = () => {
    setAnchorElement(null);
    setOpenPopup(false);
    setOpenShareModal(true);
  };

  const options = [
    { label: "Modifica", icon: faPenToSquare, clickHandler: handleEdit },
    { label: "Condividi", icon: faShare, clickHandler: handleShare },
    { label: "Elimina", icon: faTrashCan, clickHandler: handleDeletion },
  ];

  return (
    <Popover
      open={openPopup}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {options.map((option) => (
        <>
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
              color: "#008FD6",
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              fontSize: "14px",
              paddingRight: "40px",
            }}
            margin={2}
            onClick={() => {
              setSelectedOption(option.label);
              option.clickHandler();
            }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={option.icon as IconProp}
            />
            {option.label}
          </Typography>
        </>
      ))}
    </Popover>
  );
};

export default FolderCardPopup;
