import {DocumentPage} from "./DocumentPage";

export const documents = ({getConfig}) => {
    return [
        {
            target: "$ONE_LAYOUT_ROUTE",
            handler: {
                exact: true,
                path: "/",
                component: DocumentPage
            }
        }
    ];
};
