import { useAuth, usePost } from "@onefront/react-sdk";
import { buildHeaders } from "./buildHeaders";
import React from "react";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function useCreateFolder({
  folderName,
  referenceIds,
  classification,
  referencePeriod,
}: any) {
  const authData = useAuth();

  const { workspaceId } = useClientInformationStore();

  const payload = {
    workspaceId,
    folderName,
    classification,
    referencePeriod,
    referenceIds,
    itemClassifier: "ACCOUNTANT",
    createdBy: "f.silvestri@teamsystem.com",
  };

  const { data, loading, hasLoaded, fetch, error } = usePost(
    `https://documents-proxy-dev.agyo.io/proxy/documentsApi/api/v1/folders`,
    payload,
    {
      lazy: true,
      headers: buildHeaders({ authData }),
    }
  );

  return { data, loading, hasLoaded, fetch, error };
}
