import { PreviewTheNavigatedDoc } from "./PreviewTheNavigatedDoc";

export const docPreviewNavigation = () => {
  return [
    {
      target: "$ONE_LAYOUT_ROUTE",
      handler: {
        exact: true,
        path: "/document-preview/:id/:workspaceId",
        component: PreviewTheNavigatedDoc,
      },
    },
  ];
};
