import React, { useState, useEffect } from "react";
import {
  faClose,
  faFolderOpen,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@vapor/react-material";
import { useTranslation } from "@onefront/react-sdk";
import { Alert, AlertTitle, Button, Grid } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";

interface AlertHeaderShowFirstProps {}

const AlertHeaderShowFirst: React.FC<AlertHeaderShowFirstProps> = ({}) => {
  const { t } = useTranslation("DOCS");
  const [firstAlertShow, setFirstAlertShow] = useState(() => {
    const storedValue = localStorage.getItem("alertFirstShow");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const handleClose = () => {
    localStorage.setItem("alertFirstShow", "true");
    setFirstAlertShow(true);
  };

  useEffect(() => {
    localStorage.setItem("alertFirstShow", JSON.stringify(firstAlertShow));
  }, [firstAlertShow]);

  return (
    <>
      {!firstAlertShow && (
        <Grid>
          <Stack>
            <Alert
              sx={{ width: "100" }}
              variant="outlined"
              severity="info"
              icon={<FontAwesomeIcon icon={faFolderOpen} />}
              action={
                <IconButton size="small" onClick={handleClose}>
                  <FontAwesomeIcon size="xs" icon={faClose} />
                </IconButton>
              }
            >
              <AlertTitle>
                <Typography variant="body500" fontSize={"16px"}>
                  {t("document.alertFirstShow")}
                </Typography>
              </AlertTitle>
              <Typography variant="bodySmall">
                {t("document.alertFirstShowBody")}
              </Typography>
            </Alert>
          </Stack>
        </Grid>
      )}
    </>
  );
};

export default AlertHeaderShowFirst;
