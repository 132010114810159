import { useAuth, usePost, usePut, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

interface UsePutLinkedDocumentsProps {
  id?: string;
  filteredDocuments?: string[]; // Adjust the type to match the actual data structure
  workspaceId?: string;
}

export function usePutLinkedDocuments({
  id,
  filteredDocuments,
  workspaceId,
}: UsePutLinkedDocumentsProps) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "put",
    lazy: true,
    headers: buildHeaders({ authData }),
    url: `documentsApi://api/v1/documents/link?workspaceId=${workspaceId}`,
    data: {
      documentId: id,
      linkedDocuments: filteredDocuments,
    },
  });

  useEffect(() => {
    if (data) {
      fetch();
    }
  }, []);

  return { data, loading, hasLoaded, fetch, error };
}
