import { useAuth, usePut, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function usePutTags(id, tags) {
  let stringTags = tags?.toString();
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "put",

    lazy: true,
    headers: buildHeaders({ authData }),

    url: `documentsApi://api/v1/documents/${id}/tags?tags=${stringTags}`
  });

  useEffect(() => {
    if (data) {
      fetch();
    }
  }, [tags]);

  return { data, loading, hasLoaded, fetch, error };
}
