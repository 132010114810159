import Stack from "@vapor/react-material/Stack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledChip } from "../document-styled-components/styledChip";
import { useTranslation } from "@onefront/react-sdk";

import {
  faInbox,
  faFileInvoiceDollar,
  faFileInvoice,
  faBuilding,
  faUserTie,
  faFileLines,
  faServer
} from "@fortawesome/pro-regular-svg-icons";



const renderChip = (t, label, translatedLabel, macroCategories) => {
  let categoriesSum = [...macroCategories.values()].reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  if (!macroCategories.has(label)) {
    return `${translatedLabel} ${categoriesSum}`;
  }
  return `${"" + translatedLabel}  ${macroCategories.get(label)}`;
};

const ChipsFilter = ({ selectedChip, setSelectedChip, macroCategories }) => {
  const { t } = useTranslation("DOCS");

  const chipNames = [
    { icon: faInbox, label: "Inbox", translatedLabel: t("document.chips.Inbox") },
    { icon: faFileInvoiceDollar, label: "Fatture", translatedLabel: t("document.chips.Fatture") },
    { icon: faFileInvoice, label: "Fiscale", translatedLabel: t("document.chips.Fiscale") },
    { icon: faBuilding, label: "Azienda", translatedLabel: t("document.chips.Azienda") },
    {
      icon: faUserTie,
      label: "Gestione del personale",
      translatedLabel: t("document.chips.Gestione del personale"),
      displayText: "Personale"
    },
    {
      icon: faFileLines,
      label: "Altri",
      translatedLabel: t("document.chips.Altri")
    },
    { icon: faServer, label: "Tutti", translatedLabel: t("document.chips.Tutti") }];

  return (
    <Stack direction="row" spacing={2}>
      {chipNames
        .filter((name) =>
          name.label !== "Tutti" ? macroCategories.has(name.label) : name
        )
        .map((category) => (
          <StyledChip
            key={category.label}
            categorylabel={category.label}
            selectedchip={selectedChip}
            variant="filled"
            icon={
              <FontAwesomeIcon
                icon={category.icon}
                color="#5a6872"
              ></FontAwesomeIcon>
            }
            label={renderChip(t, category.label, category.translatedLabel, macroCategories)}
            size="medium"
            onClick={() => {
              setSelectedChip(category.label);
              if (window.aptrinsic) {
                window.aptrinsic("track", "docs:SELECT-MACROCATEGORY");
              }
            }
            }
          ></StyledChip>
        ))}
    </Stack>
  );
};

export default ChipsFilter;
