import Typography from "@vapor/react-extended/ExtendedTypography";
import { AccordionDetails } from "@vapor/react-material/AccordionDetails";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Stack from "@vapor/react-material/Stack";
import { useState } from "react";
import {
  StyledAccordion,
  StyledAccordionSummary,
} from "../document-styled-components/StyledAccordion";

import { useTranslation } from "@onefront/react-sdk";

export const AccordionFilter = ({ categories, setSelectedCategories }) => {
  const [expanded, setExpanded] = useState("");
  const { t } = useTranslation("DOCS");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const categoryTitles = categories
    ? Object.entries(categories)
        .filter((category) => category[0] !== "macrocategory")
        .filter((category) => category[0] !== "microcategory")
        .filter((category) => category[0] !== "hubId")
        .filter((category) => category[1].length > 0)
    : [];

  const handleSelectCategory = (title, detail) => {
    setSelectedCategories((prevCategories) => {
      const previous = prevCategories[title];
      if (previous === detail.bucketName) {
        const { [title]: _, ...rest } = prevCategories;
        return rest;
      } else {
        return { ...prevCategories, [title]: detail.bucketName };
      }
    });
  };

  return (
    <Box sx={{ width: "300px" }}>
      {categoryTitles.map(([title, details], index) => (
        <StyledAccordion
          key={index}
          expanded={expanded === title}
          onChange={handleChange(title)}
        >
          <StyledAccordionSummary>
            <Typography variant="subtitle" color="primary.textTitleColor">
              {t("document.filter." + title)}
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Stack>
              {details.map((detail, i) => (
                <Button
                  key={i}
                  variant="text"
                  size="small"
                  onClick={(e) => handleSelectCategory(title, detail)}
                  style={{justifyContent:"flex-start"}}
                >
                  <Typography variant="body" noWrap>
                  {detail.bucketName} ({detail.docCount})
                  </Typography>
                </Button>
              ))}
            </Stack>
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </Box>
  );
};
