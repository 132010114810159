import {useEffect, useState} from "react";
import { useAuth, usePost } from "@onefront/react-sdk";
import { buildHeaders } from "../../../common-utils/buildHeaders";

export function usePostDownload({workspaceId}) {
    const authData = useAuth();
    const [requestParams, setRequestParams] = useState(null);
    const [fileName, setFileName] = useState("");
    const { data, loading, hasLoaded, fetch, error } = usePost(
        `documentsApi://api/v1/documents/download/multiple?workspaceId=${workspaceId}`,
        requestParams ? {
            documentIds: requestParams.documentIds
        } : {},
        {
            headers: buildHeaders({ authData }),
            responseType: "blob",
            onResponse: (response) => {
                if (response.data.size) {
                    const contentDisposition = response.headers.get('content-disposition');
                    const filename = contentDisposition?.match(/filename="(.+)"/)[1] || 'download.zip';
                    setFileName(filename);
                }
            }
        }
    );

    useEffect(() => {
        if (requestParams) {
            fetch();
        }
    }, [requestParams]);

    const postDownloadDocs = (documentIds) => {
        setRequestParams({ documentIds });
    };

    return { data, loading, hasLoaded, postDownloadDocs, error, fileName };
}
