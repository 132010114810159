import FormControl from "@vapor/react-material/FormControl";
import FormGroup from "@vapor/react-material/FormGroup";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import Checkbox from "@vapor/react-material/Checkbox";
import { useTranslation } from "@onefront/react-sdk";

export const CheckboxFilter = ({ checkboxes, handleCheckboxChange }) => {
    const { t } = useTranslation("DOCS");
    const translatedMicro = checkboxes
      ? checkboxes.reduce((acc, c) => {
          acc[c.name] = t("micro." + c.name) + " (" + c.value + ")";
          return acc;
        }, {})
      : {};
    return (
        <FormControl sx={{ marginTop: "10px" }}>
            <FormGroup row>
                {checkboxes &&
                    checkboxes.map((category, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={category.checked}
                                    onChange={() => handleCheckboxChange(category)}
                                />
                            }
                            label={translatedMicro[category.name]}
                            labelPlacement="end"
                        />
                    ))}
            </FormGroup>
        </FormControl>
    );
};
