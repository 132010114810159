import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import Button from "@vapor/react-material/Button";
import Popover from "@vapor/react-material/Popover";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LoadingWithoutText } from "../common-components/LoadingWithoutText";
import { useDeleteLinkedDocument } from "./api-hooks/useDeleteLinkedDocument";
import { LinkedDocumentModalDelete } from "./LinkedDocumentModalDelete";
import { LinkedDocumentModalInformation } from "./LinkedDocumentModalInformation";
import SuccessAlertDocumentDelete from "../document-detail/document-details-components/SuccessAlertDocumentDelete";

export const ClassificationLinkedDocuments = ({
  data,
  workspaceId,
  setDeletedId,
  fetchDetails,
  loadingDetails,
}) => {
  const { id: documentId } = useParams();
  const [documentIdToDelete, setDocumentIdToDelete] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [linkedDocuments, setLinkedDocuments] = React.useState(null);
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [docInfo, setDocInfo] = React.useState("");
  const [clickToConfirm, setClickToConfirm] = React.useState(false);
  const { t } = useTranslation("DOCS");
  const navigate = useNavigate();

  const handleDialog = () => {
    setOpenModalInfo(!openModalInfo);
  };
  const handleDialogDelete = () => {
    setAnchorEl(null);
    setOpenModalDelete(!openModalDelete);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  setDeletedId(documentIdToDelete);
  const { data: deleted, loading } = useDeleteLinkedDocument(
    documentId,
    documentIdToDelete,
    workspaceId,
    clickToConfirm,
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (data) {
      setLinkedDocuments(data?.linkedDocuments);
    }
  }, [data]);

  useEffect(() => {
    if (deleted) {
      fetchDetails();
    }
  }, [deleted]);

  useEffect(() => {
    if (clickToConfirm) {
      setDocumentIdToDelete(docInfo?.documentId);
    }
    setClickToConfirm(false);
  }, [clickToConfirm]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {loading ? (
        <LoadingWithoutText />
      ) : (
        <div style={{ marginLeft: 20, marginTop: 20 }}>
          <div>
            {data?.linkedDocuments.length !== 0 && (
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  style={{ marginRight: 10 }}
                  color="#0090d1"
                  size="sm"
                  icon={faLink}
                />
                <Typography fontSize={"14px"} fontFamily={"Roboto"}>
                  {t("classification.documentLinkedTo")}:
                </Typography>
              </div>
            )}
            {linkedDocuments?.map((doc) => (
              <>
                <div style={{ display: "flex" }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "35px",
                        marginBottom: 20,
                      }}
                    >
                      <Link
                        to={`/document-details/${doc.documentId}/${workspaceId}`}
                        style={{ textDecoration: "none" }}
                      >
                        <Typography
                          fontSize={"14px"}
                          fontWeight={500}
                          fontFamily={"Roboto"}
                        >
                          {doc.filename}
                        </Typography>
                      </Link>

                      <div style={{ display: "flex", marginLeft: 10 }}>
                        <Typography fontSize={"14px"} fontFamily={"Roboto"}>
                          del {doc.updatedOn} &nbsp;
                        </Typography>
                        <Typography fontSize={"14px"} fontFamily={"Roboto"}>
                          in &nbsp; {doc.macrocategory}/{doc.microcategory}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <LinkedDocumentModalInformation
                    docInfo={docInfo}
                    data={data}
                    handleDialog={handleDialog}
                    openModalInfo={openModalInfo}
                  />
                  <LinkedDocumentModalDelete
                    setClickToConfirm={setClickToConfirm}
                    setOpenModalDelete={setOpenModalDelete}
                    handleDialogDelete={handleDialogDelete}
                    openModalDelete={openModalDelete}
                    docInfo={docInfo}
                  />
                  <Button
                    onClick={() => setDocInfo(doc)}
                    key={doc}
                    size="medium"
                    style={{
                      padding: 0,
                      minHeight: 0,
                      minWidth: 0,
                      marginTop: "-12px",
                      marginLeft: 30,
                      marginBottom: 12,
                      marginRight: 50,
                    }}
                  >
                    <FontAwesomeIcon
                      onClick={handleClick}
                      color="#4f4f4f"
                      size="lg"
                      icon={faEllipsisVertical}
                    />
                  </Button>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Button
                      style={{ border: "none" }}
                      onClick={() =>
                        navigate(
                          `/document-details/${doc.documentId}/${workspaceId}`,
                        )
                      }
                    >
                      <FontAwesomeIcon
                        key={doc?.documentId}
                        color="#0090d1"
                        size="sm"
                        icon={faEye}
                      />
                    </Button>
                    <Button style={{ border: "none" }} onClick={handleDialog}>
                      <FontAwesomeIcon
                        key={doc?.documentId}
                        color="#0090d1"
                        size="sm"
                        icon={faCircleInfo}
                      />
                    </Button>
                    <Button
                      style={{ border: "none" }}
                      onClick={() => {
                        handleDialogDelete();
                      }}
                    >
                      <FontAwesomeIcon
                        key={doc?.documentId}
                        color="red"
                        size="sm"
                        icon={faTrash}
                      />
                    </Button>
                  </Popover>
                </div>
              </>
            ))}
            {deleted && <SuccessAlertDocumentDelete />}
          </div>
        </div>
      )}
    </>
  );
};
