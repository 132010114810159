import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";
import { useClientInformationStore } from "../../store/useClientInformationStore";

export function useGetDocumentById({
  id,
  workspaceId,
  reload,
  setReload,
  checkVisibility,
  setCheckVisibility,
}) {
  const authData = useAuth();
  const { selectedTenant: currentCompany } = useClientInformationStore();
  const clientId = currentCompany?.itemId;

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `documentsApi://api/v1/documents/${id}?workspaceId=${workspaceId}&loggedItemId=${clientId}`,
    {
      headers: buildHeaders({ authData }),
    },
  );
  useEffect(() => {
    if (data) {
      fetch();
    }
  }, [id]);

  useEffect(() => {
    if (reload || checkVisibility || !checkVisibility) {
      fetch();
      setReload(false);
    }
  }, [reload, checkVisibility]);
  return { data, loading, hasLoaded, fetch, error };
}
