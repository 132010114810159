import { useAuth, useDelete, useGet, useRequest } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function usePatchToRenameFile({ id, workspaceId, newFileName }) {
  const authData = useAuth();
  const { data, loading, hasLoaded, fetch, error } = useRequest({
    method: "patch",

    lazy: true,
    headers: buildHeaders({ authData }),

    url: `documentsApi://api/v1/documents/${id}/rename?newFileName=${newFileName}&workspaceId=${workspaceId}`,
  });

  useEffect(() => {
    if (data) {
      fetch();
    }
  }, []);

  return { data, loading, hasLoaded, fetch, error };
}
