import {
  faInbox,
  faFileInvoiceDollar,
  faFileInvoice,
  faBuilding,
  faUserTie,
  faFileLines,
  faServer,
} from "@fortawesome/pro-regular-svg-icons";

export const macroCategoryCostants = {
  inbox: { icon: faInbox, label: "Inbox" },
  fatture: { icon: faFileInvoiceDollar, label: "Fatture" },
  fiscale: { icon: faFileInvoice, label: "Fiscale" },
  azienda: { icon: faBuilding, label: "Azienda" },
  personale: {
    icon: faUserTie,
    label: "Gestione del personale",
    displayText: "Personale",
  },
  altri: { icon: faFileLines, label: "Altri" },
  tutti: { icon: faServer, label: "Tutti" },
};

export const macroCategoryConstant = new Map([
  [macroCategoryCostants.inbox.label, macroCategoryCostants.inbox.icon],
  [macroCategoryCostants.fatture.label, macroCategoryCostants.fatture.icon],
  [macroCategoryCostants.altri.label, macroCategoryCostants.altri.icon],
  [macroCategoryCostants.azienda.label, macroCategoryCostants.azienda.icon],
  [macroCategoryCostants.fiscale.label, macroCategoryCostants.fiscale.icon],
  [
    macroCategoryCostants.personale.label,
    macroCategoryCostants.personale.icon,
  ],
  [macroCategoryCostants.tutti.label, macroCategoryCostants.tutti.icon],
]);