import ButtonGroup from "@vapor/react-material/ButtonGroup";
import Button from "@vapor/react-material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faList } from "@fortawesome/pro-solid-svg-icons";
import { FileFormatSwitcherProps } from "../types";
export type fileFormat = "Card" | "List";

export const FileFormatSwitcher = ({
  selectedFileFormat,
  setSelectedFileFormat,
}: FileFormatSwitcherProps) => {
  return (
    <ButtonGroup variant="contained" sx={{ marginRight: "20px" }}>
      <Button
        onClick={(e) => setSelectedFileFormat("Card")}
        variant={selectedFileFormat === "Card" ? "outlined" : "contained"}
        style={
          selectedFileFormat === "Card" ? { backgroundColor: "#b9e6f6" } : {}
        }
        startIcon={<FontAwesomeIcon icon={faList}></FontAwesomeIcon>}
      ></Button>
      <Button
        onClick={(e) => setSelectedFileFormat("List")}
        variant={selectedFileFormat === "List" ? "outlined" : "contained"}
        style={
          selectedFileFormat === "List" ? { backgroundColor: "#b9e6f6" } : {}
        }
        startIcon={<FontAwesomeIcon icon={faTh}></FontAwesomeIcon>}
      ></Button>
    </ButtonGroup>
  );
};
