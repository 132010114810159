import { DocPreviewer } from "@onefront/documents-smart-components";
import React from "react";
import { useParams } from "react-router-dom";

export const PreviewTheNavigatedDoc: React.FC = () => {
  const { id, workspaceId } = useParams<{ workspaceId: string | undefined; id: string | undefined }>();
  const containerStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    marginLeft: "15%",
    paddingTop: "5%",
  };

  return (
    <div style={containerStyle}>
      <DocPreviewer
        isInvoice={false}
        height={"100vh"}
        id={id}
        workspaceId={workspaceId}
      />
    </div>
  );
};
