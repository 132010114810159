import { useCallback, useEffect, useState } from "react";
import { Box } from "@vapor/react-material";
import { Uploader } from "@vapor/react-custom";
import ConvertFileToBase64 from "../../utils/ConvertFileToBase64";
import { usePostUploadDocument } from "../../hooks/usePostUploadDocument";

interface FileData {
  content: string | null;
  filename: string | null;
}

const FileUploader = ({
  clickToConfirm,
  setClickToConfirm,
  onFilesUpload,
  setUploadFolder,
}: any) => {
  const [files, setFiles] = useState<any>([]);
  const [fileDataList, setFileDataList] = useState<FileData[]>([]);
  const [documentIds, setDocumentIds] = useState<any>([]);
  const [fileContent, setFileContent] = useState<FileData>({
    content: "",
    filename: "",
  });

  const onFileUpload = (filesItems: any) => {
    setFiles((files: any) => [...files, ...filesItems]);
  };

  const onRemove = (fileIndex: number) => {
    setFiles((files: any) => files.filter((_: any, i: any) => i !== fileIndex));
  };

  const { data, loading, error, fetch, hasLoaded } =
    usePostUploadDocument(fileContent);

  const handleDocUpload = useCallback(async () => {
    for await (const file of files) {
      const fileData = await ConvertFileToBase64(file);
      setFileContent(fileData);
    }
  }, [files]);

  useEffect(() => {
    clickToConfirm && handleDocUpload();
  }, [clickToConfirm, handleDocUpload]);

  useEffect(() => {
    if (documentIds.length > 0) {
      onFilesUpload(documentIds);
    }
    if (data && documentIds.length === files.length) {
      setUploadFolder(true);
    }
  }, [documentIds]);

  useEffect(() => {
    if (clickToConfirm && files.length === 0) {
      setUploadFolder(true);
    }
  }, [clickToConfirm, files]);

  useEffect(() => {
    if (data && data.documentId) {
      setDocumentIds((ids: any) => [...ids, data.documentId]);
    }
  }, [data]);

  return (
    <Box
      sx={{
        width: "auto",
        marginBottom: "30px",
      }}
    >
      <Uploader
        draggable
        buttonLabel="Carica file"
        draggableText="Trascina uno o più documenti in quest’area, o"
        dropText="Drop file here"
        onFileUpload={onFileUpload}
        label="File uploader label"
        description="File accettati XML, P7M, PDF, JPEG, JPG, PNG, DOC, DOCX, XLS, XLSX, CSV. Dimensione max 16mb."
        inputFileProps={{
          multiple: true,
        }}
        buttonVariant="outlined"
        files={files}
        onRemove={onRemove}
      />
    </Box>
  );
};
export default FileUploader;
