import { v4 as uuidv4 } from "uuid";

const packageJson = require("../../../package.json");

interface BuildHeadersProps {
  authData: {
    tokenData?: {
      client_id?: string;
      email?: string;
    };
  };
  token?: string;
}

export const buildHeaders = ({ authData, token }: BuildHeadersProps) => {
  const name = authData.tokenData?.client_id || authData.tokenData?.email || "no-user";

  let headers: Record<string, string> = {
    "X-App-Version": packageJson.version,
    "X-App-Name": packageJson.name,
    "X-Correlation-Id": uuidv4(),
    "X-Forwarded-For": "frontend",
    "X-Request-Id": uuidv4(),
    "X-User-Id": name,
  };

  if (token) {
    headers = { ...headers, Authorization: token };
  }

  return headers;
};
