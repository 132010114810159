import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/pro-regular-svg-icons";
import { Box, Grid, LinearProgress } from "@vapor/react-material";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { formatNumberOfGB } from "../document-utils/DateFormater";

interface SpaceManagementLinearProgressBarProps {
  usedSpace: number;
  totalSpace: number;
  freeSpace: number;
}

export const SpaceManagementLinearProgressBar: React.FC<
  SpaceManagementLinearProgressBarProps
> = ({ freeSpace, totalSpace, usedSpace }) => {
  const { t } = useTranslation("DOCS");
  const usedSpacePercentage = (usedSpace / totalSpace) * 100;
  return (
    <>
      <Grid>
        <Box
          sx={{
            display: "flex",
            padding: 1,
            marginBottom: "10px",
          }}
        >
          <FontAwesomeIcon
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: 4,
              marginRight: 5,
            }}
            size={"sm"}
            color={"#008FD6"}
            icon={faCloud}
          />
          <Typography
            color={"#008FD6"}
            fontFamily={"Roboto"}
            fontSize={"16px"}
            fontWeight={500}
          >
            {t("document.manageSpace")}
          </Typography>
        </Box>
        <Box
          sx={{
            mr: 1,
            width: "100%",
          }}
        >
          <Box
            sx={{
              mr: 1,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                paddingLeft: "2%",
                flexWrap: "wrap",
                marginBottom: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                color={"#004E75"}
                fontFamily={"Roboto"}
                fontSize={"12px"}
                fontWeight={400}
              >
                {formatNumberOfGB(usedSpace)} GB {t("document.occupied")}
              </Typography>

              <Typography
                color={"#98AAB3"}
                fontFamily={"Roboto"}
                fontSize={"12px"}
                fontWeight={400}
              >
                {formatNumberOfGB(freeSpace)} GB {t("document.available")}
              </Typography>
            </Box>
          </Box>
          <LinearProgress
            sx={{ marginLeft: "5px" }}
            variant="determinate"
            value={usedSpacePercentage}
          />
        </Box>
      </Grid>
    </>
  );
};
