import ButtonGroup from "@vapor/react-material/ButtonGroup";
import Button from "@vapor/react-material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh, faList } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@vapor/react-material";
import { useTranslation } from '@onefront/react-sdk';

interface FileFormatSwitcherProps  {
  selectedFileFormat: string,
  setSelectedFileFormat:(format:string) => void
}

export const FileFormatSwitcher = ({
  selectedFileFormat,
  setSelectedFileFormat
}:FileFormatSwitcherProps) => {
  const {t} = useTranslation("DOCS");
  return (
    <ButtonGroup variant="contained" sx={{ marginRight: "20px" }}>
      <Tooltip  title={t("document.list")} arrow placement="top">
        <Button
          onClick={(e) => setSelectedFileFormat("Card")}
          variant={selectedFileFormat === "Card" ? "outlined" : "contained"}
          style={selectedFileFormat === "Card" ? { backgroundColor: "#b9e6f6" } : {}}
          startIcon={<FontAwesomeIcon icon={faList}></FontAwesomeIcon>}
        ></Button>
      </Tooltip>
      <Tooltip title={t("document.grid")} arrow placement="top">
        <Button
          onClick={(e) => setSelectedFileFormat("List")}
          variant={selectedFileFormat === "List" ? "outlined" : "contained"}
          style={selectedFileFormat === "List" ? { backgroundColor: "#b9e6f6" } : {}}
          startIcon={<FontAwesomeIcon icon={faTh}></FontAwesomeIcon>}
        ></Button>
      </Tooltip>
    </ButtonGroup>
  );
};
