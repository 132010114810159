import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { IconButton, Alert } from "@vapor/react-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Close from "@mui/icons-material/Close";

const SuccessAlert = ({ message }: any) => {
  return (
    <Alert
      action={
        <IconButton size="small">
          <Close />
        </IconButton>
      }
      icon={<FontAwesomeIcon icon={faCheckCircle} />}
      severity="success"
      variant="outlined"
    >
      {message}
    </Alert>
  );
};
export default SuccessAlert;
