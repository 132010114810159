import { parseISO, isValid, format } from "date-fns";

interface StandardTag {
  name: string;
  value: any;
  valueDate: Date | null;
  valueNumber: number | null;
}

interface DynamicObject {
  [key: string]: any;
}

export const updateDynamicObject = (
  standardTags: StandardTag[],
  dynamicObject: DynamicObject
): DynamicObject => {
  // Loop through each object in the standardTags array
  for (const tag of standardTags) {
    // Check if the 'name' property of the tag exists in dynamicObject
    if (tag.name in dynamicObject) {
      // Check if the cached value is different from the standardTags value

        if (isDate(tag.value)) {
          // If it is a date, format it with moment
          dynamicObject[tag.name] = format(parseISO(tag.value), "yyyy-MM-dd");
        } else {
          // If it's not a date, set it as is
          dynamicObject[tag.name] = tag.value;
      }
    }
  }
  return dynamicObject;
};

function isDate(value: any): boolean {
  const parsedDate = parseISO(value);
  return isValid(parsedDate);
}
