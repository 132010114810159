import { useEffect } from "react";
import { useAuth, usePost } from "@onefront/react-sdk";
import { buildHeaders } from "../../common-utils/buildHeaders";
import { useClientInformationStore } from "../../store/useClientInformationStore";

interface GetDocumentsForClassificationsProps {
  workspaceId: string | undefined;
  startDate?: string;
  endDate?: string;
  dateType?: string;
  searchTerm?: string;
  macrocategory?: string | null;
  buttonclicked?: boolean;
}

export function useGetDocumentsForClassifications({
  workspaceId,
  macrocategory,
  buttonclicked,
  startDate,
  endDate,
  dateType,
  searchTerm,
}: GetDocumentsForClassificationsProps) {
  const { selectedTenant: currentCompany } = useClientInformationStore();

  const clientId = currentCompany?.itemId;
  const authData = useAuth();
  const { data, loading, hasLoaded, fetch, error } = usePost(
    `documentsApi://api/v1/documents/search?`,
    {
      aggregationsFilter: { macrocategory: macrocategory },
      workspaceId,
      fullTextSearch: searchTerm,
      pageNumber: 0,
      startDate,
      sort: "LATEST_UPLOADS",
      endDate,
      dateType,
      loggedItemId: clientId,
      pageSize: 50,
    },
    {
      headers: buildHeaders({ authData }),
    },
  );
  useEffect(() => {
    if (workspaceId) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId, searchTerm, startDate, endDate, dateType, macrocategory]);

  return { data, loading, hasLoaded, fetch, error };
}
