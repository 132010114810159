import Box from "@vapor/react-material/Box";
import React from "react";
import AlertHeaderShowFirst from "./AlertHeaderShowFirst";

export const DocumentHeader: React.FC = () => (
  <>
    <Box
      sx={{
        backgroundColor: "white",
        marginLeft: "16px",
        marginRight: "16px",
        padding: "20px",
      }}
    >
      <AlertHeaderShowFirst />
    </Box>
  </>
);
