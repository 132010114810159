import { faCircleMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from "@vapor/react-material/TextField";
import { useTranslation } from "@onefront/react-sdk";
import {
  AdapterDateFns, DatePicker, LocalizationProvider
} from "@vapor/react-x-date-pickers";
import { it } from "date-fns/locale";

const ClassificationDatePicker = ({
  width,
  label1,
  onChange,
  value,
  key,
  required,
}) => {
  const { t } = useTranslation("DOCS");

  const handleChange = (newDate) => {
    const formattedDate = newDate ? newDate : null;
    onChange({ target: { value: formattedDate } });
  };

  return (
    <>
      <LocalizationProvider locale={it} dateAdapter={AdapterDateFns}>
        <div
          style={{
            width:"100%",
            display: "inline",
            marginRight: 10,
          }}
        >
          <DatePicker
            key={key}
            label={label1}
            value={value ? value : null}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                sx={{
                  width: "30%",marginTop:1.3
                }}
                helperText={
                  value === "" && required.required ? (
                    <div>
                      <FontAwesomeIcon icon={faCircleMinus} />{" "}
                      {t("classification.errorMessage")}
                    </div>
                  ) : (
                    " "
                  )
                }
                {...params}
              />
            )}
          />
        </div>
      </LocalizationProvider>
    </>
  );
};
export default ClassificationDatePicker;
