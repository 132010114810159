import { styled } from "@vapor/react-material/styles";
import { ExtendedAccordion } from "@vapor/react-extended/ExtendedAccordion";
import { AccordionSummary } from "@vapor/react-material/AccordionSummary";
import { AccordionDetails } from "@vapor/react-material/AccordionDetails";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledAccordion = styled((props) => (
  <ExtendedAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

export const StyledAccordionSummary = styled((props) => (
  <AccordionSummary
    expandIcon={<FontAwesomeIcon icon={faAngleDown} color="#4E8FBB" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F2F5F8",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    Color: "black",
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingRight: "100px",
  padding: theme.spacing(0.2),
  "&:hover": {
    cursor: "pointer",
    textShadow: ".2px .2px .2px #000000",
    fontSize: "10px"
  }
}));
