import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons/faCircleInfo";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons/faEllipsisVertical";
import { faLink } from "@fortawesome/pro-solid-svg-icons/faLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { IconButton, Stack } from "@vapor/react-material";
import Popover from "@vapor/react-material/Popover";
import { format, parse } from "date-fns";
import { enGB, es, it } from "date-fns/locale";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LinkedDocumentModalDelete } from "../../classification/LinkedDocumentModalDelete";
import { LinkedDocumentModalInformation } from "../../classification/LinkedDocumentModalInformation";
import { useDeleteLinkedDocument } from "../../classification/api-hooks/useDeleteLinkedDocument";
import { LoadingWithoutText } from "../../common-components/LoadingWithoutText";
import SuccessAlertDocumentDelete from "./SuccessAlertDocumentDelete";

export const DocumentLinks = ({
  data,
  workspaceId,
  setDeletedId,
  fetchDetails,
  loadingDetails,
}) => {
  const { id: documentId } = useParams();
  const [documentIdToDelete, setDocumentIdToDelete] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [linkedDocuments, setLinkedDocuments] = React.useState(null);
  const [openModalInfo, setOpenModalInfo] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [docInfo, setDocInfo] = React.useState("");
  const [clickToConfirm, setClickToConfirm] = React.useState(false);
  const { t, i18n } = useTranslation("DOCS");
  const locale =
    i18n.language === "it" ? it : i18n.language === "es" ? es : enGB;

  const navigate = useNavigate();

  const handleDialog = () => {
    setOpenModalInfo(!openModalInfo);
  };
  const handleDialogDelete = () => {
    setAnchorEl(null);
    setOpenModalDelete(!openModalDelete);
  };

  const handleClick = (target) => {
    setAnchorEl(target);
  };
  setDeletedId(documentIdToDelete);
  const { data: deleted, loading } = useDeleteLinkedDocument(
    documentId,
    documentIdToDelete,
    workspaceId,
    clickToConfirm
  );
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (data) {
      setLinkedDocuments(data?.linkedDocuments);
    }
  }, [data]);

  useEffect(() => {
    if (deleted) {
      fetchDetails();
    }
  }, [deleted]);

  useEffect(() => {
    if (clickToConfirm) {
      setDocumentIdToDelete(docInfo?.documentId);
    }
    setClickToConfirm(false);
  }, [clickToConfirm]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {loading ? (
        <LoadingWithoutText />
      ) : (
        <Stack direction="column" gap={2}>
          {linkedDocuments?.map((doc) => (
            <Stack
              direction="row"
              key={doc.documentId}
              justifyContent="space-between"
            >
              <Link
                to={`/document-details/${doc.documentId}/${workspaceId}`}
                style={{ display: "flex", textDecoration: "none" }}
              >
                <FontAwesomeIcon
                  color="#0090d1"
                  size="sm"
                  icon={faLink}
                  style={{ paddingTop: "8px" }}
                />
                <Stack direction="column" paddingLeft={1}>
                  <Typography variant="body500">
                    {t("classification.connectedTo")}:
                  </Typography>
                  <Stack direction="row" justifyContent="flex-start" gap={1}>
                    <Typography
                      variant="body500"
                      noWrap
                      maxWidth={300}
                      color="primary.interactiveDefault"
                    >
                      {doc.filename}
                    </Typography>
                    <Typography
                      variant="body"
                      color="primary.interactiveDefault"
                    >
                      {(() => {
                        try {
                          const formattedDate = format(
                            parse(doc.updatedOn, "dd/MM/yyyy", new Date()),
                            "dd MMM yyyy",
                            { locale: locale }
                          );
                          return `del (${formattedDate})`;
                        } catch (error) {
                          return "";
                        }
                      })()}
                    </Typography>
                  </Stack>
                  <Typography variant="body">
                    in {doc.macrocategory}/{doc.microcategory}
                  </Typography>
                </Stack>
              </Link>
              <LinkedDocumentModalInformation
                docInfo={docInfo}
                data={data}
                handleDialog={handleDialog}
                openModalInfo={openModalInfo}
              />
              <LinkedDocumentModalDelete
                setClickToConfirm={setClickToConfirm}
                setOpenModalDelete={setOpenModalDelete}
                handleDialogDelete={handleDialogDelete}
                openModalDelete={openModalDelete}
                docInfo={docInfo}
              />
              <IconButton
                onClick={(e) => {
                  setDocInfo(doc);
                  handleClick(e.currentTarget);
                }}
                key={doc}
                color="inherit"
              >
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </IconButton>
              <Popover
                elevation={1}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    navigate(
                      `/document-details/${doc.documentId}/${workspaceId}`
                    );
                  }}
                >
                  <FontAwesomeIcon key={doc?.documentId} icon={faEye} />
                </IconButton>
                <IconButton color="primary" onClick={() => handleDialog()}>
                  <FontAwesomeIcon key={doc?.documentId} icon={faCircleInfo} />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={() => {
                    handleDialogDelete();
                  }}
                >
                  <FontAwesomeIcon key={doc?.documentId} icon={faTrash} />
                </IconButton>
              </Popover>
            </Stack>
          ))}
          {deleted && <SuccessAlertDocumentDelete />}
        </Stack>
      )}
    </>
  );
};
