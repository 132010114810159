import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export const StyledTagWrapperDiv = styled.div`
  margin-right: 10px;
  display: inline-block;
  margin-bottom: 2px;
`;
export const StyledTagWithRadiusButton = styled.button`
background-color: #c53090;
justify-content: space-around;
margin-right: 1px;
margin-bottom: 4px;
border-radius: 4px;
`;
export const StyledFontAwesomeFaCircleXmark = styled(FontAwesomeIcon)`
  color: white;
  font-size: 12px;
  margin-top: 2px;
  margin-right: 5px;
`;

export const StyledRadiusButton = styled.button`
  background-color: #0d6c80;
  border-radius: 4px;
  cursor: default;
`;