import Select from "@vapor/react-material/Select";
import FormControl from "@vapor/react-material/FormControl";
import MenuItem from "@vapor/react-material/MenuItem";
import InputLabel from "@vapor/react-material/InputLabel";
import Stack from "@vapor/react-material/Stack";
import { MonthType } from "./MonthsType";
import { MonthsTypeList } from "./MonthTypeList";
import { useTranslation } from "@onefront/react-sdk";

export const DateFilter = ({
  allYears,
  selectedYear,
  setSelectedYear,
  monthType,
  months,
  setMonth,
  setMonths,
  setMonthType,
  changeSelectedMonths
}) => {
  const {t} = useTranslation("DOCS")
  const years = allYears ? [t("document.dateFilter.all")].concat([...allYears]) : [];
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        sx={{ width: "300px" }}
      >
        <FormControl sx={{ width: "120px", zIndex: 0 }}>
          <InputLabel>{t("document.dateFilter.label")}</InputLabel>
          <Select value={selectedYear}>
            {years.map((year) => (
              <MenuItem
                key={year}
                value={year}
                onClick={(e) => setSelectedYear(year)}
              >
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {selectedYear !== t("document.dateFilter.all") && (
          <MonthType
            monthType={monthType}
            setMonthType={setMonthType}
          ></MonthType>
        )}
      </Stack>
      {selectedYear !== t("document.dateFilter.all") && selectedYear !== "All" && (
        <MonthsTypeList
          selectedYear={selectedYear}
          monthType={monthType}
          months={months}
          setMonth={setMonth}
          changeSelectedMonths={changeSelectedMonths}
          setMonths={setMonths}
        ></MonthsTypeList>
      )}
    </Stack>
  );
};
