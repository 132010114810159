import React, { useState } from "react";
import { faClose, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack } from "@vapor/react-material";
import { useTranslation } from "@onefront/react-sdk";
import { Alert, AlertTitle, Button, Grid } from "@vapor/react-material";
import Typography from "@vapor/react-extended/ExtendedTypography";
import IconButton from "@vapor/react-material/IconButton";

interface DownloadConfirmationAlertProps {
  setDownloadMassiveDocuments: (prop: boolean) => void;
  setOpenModal: (value: boolean) => void;
  displayDataToCard: any;
}

export const DownloadConfirmationAlert: React.FC<
  DownloadConfirmationAlertProps
> = ({ setDownloadMassiveDocuments, setOpenModal, displayDataToCard }) => {
  const [isClosed, setIsClosed] = useState(false);
  const { t } = useTranslation("DOCS");
  const translatedMessage =
    t("document.selected") + displayDataToCard?.length + ".";

  const handleClose = () => {
    setIsClosed(true);
    setOpenModal(false);
  };

  const handleDownload = () => {
    setDownloadMassiveDocuments(true);
  };

  return (
    <>
      <Grid>
        {!isClosed && (
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: "80%",
              right: "20%",
              transform: "translate(50%, -50%)",
            }}
          >
            <Alert
              sx={{ width: "35vw" }}
              variant="outlined"
              severity="info"
              icon={<FontAwesomeIcon icon={faCircleExclamation} />}
              action={
                <IconButton size="small" onClick={handleClose}>
                  <FontAwesomeIcon size="xs" icon={faClose} />
                </IconButton>
              }
            >
              <AlertTitle sx={{ paddingTop: "3px", display: "flex" }}>
                <Typography>{t("document.downloadFile")} </Typography>
                <Typography sx={{ width: "60%", marginLeft: 1 }}>
                  {translatedMessage}
                  <br></br>
                  {t("document.fileWillBeSent")}
                </Typography>
              </AlertTitle>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  style={{ backgroundColor: "white", marginRight: "15px" }}
                  onClick={handleClose}
                >
                  <Typography color={"#0090d1"}>
                    {t("classification.cancel")}
                  </Typography>
                </Button>
                <Button
                  onClick={handleDownload}
                  variant="contained"
                  sx={{
                    background: "linear-gradient(90deg,#0090d1,#00c3ea)",
                  }}
                >
                  <Typography style={{ color: "white" }}>
                    {t("common.confirmDownload")}
                  </Typography>
                </Button>
              </div>
            </Alert>
          </Stack>
        )}
      </Grid>
    </>
  );
};
