import { useAuth, useGet } from "@onefront/react-sdk";
import { useEffect } from "react";
import { buildHeaders } from "../../common-utils/buildHeaders";

export function useGetDocumentTypes({workspaceId}) {
  const authData = useAuth();

  const { data, loading, hasLoaded, fetch, error } = useGet(
    `documentsApi://api/v1/documents/types?workspaceId=${workspaceId}`,
    {
      lazy: true,
      headers: buildHeaders({ authData })
    }
  );
  useEffect(() => {
    fetch();
  }, []);

  return { data, loading, hasLoaded, fetch, error };
}
