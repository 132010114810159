import { Stack } from "@vapor/react-material/Stack";
import { StyledBanner } from "../document-styled-components/StyledBanner";

export const NotFoundBanner = () => {
  return (
    <Stack alignItems="stretch" justifyContent="center" paddingTop="1rem">
      <StyledBanner src="banner-notfound.png" alt="not found"></StyledBanner>
    </Stack>
  );
};
