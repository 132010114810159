import { config } from "../../config";

export const gainsights = {
  target: "$INIT_SERVICE",
  handler: () => {
    (function (n: any, t: any, a: any, e: any, co?: any) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      var i = "aptrinsic"; n[i] = n[i] || function () {
        (n[i].q = n[i].q || []).push(arguments)
        // eslint-disable-next-line no-sequences
      }, n[i].p = e; n[i].c = co;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
      var r = t.createElement("script"); r.async = !0, r.src = a + "?a=" + e;
      var c = t.getElementsByTagName("script")[0]; c.parentNode.insertBefore(r, c)
    })(window, document, "https://web-sdk.aptrinsic.com/api/aptrinsic.js", config.gainsightEnvKey);
  }
}