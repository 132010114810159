import { DefaultFiltersType } from "../types";
import { useEffect, useState } from "react";

export const useFilters = (defaultFilters?: DefaultFiltersType) => {
  const savedFiltersRaw = localStorage.getItem("filters");
  let savedFilters = JSON.parse(savedFiltersRaw ?? "{}");

  if (Object.keys(savedFilters).length === 0 && defaultFilters) {
    savedFilters = defaultFilters;
  }

  const [filters, setFilters] = useState(savedFilters);

  useEffect(() => {
    localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  const handleFilterChange = (name: keyof DefaultFiltersType, value: any) => {
    setFilters((prevFilters: DefaultFiltersType) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  return [filters, setFilters, handleFilterChange];
};
