import { Stack } from "@vapor/react-material";
import { Search } from "./SearchBar";
import { SortingFilter } from "./SortingFilter";
import { DocumentsReadByFilter } from "./ReadByFilter";
import { DateType } from "./DateType";
import DocumentDatePicker from "../../common-components/DocDatePicker";
import { InvoiceFilter } from "./InvoiceFilter";
import { DateFilter } from "./DateFilter";
import { AccordionFilter } from "./CategoryFilter";
import { useTranslation } from "@onefront/react-sdk";
import { FiltersProps } from "../types";
import { SpaceManagementLinearProgressBar } from "./SpaceManagementLinearProgressBar";

const Filters = ({
  allYears,
  data,
  dateType,
  endDate,
  invoiceNumber,
  months,
  monthType,
  readByAccountant,
  readByCompany,
  selectedChip,
  selectedYear,
  setInvoiceNumber,
  setMonths,
  setMonthType,
  setSearchValue,
  setSelectedCategories,
  setSelectedYear,
  handleFilterChange,
  sortOption,
  startDate,
  urgentDocs,
  freeSpace,
  totalSpace,
  usedSpace,
  spaceManagementError,
}: FiltersProps) => {
  const { t } = useTranslation("DOCS");
  let renderSpaceManagement = totalSpace?.toString();
  return (
    <Stack direction="column" spacing={4}>
      <Search setSearchValue={setSearchValue} width={260}></Search>

      <SortingFilter
        sortOption={sortOption}
        handleFilterChange={handleFilterChange}
      ></SortingFilter>
      <DocumentsReadByFilter
        readByAccountant={readByAccountant}
        readByCompany={readByCompany}
        urgentDocs={urgentDocs}
        handleFilterChange={handleFilterChange}
      ></DocumentsReadByFilter>
      <>
        <DateType
          doNotShow={true}
          dateType={dateType}
          handleFilterChange={handleFilterChange}
        ></DateType>
      </>

      <DocumentDatePicker
        label1={t("document.MonthFilter.startDate")}
        label2={t("document.MonthFilter.endDate")}
        startDate={startDate}
        endDate={endDate}
        width={""}
        handleFilterChange={handleFilterChange}
      />
      {selectedChip === "Fatture" && selectedYear !== "All" && (
        <>
          <InvoiceFilter
            setInvoiceNumber={setInvoiceNumber}
            invoiceNumber={invoiceNumber}
          ></InvoiceFilter>
          <DateFilter
            allYears={allYears}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
            monthType={monthType}
            months={months}
            setMonthType={setMonthType}
            setMonths={setMonths}
          ></DateFilter>
        </>
      )}
      {data && (
        <AccordionFilter
          setSelectedCategories={setSelectedCategories}
          categories={data.aggregationValue}
        ></AccordionFilter>
      )}
      {renderSpaceManagement && (
        <SpaceManagementLinearProgressBar
          freeSpace={freeSpace}
          totalSpace={totalSpace}
          usedSpace={usedSpace}
        />
      )}
    </Stack>
  );
};

export default Filters;
